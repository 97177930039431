// src/analytics.js

// Set the page title and send page view
export const trackPageView = (path, title) => {

    if (window.dataLayer) {
        console.log('gtag', window.dataLayer)
        window.dataLayer.push({
            event: 'page_view',
            page_path: path,
            page_title: title
        });
    }

};

