import React, { useState, useEffect, useContext } from 'react';
import '../../app.css';
import '../BusinessPage/BusinessPage.css'
import Header from '../../Components/Header/Header'
import { Col, OverlayTrigger, Row, Popover } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';

import axios from 'axios';
import { getCookieValue, Strings, URLS } from '../../utilities/constants';
import { DataContext } from '../../utilities/DataContext';
import { useNavigate } from 'react-router-dom';
import Footer from '../../Components/Footer/Footer'
import { css } from '@emotion/react';
import { ClipLoader } from 'react-spinners';
import { FaYoutube } from "react-icons/fa6";
import { trackPageView } from '../../hooks/analytics';


const override = css`
display: block;
margin: 0 auto;
border-color: red;
`;


const BusinessPage = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const companyid = location.state?.id;
  let { companyName } = useParams();
  const { userData, businessForm1data, businessForm2data, setToken } = useContext(DataContext);
  const [businessName, setbusinessName] = useState("");
  const [isTitleReady, setIsTitleReady] = useState(false);
  const [companyData, setCompanyData] = useState(null)
  const [address, setAddress] = useState(null)
  const [loading, setLoading] = useState(false);
  const [businessImages, setBusinessImages] = useState([])
  const [businessImageList, setBusinessImgList] = useState([])
  const [businessdata, setBusinessdata] = useState(null)
  const [heroImage, setHeroImage] = useState(null)
  const [popoverText, setPopoverText] = useState('')
  const [showPopover, setShwPopover] = useState(Array(5).fill(false)); // Array of booleans to manage visibility of each popover
  const [horizontalPosition, setHorizontalPosition] = useState('ceter')
  const [verticalPosition, setverticalPosition] = useState('center')
  const [logoStyles, setLogoStyles] = useState({});
  const [businessStatus, setBusinessStatus] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')


  useEffect(() => {
    if (isEmptyObject(businessForm1data) && isEmptyObject(businessForm2data)) {
      getCompanyDetails()
    } else {
      setCompanyData(businessForm1data)
      setBusinessdata(businessForm2data)
      const wrapperimage = (businessForm2data.hero_image instanceof File || businessForm2data.hero_image instanceof Blob) ? URL.createObjectURL(businessForm2data.hero_image) : businessForm2data.hero_image
      setHeroImage(wrapperimage)
      const busnessimages = businessForm2data.companybusinesspage_images
      const businessImagesarray = []
      busnessimages.forEach(imageFile => {

        const wrapperimage = isBlobURL(imageFile.updated_image) ? imageFile.updated_image : (imageFile.updated_image instanceof File || imageFile.updated_image instanceof Blob) ? URL.createObjectURL(imageFile.updated_image) : imageFile.updated_image

        const obj = { 'business_page_image': wrapperimage, 'image_description': imageFile.image_description }
        businessImagesarray.push(obj)
      });
      console.log('businessImages', businessForm2data)
      setBusinessImgList(businessImagesarray)
    }
  }, []);

  useEffect(() => {
    document.title = businessName;
    const consentCookie = getCookieValue('cookie_consent_level');
    if (consentCookie) {
      const consentStatus = JSON.parse(decodeURIComponent(consentCookie));
      console.log('consentStatus', consentStatus.tracking);
      if (consentStatus.tracking) {
        trackPageView(window.location.pathname, businessName);
      }
    }
  }, [businessName])


  useEffect(() => {
    // Update logo styles whenever horizontal or vertical positions change
    const updateLogoStyles = () => {
      let horizontalStyles = {};
      let verticalStyles = {};

      switch (horizontalPosition) {
        case 'left':
          horizontalStyles = { left: 20 };
          break;
        case 'center':
          horizontalStyles = { left: verticalPosition == 'center' ? '40%' : '50%', transform: 'translateX(-50%)' };
          break;
        case 'right':
          horizontalStyles = { right: 20 };
          break;
        default:
          horizontalStyles = { left: '40%', transform: 'translateX(-50%)' };
          break;
      }

      switch (verticalPosition) {
        case 'top':
          verticalStyles = { top: '15%' };
          break;
        case 'center':
          verticalStyles = { top: '50%', transform: 'translateY(-50%)' };
          break;
        case 'bottom':
          verticalStyles = { bottom: '5%' };
          break;
        default:
          verticalStyles = { top: '50%', transform: 'translateY(-50%)' };
          break;
      }

      setLogoStyles({
        position: 'absolute',
        ...horizontalStyles,
        ...verticalStyles
      });
    };

    updateLogoStyles(); // Call the function when component mounts or when position changes

  }, [horizontalPosition, verticalPosition]);

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  function isBlobURL(url) {
    // Regular expression to match blob URLs
    const blobUrlRegex = /^blob:/i;
    return blobUrlRegex.test(url);
  }

  const handleMouseEnter = (index) => {
    const text = businessImageList.length > 0 ? businessImageList[index].image_description : businessImages.length > 0 ? businessImages[index].image_description : '';
    if (text != null && text.trim() !== '') {
      setPopoverText(text);
      setShwPopover(prevState => {
        const newState = [...prevState];
        newState[index] = true;
        return newState;
      });
    }
  };

  const handleMouseLeave = () => {
    setPopoverText('');
    // Hide all the popovers when mouse leaves any image
    setShwPopover(Array(5).fill(false));
  };

  const handleClosePopover = () => {
    // Hide all the popovers when popover is closed
    setShwPopover(Array(5).fill(false));
  }

  const getCompanyDetails = () => {
    setLoading(true)
    const searchParams = new URLSearchParams(location.search);

    let id = ''
    const companyId = searchParams.get('company_information_id');
    const token = searchParams.get('token');
    console.log('searchParams', searchParams)
    console.log('companyId', companyId)
    console.log('token', token)
    if (companyId != null && token != null) {
      id = companyId ? companyId : ''
      setToken(token)
    } else {
      console.log('companyName', companyName)
      id = companyid != undefined ? companyid : companyName != undefined ? companyName : userData.id
    }

    const url = `${URLS.BASE_URL}${URLS.COMPANY_DETAILS}${id}`;
    axios.defaults.headers.common["Accept"] = "application/json";
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.get(url)
      .then((response) => {
        console.log('response', response)
        setLoading(false)
        if (response?.data.status === 1) {
          const data = response?.data.data
          const companyName = data.formatted_company_name; // Access the company name from the response
          setbusinessName(companyName);
          setBusinessStatus(data.businesspage_status)
          const businessData = data.companybusinesspage
          // history.replace(`/businessPage/${companyName}`);
          if (businessData === null) {
            // alert(Strings.business_page_notfound);
            // setErrorMessage('')
            // setTimeout(() => {
            //   navigate(-1)
            // }, 200);
            setErrorMessage(Strings.business_page_notfound)
          } else {
            let title = `${data.company_name} auf campervanspot.com`
            setbusinessName(title)
            setIsTitleReady(true)

            // document.title = `${data.company_name} auf campervanspot.com`
            setBusinessdata(businessData)
            setBusinessImages(businessData.companybusinesspage_images)
            setHeroImage(businessData.hero_image)
            console.log('company_logo_horizontal_position', data.company_logo_horizontal_position)
            console.log('company_logo_vertical_position', data.company_logo_vertical_position)
            setHorizontalPosition(data.company_logo_horizontal_position)
            setverticalPosition(data.company_logo_vertical_position)

          }
          setCompanyData(data);
        } else {
          alert(response.data.message);
        }
      })
      .catch(function (error) {
        setLoading(false)
        // alert(error.response.data.message);
        setErrorMessage(error.response.data.message)
      });
  }

  function CompanyAddress() {
    return (
      <div>
        {companyData.street && companyData.street_number &&
          <span className='descriptionbody'>{companyData.street} {companyData.street_number}</span>
        }
        {(companyData.street || companyData.street_number) &&
          companyData.zip_code &&
          <span className='descriptionbody'>, {companyData.zip_code}
          </span>
        }
        {(companyData.street || companyData.street_number || companyData.zip_code) &&
          companyData.city &&
          <span className='descriptionbody'> {companyData.city}</span>
        }
        {(companyData.street || companyData.street_number || companyData.zip_code || companyData.city) &&
          companyData.country &&
          <span className='descriptionbody'>, {companyData.country}</span>
        }
      </div>
    );
  }


  return (
    <>
      <Header />
      {loading && (
        <div className="loader">
          <ClipLoader color={'#123abc'} loading={loading} css={override} size={50} />
        </div>
      )}
      {businessdata ?
        <div className="full-width-container" style={{ height: "100vh" }}>

          <div style={{ backgroundColor: 'grey', height: "98vh", backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: `url(${heroImage})` }} >
            {/* <img src={require('../../assets/dashboard_bg.png')} style={{ position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, width: '100%', height: '90%' }}></img> */}
            {(businessStatus == 'approved' && userData.id === companyData.user.id) ? <div style={{ backgroundColor: 'green', paddingLeft: 20, paddingRight: 20, paddingTop: 5, paddingBottom: 5, width: '100%', display: 'flex', justifyContent: 'center', zIndex: 900, position: 'absolute', marginTop: 75 }}>
              <span className='redtext' >{Strings.page_published}</span>
            </div> : (businessStatus == 'inprogress') ? <div style={{ backgroundColor: 'red', paddingLeft: 20, paddingRight: 20, paddingTop: 5, paddingBottom: 5, width: '100%', display: 'flex', justifyContent: 'center', zIndex: 900, position: 'absolute', marginTop: 75 }}>
              <span className='redtext' >{Strings.page_inprogress}</span>
            </div> : (businessStatus == 'waiting for approval') ? <div style={{ backgroundColor: 'orange', paddingLeft: 20, paddingRight: 20, paddingTop: 5, paddingBottom: 5, width: '100%', display: 'flex', justifyContent: 'center', zIndex: 900, position: 'absolute', marginTop: 75 }}>
              <span className='redtext' >{Strings.page_waiting}</span>
            </div> : null}
            <div className='companyLogo'
              style={logoStyles}
            >
              <img
                src={companyData ? companyData.company_logo != null ? companyData.company_logo : require('../../assets/placeholder-image.jpg') : require('../../assets/placeholder-image.jpg')}
                className="image"
              />
            </div>
          </div>
          <div className='businesswrapperContainer'>
            <div className='businessContainer'>
              <h2 className='heading_businesspage '>{companyData ? companyData.company_name : ''}
                <div className='headingborder' /></h2>
              {businessdata &&
                <Row>
                  {(businessdata.highlights_1 || businessdata.highlights_2 || businessdata.highlights_3 || businessdata.highlights_4 || businessdata.highlights_5 || businessdata.highlights_6) &&
                    <Col md={7}>
                      <div className='content-inner'>
                        <ul className="custom-list" style={{ fontSize: '2.5vh' }} >
                          {businessdata.highlights_1 && <li className='li' style={{ backgroundImage: `url(${process.env.PUBLIC_URL + "/check.png"})`, backgroundPosition: 'left center', display: 'flex', alignItems: 'center', paddingBottom: 20, backgroundSize: 'auto' }}>  <span style={{ marginLeft: '20px', marginTop: 12 }}>{businessdata ? businessdata.highlights_1 : ''}</span></li>}
                          {businessdata.highlights_2 && <li className='li' style={{ backgroundImage: `url(${process.env.PUBLIC_URL + "/check.png"})`, backgroundPosition: 'left center', display: 'flex', alignItems: 'center', paddingBottom: 20, backgroundSize: 'auto' }}> <span style={{ marginLeft: '20px', marginTop: 12 }}>{businessdata ? businessdata.highlights_2 : ''}</span></li>}
                          {businessdata.highlights_3 && <li className='li' style={{ backgroundImage: `url(${process.env.PUBLIC_URL + "/check.png"})`, backgroundPosition: 'left center', display: 'flex', alignItems: 'center', paddingBottom: 20, backgroundSize: 'auto' }}><span style={{ marginLeft: '20px', marginTop: 12 }}>{businessdata ? businessdata.highlights_3 : ''}</span></li>}
                          {businessdata.highlights_4 && <li className='li' style={{ backgroundImage: `url(${process.env.PUBLIC_URL + "/check.png"})`, backgroundPosition: 'left center', display: 'flex', alignItems: 'center', paddingBottom: 20, backgroundSize: 'auto' }}><span style={{ marginLeft: '20px', marginTop: 12 }}>{businessdata ? businessdata.highlights_4 : ''}</span></li>}
                          {businessdata.highlights_5 && <li className='li' style={{ backgroundImage: `url(${process.env.PUBLIC_URL + "/check.png"})`, backgroundPosition: 'left center', display: 'flex', alignItems: 'center', paddingBottom: 20, backgroundSize: 'auto' }}><span style={{ marginLeft: '20px', marginTop: 12 }}>{businessdata ? businessdata.highlights_5 : ''}</span></li>}
                          {businessdata.highlights_6 && <li className='li' style={{ backgroundImage: `url(${process.env.PUBLIC_URL + "/check.png"})`, backgroundPosition: 'left center', display: 'flex', alignItems: 'center', paddingBottom: 20, backgroundSize: 'auto' }}><span style={{ marginLeft: '20px', marginTop: 12 }}>{businessdata ? businessdata.highlights_6 : ''}</span></li>}
                        </ul>

                      </div>
                    </Col>
                  }

                  <Col md={5}>
                    <div className='contact-info'>
                      {companyData && <CompanyAddress />}
                      <div className='buttons-wrapper'>
                        {businessdata && ((businessdata.website_url !== "") && (businessdata.website_url !== null)) &&
                          <div className='button-inner'>
                            <a className='websitebtn' target="_blank" href={businessdata.website_url}>Zur Website</a>
                          </div>
                        }
                        {businessdata && ((businessdata.facebook_url !== "") && (businessdata.facebook_urll !== null)) &&
                          <div className='social-icons-wrapper'>
                            <div className='social-icon'>
                              <a href={businessdata.facebook_url} target="_blank" rel="noopener">
                                <img className='socialimg' src={require('../../assets/fblogo.png')} alt="Fb" loading="" />
                              </a>
                            </div>
                          </div>
                        }
                        {businessdata && ((businessdata.youtube_url !== "") && (businessdata.youtube_url !== null)) &&
                          <div className='social-icons-wrapper'>
                            <div className='social-icon'>
                              <a href={businessdata.youtube_url} target="_blank" rel="noopener" onClick={() => console.log('businessdata.youtube_url', businessdata.youtube_url)}>
                                <FaYoutube size={50} color='red' />
                              </a>
                            </div>
                          </div>
                        }
                        {businessdata && ((businessdata.instagram_url !== "") && (businessdata.instagram_url !== null)) &&
                          <div className='social-icons-wrapper'>
                            <div className='social-icon'>
                              <a href={businessdata.instagram_url} target="_blank" rel="noopener">
                                <img className='socialimg' src={require('../../assets/instalogo.webp')} alt="Instagram" loading="" />
                              </a>
                            </div>
                          </div>
                        }

                      </div>
                    </div>
                  </Col>
                </Row>
              }

            </div>
          </div>


          <div className='descriptionview'>
            <div className='row-fluid' style={{ marginTop: '4vmin' }}>
              {businessdata.headline != '' ?
                <span className='header5'>{businessdata ? businessdata.headline : ''}</span> : null}
              {businessdata.description != '' &&
                <p className='description'>
                  {businessdata ? (
                    <span dangerouslySetInnerHTML={{ __html: businessdata.description }} />
                  ) : (
                    ""
                  )}
                </p>}
            </div>
          </div>



          <div className='galleryvw'>

            <div className='row-fluid'>
              <Row className='rowimage' >
                <Col className='image_section' md={8}>
                  <Row className='rowimage'>
                    {[0, 1].map((index) => (
                      <OverlayTrigger
                        show={showPopover[index]}
                        trigger={['hover', 'focus']}
                        placement="top"
                        overlay={
                          <Popover placement="top" show={showPopover[index]} onHide={handleClosePopover}>
                            <div className='popover-content_page'>
                              <div style={{ color: 'white', fontSize: 15, paddingRight: 10, paddingLeft: 10 }} dangerouslySetInnerHTML={{ __html: popoverText }} />
                            </div>
                          </Popover>

                        }
                      >

                        <Col md={index % 2 === 0 ? 5 : 7} key={index} className="pic_items">
                          {(businessImages.length > 0 && businessImages.length > index) &&
                            <div className="pic" style={{ backgroundImage: (businessImages.length > 0 && businessImages.length > index) ? `url(${businessImages[index].business_page_image})` : businessImageList.length > 0 ? `url(${businessImageList[index].business_page_image})` : null }} onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={handleMouseLeave} />
                          }
                        </Col>
                      </OverlayTrigger>
                    ))}
                  </Row>
                  <Row className='rowimage'>
                    {[2, 3].map((index) => (
                      <OverlayTrigger
                        show={showPopover[index]}
                        trigger={['hover', 'focus']}
                        placement="top"
                        overlay={
                          <Popover placement="top" show={showPopover[index]} onHide={handleClosePopover}>
                            <div className='popover-content_page'>
                              <div style={{ color: 'white', fontSize: 15, paddingRight: 10, paddingLeft: 10 }} dangerouslySetInnerHTML={{ __html: popoverText }} />
                            </div>
                          </Popover>

                        }
                      >
                        <Col className='pic_items' md={index % 2 === 0 ? 7 : 5} key={index}>
                          {(businessImages.length > 0 && businessImages.length > index) &&
                            <div className="pic" style={{ backgroundImage: (businessImages.length > 0 && businessImages.length > index) ? `url(${businessImages[index].business_page_image})` : businessImageList.length > 0 ? `url(${businessImageList[index].business_page_image})` : null }} onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={handleMouseLeave} />
                          }
                        </Col>
                      </OverlayTrigger>
                    ))}
                  </Row>
                </Col>
                <Col className='right_image_section' md={4}>
                  <div className='right_column col-12'>
                    <OverlayTrigger
                      show={showPopover[4]}
                      trigger={['hover', 'focus']}
                      placement="top"
                      overlay={
                        <Popover placement="top" show={showPopover[4]} onHide={handleClosePopover}>
                          <div className='popover-content_page'>
                            <div style={{ color: 'white', fontSize: 15, paddingRight: 10, paddingLeft: 10 }} dangerouslySetInnerHTML={{ __html: popoverText }} />
                          </div>
                        </Popover>
                      }
                    >
                      {(businessImages.length > 0 && businessImages.length > 4) ?
                        <div className="pic" style={{ backgroundImage: (businessImages.length > 0 && businessImages.length > 4) ? `url(${businessImages[4].business_page_image})` : businessImageList.length > 0 ? `url(${businessImageList[4].business_page_image})` : null }} onMouseEnter={() => handleMouseEnter(4)} onMouseLeave={handleMouseLeave} />
                        : <div />
                      }
                    </OverlayTrigger>
                  </div>
                </Col>
              </Row>
            </div>
          </div >
          <Footer />
        </div > : null}
      {errorMessage != '' &&
        <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', height: '100vh', padding: 20 }}>
          <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', marginBottom: 20, flexDirection: 'column' }}>
            <img
              src={require("../../assets/logo.webp")}
              width="200vh"
              height="200vw"
              alt='logo'
              style={{ objectFit: 'contain' }}
              className="d-inline-block align-top"
            />
            <p style={{ marginBottom: 20, marginTop: 20 }} className='error_string'>
              404: Es tut uns sehr leid, aber wir konnten die von Dir gesuchte Seite nicht finden. Bitte starte nochmal von hier:
              <a href="https://campervanspot.com" style={{ textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer"> https://campervanspot.com</a>
            </p>
          </div>
        </div>
      }
    </>
  );
};

export default BusinessPage;