import React, { useState, useEffect, useContext, useRef } from 'react';
import '../../app.css'
import '../Search/Search.css'
import { useNavigate } from 'react-router-dom';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import { FaSearch } from "react-icons/fa";
import { BiUpArrowAlt } from "react-icons/bi";
import { BiDownArrowAlt } from "react-icons/bi";
import { Col, Row } from 'react-bootstrap';
import DropDown from '../../Components/DropDown/DropDown';
import { getCookieValue, URLS } from '../../utilities/constants';
import { DataContext } from '../../utilities/DataContext';
import axios from 'axios';
import { css } from '@emotion/react';
import { ClipLoader } from 'react-spinners';
import { MdEventSeat } from "react-icons/md";
import { MdBed } from "react-icons/md";
import { IoChevronBackCircle } from "react-icons/io5";
import { IoChevronForwardCircle } from "react-icons/io5";
import { BsThreeDots } from "react-icons/bs";
import CountryFlag from './CountryFlag';
import { trackPageView } from '../../hooks/analytics';


const override = css`
display: block;
margin: 0 auto;
border-color: red;
`;


const SearchScreen = () => {
  const navigate = useNavigate();
  const itemsListRef = useRef(null);
  const typeData = ['Kastenwagen', 'Teilintegriert', 'Vollintegriert']
  const [loading, setLoading] = useState(false);
  const [addsList, setAddsList] = useState([])
  const [addsListCount, setAddsListCount] = useState(0)
  const [manfacturerData, setManufacturerData] = useState([])
  const { token } = useContext(DataContext);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [showAdvanceSearch, setShwAdvancedSearch] = useState(false)
  const [conversionBrand, setConversionBrand] = useState([]);
  const [pageSize, setPageSize] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [formData, setFormData] = useState({
    noofbeds: '',
    pricerangefrom: '',
    pricerangeto: '',
    milagerangefrom: '',
    milagerangeto: '',
    type: [],
    chasisbrand: [],
    conversionbrnd: [],
    vehiclename: ''
  })

  const [currentPage, setCurrentPage] = useState(1);
  const [nextPage, setNextPage] = useState(1);
  const [totalPages, settotalPages] = useState(1)

  useEffect(() => {
    document.title = 'Suchergebnisse Campervans zu verkaufen';
    const consentCookie = getCookieValue('cookie_consent_level');
    if (consentCookie) {
      const consentStatus = JSON.parse(decodeURIComponent(consentCookie));
      console.log('consentStatus', consentStatus.tracking);
      if (consentStatus.tracking) {
        trackPageView(window.location.pathname, 'Suchergebnisse Campervans zu verkaufen')
      }
    }

  }, [])




  useEffect(() => {
    const updatePageSize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      const calculatedPageSize = calculatePageSize(width, height);
      setPageSize(calculatedPageSize);
      setCurrentPage(1)
      setNextPage(1)
      console.log('calculatedPageSize', calculatedPageSize)
      callAddsListApi(1, calculatedPageSize)
    };

    // Set initial page size
    updatePageSize();

    // Update page size on window resize
    window.addEventListener('resize', updatePageSize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', updatePageSize);
    };
  }, []);


  useEffect(() => {
    // document.title = 'Suchergebnisse Campervans zu verkaufen'
    callBasicDetailsistApi()
    const width = window.innerWidth;
    const height = window.innerHeight;
    const calculatedPageSize = calculatePageSize(width, height);
    setPageSize(calculatedPageSize);

    callAddsListApi(nextPage, calculatedPageSize)

  }, []);

  const calculatePageSize = (width, height) => {

    if (width < 600) {
      // Small devices like mobile
      return Math.floor((height / 100) * 0.5); // Adjust based on height
    } else if (width < 1024) {
      // Tablets
      return Math.floor((height / 100) * 1.2);
    } else {
      // Desktops
      return Math.floor((height / 100) * 1.3);
    }
  };



  const handleInputChange = (label, value) => {
    console.log('label, value', label, value)
    setFormData(prevFormData => {
      const updatedFormData = {
        ...prevFormData,
        [label]: value
      };
      console.log('Updated form data:', updatedFormData);
      return updatedFormData;
    });
  }

  // Handle page change
  const handlePageChange = (pageNumber) => {
    callAddsListApi(pageNumber, pageSize)
  };

  const handleDropdownToggle = (label) => {
    console.log('label', label)
    console.log('openDropdown', openDropdown)
    setOpenDropdown(openDropdown === label ? null : label);
  };

  const searchClicked = (searchback) => {
    setCurrentPage(1)
    setNextPage(1)
    callAddsListApi(1, pageSize)
  }

  // Handle previous and next button clicks
  const handlePrevious = () => {
    console.log('nextpage from previous', nextPage)
    if (nextPage > 1) {
      callAddsListApi(nextPage - 1, pageSize)
    }
  };

  const handleNext = () => {
    if (nextPage <= totalPages) {
      callAddsListApi(nextPage, pageSize)
    }
  };

  const advancedSearchClicked = () => {
    setShwAdvancedSearch(!showAdvanceSearch)
  }

  const adClicked = (item) => {
    // navigate('/adcreate', { state: { id: item.id } })
    const componentPage = `/ad/${item.id}`;
    navigate(componentPage)
  }

  const handleScrollToTop = () => {
    console.log('itemsListRef.current', itemsListRef.current)
    // Check if the ref exists and scroll to top
    if (itemsListRef.current) {
      itemsListRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const formatMilageNumber = (value) => {
    return new Intl.NumberFormat('de-DE').format(value);
  }



  const formatCurrency = (value, currency) => {
    console.log('currency', currency)
    if (value !== '' && currency !== '') {
      let locale = 'de-DE'; // German locale for both EUR and CHF
      let formattedValue = new Intl.NumberFormat(locale, {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value);
      return `${formattedValue} ${currency.toUpperCase()}`;
    } else {
      return ''
    }

  };


  const callBasicDetailsistApi = async () => {
    setLoading(true)
    const url = `${URLS.BASE_URL}${URLS.BASIC_DETAILS_LIST}`;
    await axios.get(url, { headers: { 'Authorization': token } })
      .then((response) => {
        setLoading(false)
        console.log('response', response);
        if (response?.data.status === 1) {
          if (response.data.data.Manufacturers.length > 0) {
            setManufacturerData(response.data.data.Manufacturers)
          }
          if (response.data.data.Conversion_brand.length > 0) {
            setConversionBrand(response.data.data.Conversion_brand)
          }
        } else {
          console.log('response', response);
          // setShowAlert(true)
          // setError(response.data.message)
        }
      })
      .catch(function (error) {
        console.log('error', error);
        setLoading(false)
        // if (error.response) {
        //   setShowAlert(true)
        //   setError(error.response.data.message)
        // }
      });
  }

  const buildUrlWithParams = (baseUrl, params) => {
    const query = Object.keys(params)
      .map(key => {
        const value = params[key];
        if (Array.isArray(value)) {
          return `${key}=${encodeURIComponent(JSON.stringify(value))}`;
        }
        return `${key}=${encodeURIComponent(value)}`;
      })
      .join('&');
    return `${baseUrl}?${query}`;
  };

  const callAddsListApi = async (pageNumber, pageSize) => {
    setLoading(true)
    const type = formData.type.includes('Alle') ? [] : formData.type
    const chasisBrand = formData.chasisbrand.includes('Alle') ? [] : formData.chasisbrand
    const conversionBrand = formData.conversionbrnd.includes('Alle') ? [] : formData.conversionbrnd

    const url = buildUrlWithParams(`${URLS.BASE_URL}${URLS.SEARCH_ADDS}`, {
      search: formData.vehiclename,
      chasis_brand: chasisBrand,
      conversion_brand: conversionBrand,
      min_mileage: formData.milagerangefrom,
      max_mileage: formData.milagerangeto,
      min_price: formData.pricerangefrom,
      max_price: formData.pricerangeto,
      type: type,
      total_no_of_beds: formData.noofbeds,
      page: pageNumber,
      page_size: pageSize
    });

    // const url = `${URLS.BASE_URL}${URLS.SEARCH_ADDS}search=${formData.vehiclename}&chasis_brand=${formData.chasisbrand.join(',')}&conversion_brand=${formData.conversionbrnd.join(',')}&min_mileage=${formData.milagerangefrom}&max_mileage=${formData.milagerangeto}&min_price=${formData.pricerangefrom}&max_price=${formData.pricerangeto}&type=${formData.type.join(',')}`;
    console.log('adurl', url)
    await axios.get(url, { headers: { 'Authorization': token } })
      .then((response) => {
        setLoading(false)
        if (response?.data.status === 1) {
          const data = response.data.data
          console.log('data', data)
          setAddsList(data);
          setAddsListCount(response.data.count)

          setCurrentPage(pageNumber);
          setNextPage(response.data.next !== null ? response.data.next : pageNumber);
          setHasMore(response.data.next !== null ? true : false);
          settotalPages(Math.ceil(response.data.count / pageSize))
          // setAddsList(data)
          handleScrollToTop()
        } else {
          console.log('response', response);
          alert(response.data.message);
        }
      })
      .catch(function (error) {
        setLoading(false)
        console.log('error', error);
        alert(error.response.data.message);
      });
  }

  // Generate pagination buttons with ellipses
  const renderPagination = () => {
    const pages = [];
    // const ellipsis = <span key="ellipsis" style={{ alignSelf: 'center', backgroundColor: 'green' }}>{'...'}</span>;
    const ellipsis = <BsThreeDots key="ellipsis" style={{ alignSelf: 'center' }} />
    console.log('totalPages', totalPages)
    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={currentPage === i ? 'active' : ''}
          >
            {i}
          </button>
        );
      }
    } else {
      // Show the first page
      pages.push(
        <button
          key={1}
          onClick={() => handlePageChange(1)}
          className={currentPage === 1 ? 'active' : ''}
        >
          1
        </button>
      );

      // Determine pages to show around the current page
      if (currentPage > 3) {
        pages.push(ellipsis);
      }

      const startPage = Math.max(2, currentPage);
      const endPage = Math.min(totalPages - 1, currentPage);
      console.log('currentPage', currentPage)
      for (let i = startPage; i <= endPage; i++) {
        console.log('startPage', startPage)
        pages.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={currentPage === i ? 'active' : ''}
          >
            {i}
          </button>
        );
      }

      if (currentPage < totalPages - 2) {
        pages.push(ellipsis);
      }

      // Show the last page
      pages.push(
        <button
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          className={currentPage === totalPages ? 'active' : ''}
        >
          {totalPages}
        </button>
      );
    }
    return pages;
  };



  return (
    <>

      <Header />
      <div>
        <div
          className='banner_search'
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + "/search_bg.png"
              })`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            paddingLeft: '10vmin',
            paddingRight: '10vmin',

          }}
        >
          <div className="blur_div">
            <div style={{ backgroundColor: 'rgba(0,0,0,0.45)', borderRadius: 5, width: '80%', height: 50, paddingLeft: '5vmin', display: 'flex', alignItems: 'center' }}>
              <input placeholder='Was suchen Sie?' className='main_search_input' style={{ color: 'white', fontSize: 16, flex: 1 }} value={formData.vehiclename} onChange={e => handleInputChange('vehiclename', e.target.value)} />
              <div style={{ backgroundColor: 'black', padding: 15, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 2, paddingRight: '5vmin', paddingLeft: '5vmin' }} onClick={searchClicked}>
                <FaSearch color='white' />
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', margin: 10, marginBottom: 20, cursor: 'pointer', justifyContent: 'center', }} onClick={advancedSearchClicked}>
              <span style={{ color: 'white', fontSize: 15 }}>Erweiterte Suche</span>
              {showAdvanceSearch ? <BiUpArrowAlt size={20} color='white' /> : <BiDownArrowAlt size={20} color='white' />}
            </div>
            {showAdvanceSearch &&
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Row style={{ width: '100%', justifyContent: 'center' }}>

                  <Col xxl={6} xl={6} lg={7} md={10} sm={12} style={{ marginTop: 20, justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <span className='small_header_search'>Interior</span>
                    <div style={{ marginTop: 30 }}>
                      <Row style={{ alignItems: 'center' }}>
                        <Col xxl={4} xl={4} lg={5} md={5} sm={5} xs={6}>
                          <span style={{ color: 'white', fontSize: 15 }}>Anzahl der Betten:</span>
                        </Col>
                        <Col xxl={3} xl={3} lg={4} md={4} sm={7} xs={6}>
                          <input min={0} className='search_input' type={'number'} value={formData.noofbeds} onChange={e => handleInputChange('noofbeds', e.target.value)} ></input>
                        </Col>
                      </Row>

                      <Row style={{ marginTop: 10, alignItems: 'center' }}>
                        <Col xxl={4} xl={4} lg={5} md={5} sm={4} xs={5}>
                          <span style={{ color: 'white', fontSize: 15, }}>Preis:</span>
                        </Col>
                        <Col xxl={8} xl={8} lg={7} md={7} sm={8} xs={7}>
                          <Row>
                            <Col xxl={4} xl={5} lg={6} md={6} sm={6} xs={6} style={{ marginTop: 5 }}>
                              <input className='search_input placeholder-search' min={0} placeholder='Von' type={'number'} value={formData.pricerangefrom} onChange={e => handleInputChange('pricerangefrom', e.target.value)} ></input>
                            </Col>
                            <Col xxl={4} xl={5} lg={6} md={6} sm={6} xs={6} style={{ marginTop: 5 }}>
                              <input className='search_input' placeholder='Bis' min={0} type={'number'} value={formData.pricerangeto} onChange={e => handleInputChange('pricerangeto', e.target.value)} ></input>
                            </Col>
                          </Row>
                        </Col>


                      </Row>

                      <Row style={{ marginTop: 10, alignItems: 'center' }}>
                        <Col xxl={4} xl={4} lg={5} md={5} sm={4} xs={5}>
                          <span style={{ color: 'white', fontSize: 15, }}>Kilometer:</span>
                        </Col>
                        <Col xxl={8} xl={8} lg={7} md={7} sm={8} xs={7}>
                          <Row>
                            <Col xxl={4} xl={5} lg={6} md={6} sm={6} xs={6} style={{ marginTop: 5 }}>
                              <input className='search_input placeholder-search' placeholder='Von' min={0} type={'number'} value={formData.milagerangefrom} onChange={e => handleInputChange('milagerangefrom', e.target.value)} ></input>
                            </Col>
                            <Col xxl={4} xl={5} lg={6} md={6} sm={6} xs={6} style={{ marginTop: 5 }}>
                              <input className='search_input' placeholder='Bis' type={'number'} min={0} value={formData.milagerangeto} onChange={e => handleInputChange('milagerangeto', e.target.value)} ></input>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  <Col xxl={6} xl={6} lg={5} md={10} sm={12} style={{ marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <span className='small_header_search'>Fahrzeugdaten</span>
                    <Row style={{ margin: 10, marginTop: 20, width: '100%', alignItems: 'center', justifyContent: 'flex-end' }}>
                      <Col xxl={4} xl={5} md={5} sm={5} xs={6}>
                        <span style={{ color: 'white', fontSize: 15, }}>Typ:</span>
                      </Col>
                      <Col xxl={4} xl={5} md={6} sm={7} xs={6}>
                        <DropDown
                          type={'text'}
                          isOpen={openDropdown === 'Typ'}
                          onToggle={() => handleDropdownToggle('Typ')}
                          label="Typ"
                          value={formData.type}
                          dropDownData={typeData}
                          onChange={e => handleInputChange('type', e.target.value)}
                          onSelect={(item) => handleInputChange('type', item)}
                        />
                      </Col>
                    </Row>
                    <Row style={{ margin: 10, marginTop: 20, width: '100%', alignItems: 'center', justifyContent: 'flex-end' }}>
                      <Col xxl={4} xl={5} md={5} sm={5} xs={6}>
                        <span style={{ color: 'white', fontSize: 15 }}>Chassis Brand:</span></Col>
                      <Col xxl={4} xl={5} md={6} sm={7} xs={6}>
                        <DropDown
                          type={'text'}
                          isOpen={openDropdown === 'Chasis'}
                          onToggle={() => handleDropdownToggle('Chasis')}
                          label="Chasis"
                          value={formData.chasisbrand}
                          dropDownData={manfacturerData}
                          onChange={e => handleInputChange('chasisbrand', e.target.value)}
                          onSelect={(item) => handleInputChange('chasisbrand', item)}
                        />
                      </Col>
                    </Row>
                    <Row style={{ margin: 10, marginTop: 20, width: '100%', alignItems: 'center', justifyContent: 'flex-end' }}>
                      <Col xxl={4} xl={5} md={5} sm={5} xs={6}>
                        <span style={{ color: 'white', fontSize: 15 }}>Conversion Brand:</span></Col>
                      <Col xxl={4} xl={5} md={6} sm={7} xs={6}>
                        <DropDown
                          type={'text'}
                          isOpen={openDropdown === 'Conversion'}
                          onToggle={() => handleDropdownToggle('Conversion')}
                          label="Conversion"
                          value={formData.conversionbrnd}
                          dropDownData={conversionBrand}
                          onChange={e => handleInputChange('conversionbrnd', e.target.value)}
                          onSelect={(item) => handleInputChange('conversionbrnd', item)}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            }
            <button className="btnseach" onClick={() => searchClicked('')}>
              {'Suchen'}
            </button>
          </div>

        </div>
        <div className='addcardcontainer' ref={itemsListRef} >
          {/* <div style={{ display: "flex", paddingTop: 10, flexDirection: "column", paddingBottom: 30 }}> */}

          <div className='addsection_cards'>
            {addsList.map((item, index) => (
              <div onClick={() => adClicked(item)} className='adimage' style={{ backgroundImage: item.hero_image_url != null ? `url(${item.hero_image_url})` : null, alignItems: 'flex-end', display: 'flex', justifyContent: 'flex-end' }}>
                {/* <div className='adoverlay' /> */}
                <div className='adddata' style={{ width: '100%' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', paddingLeft: 20, width: '100%', paddingTop: 5, paddingBottom: 5 }}>
                    <CountryFlag countryCode={item.country_code} />
                    {/* <h1 className='adtitle' style={{ fontSize: 17, fontWeight: 400, color: 'white', marginBottom: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.vehicle_name !== '' ? item.vehicle_name : '--'}</h1> */}
                    <h1 style={{ fontSize: 17, fontWeight: 400, color: 'white', marginBottom: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.vehicle_name !== '' ? item.vehicle_name : '--'}</h1>
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div>
                      <div>
                        <MdEventSeat color='white' />
                        <span className='addetails'>{item.no_of_seats !== null && item.no_of_seats !== 0 ? item.no_of_seats : '--'}</span>
                      </div>
                      <div>
                        <MdBed color='white' size={15} />
                        <span className='addetails'>{item.total_no_of_beds !== null && item.total_no_of_beds !== 0 ? item.total_no_of_beds : '--'}</span>
                      </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div>

                        <div md={3}>
                          <span className='addetails'>{item.year_of_manufacture !== null ? item.year_of_manufacture : '--'}</span> </div>
                        <div md={9}><span className='addetails'>{item.mileage_km !== null && item.mileage_km !== 0 ? `${formatMilageNumber(item.mileage_km)} km` : '--'}</span></div>
                      </div>
                      <div>
                        <span className='addetails'>{(item.price !== null && item.currency !== null) ? formatCurrency(item.price, item.currency) : '--'}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {addsList.length > 0 &&
            <div className="pagination_ad">
              <button onClick={handlePrevious} disabled={currentPage === 1} className={currentPage === 1 ? 'disabled' : ''}>
                <IoChevronBackCircle size={30} />
              </button>
              {renderPagination()}
              <button onClick={handleNext} disabled={currentPage === totalPages} className={currentPage === totalPages ? 'disabled' : ''}>
                <IoChevronForwardCircle size={30} />
              </button>

            </div>
          }
          {/* </div> */}
        </div>
        {loading && (
          <div className="loader">
            <ClipLoader color={'#123abc'} loading={loading} css={override} size={50} />
          </div>
        )
        }
      </div >



      <Footer />
    </>
  );
};

export default SearchScreen;