import React, { useEffect, useState } from "react";
import { Button, Row, Col } from "react-bootstrap";
import './ImageTextEditor.css'
import { IoMdArrowBack } from "react-icons/io";
import TopLabelInput from '../TpLabelInput/TopLabelInput';
import ReactQuill from 'react-quill'
import { FiAlertTriangle } from "react-icons/fi";
import ImageSlider from "./ImageSlider";

const ImageTextEditor = ({ showModal, closeModal, imageData, onSaveImageData, showHeader, onSavedata, images, title, singleimg }) => {
    const [header, setHeader] = useState('')
    const [description, setDescription] = useState('')

    const [txteditorErr, setTxtEditorErr] = useState({
        heading: "",
        description: "",
    });

    function isBlobURL(url) {
        // Regular expression to match blob URLs
        const blobUrlRegex = /^blob:/i;
        return blobUrlRegex.test(url);
    }



    var modules = {
        toolbar: [
            [{ size: ["small", false, "large", "huge"] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link", "image"],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
                { align: [] }
            ],
            [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
        ]
    };

    var formats = [
        "header", "height", "bold", "italic",
        "underline", "strike", "blockquote",
        "list", "color", "bullet", "indent",
        "link", "image", "align", "size",
    ];

    useEffect(() => {
        console.log('imagedata', imageData)
        setHeader(imageData.header)
        setDescription(singleimg ? imageData.image_description : imageData.description)
    }, [showModal])


    const validateTextEditorData = () => {
        const errors = {};
        if (header === undefined || header === '') {
            errors.heading = 'Bitte füllen Sie dieses Pflichtfeld aus.';
        } else if (description === undefined || description === '') {
            console.log('errordesc')
            errors.description = 'Bitte füllen Sie dieses Pflichtfeld aus.';
        }
        return errors;
    }

    const handleSaveChanges = () => {
        // const errors = validateTextEditorData()
        // setTxtEditorErr(errors);
        // if (Object.keys(errors).length === 0) {
        if (singleimg) {
            onSaveImageData(description)
        } else {
            onSavedata(header, description)
        }

        // }
    }

    const handleInputChange = (value) => {
        setHeader(value);
    }

    const handleDescriptionChange = (content) => {
        setDescription(content);
    }

    return (
        // <Modal show={showModal} onHide={closeModal} style={{ backgroundColor: 'rgba(255,255,255,0.3)' }}>
        //     <div className="maincontent" >
        //         <span>hello</span>
        //         <textarea aria-placeholder="Enter text here"></textarea>
        //     </div>
        // </Modal>
        <div className={`modal ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'flex' : 'none', justifyContent: 'center' }}>
            <div className="modal-inner modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="back-button" onClick={closeModal}>
                            <IoMdArrowBack />
                            {title}
                        </div>
                    </div>
                    <div className="modal-body">
                        <Row>
                            <Col md={4}>
                                <div className="businees_image">
                                    {
                                        singleimg ?
                                            <img
                                                src={imageData.original_image instanceof Blob ? URL.createObjectURL(imageData.original_image) : imageData.original_image}
                                                alt='logo'
                                                style={{ objectFit: 'contain', borderRadius: 5, height: '10vmin', width: '10vw' }}

                                            /> :
                                            <div>
                                                {images.length > 0 ?
                                                    <ImageSlider images={images} /> :
                                                    <div style={{ backgroundColor: 'gray', height: '15vmin', width: '15vw', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 5 }}>
                                                        <img
                                                            src={require("../../assets/placeholder.png")}
                                                            alt='logo'
                                                            style={{ objectFit: 'contain', borderRadius: 5, height: '10vmin', width: '10vw' }}

                                                        />
                                                    </div>
                                                }
                                            </div>
                                    }
                                </div>
                            </Col>
                            <Col md={8}>
                                {showHeader &&
                                    <div>
                                        <TopLabelInput type={'text'} label="Überschrift" value={header} onChange={e => handleInputChange(e.target.value)} />
                                        {txteditorErr.heading && (
                                            <div style={{ display: 'flex', marginBottom: 10 }}>
                                                <FiAlertTriangle color='red' />
                                                <div className="error-message">{txteditorErr.heading}</div>
                                            </div>
                                        )}
                                    </div>
                                }
                                {/* <div className='descriptionView'>
                                <span className='floatlabel'>Beschreibung</span>
                                <textarea className="descriptiontextarea" value={description} onInput={handleDescriptionChange} />
                            </div> */}
                                <div>
                                    <ReactQuill
                                        theme="snow"
                                        modules={modules}
                                        className='quill'
                                        formats={formats}
                                        value={description}
                                        placeholder="Beschreibung ...."
                                        onChange={handleDescriptionChange}
                                    />
                                    {txteditorErr.description && (
                                        <div style={{ display: 'flex', marginBottom: 10 }}>
                                            <FiAlertTriangle color='red' />
                                            <div className="error-message">{txteditorErr.description}</div>
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </Row>


                    </div>
                    <Button className="next_button_text" onClick={handleSaveChanges}>Übernehmen!</Button>
                </div>
            </div>
        </div>
    )
}

export default ImageTextEditor;