import React, { useState, createRef, useRef, useContext, useEffect } from 'react';
import { Collapse, Card, Row, Col, Popover, OverlayTrigger, Button, Modal } from 'react-bootstrap';
import '../../app.css'
import '../Ads/Ads.css';
import { TiTick } from "react-icons/ti";
import { newtaburl, Strings, URLS } from '../../utilities/constants';
import axios from 'axios';
import { DataContext } from '../../utilities/DataContext';
import ReactQuill from 'react-quill'
import { MdEdit } from "react-icons/md";
import { css } from '@emotion/react';
import { ClipLoader } from 'react-spinners';
import TopLabelInput from '../../Components/TpLabelInput/TopLabelInput';
import ImageTextEditor from '../../Components/ImageTextEditor/ImageTextEditor';
import 'quill/dist/quill.snow.css'
import { IoIosDesktop } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { MdOutlineClose } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import 'react-image-crop/dist/ReactCrop.css';
import { useNavigate } from 'react-router-dom';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import AlertComponent from "../../Components/AlertComponent";
import { tab } from '@testing-library/user-event/dist/tab';

var modules = {
  toolbar: [
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
      { align: [] }
    ],
    [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
  ]
};

var formats = [
  "header", "height", "bold", "italic",
  "underline", "strike", "blockquote",
  "list", "color", "bullet", "indent",
  "link", "image", "align", "size",
];

const AdGallery = ({ onBackPress, onNextPress, tabchanged, addId, onClick }) => {
  const navigate = useNavigate();
  const cropperRef = useRef(null);
  const { userData, token, addDetails, setAddDetails, tab } = useContext(DataContext);
  const [popoverOpen, setPopoverOpen] = useState({ sectionIndex: null, imageIndex: null });
  const [showDeletePopover, setShowDeletePopover] = useState({ sectionIndex: null, imageIndex: null });
  const [showModal, setShowModal] = useState(false);
  const [crop, setCrop] = useState({ unit: '%', width: 100, height: 100 });
  const [imageRef, setImageRef] = useState(null);
  const [imageUrl, setImgUrl] = useState(null)
  const [sectionindex, setSectionIndex] = useState(0)
  const [imageIndex, setImageIndex] = useState(0)
  const [cropedImage, setCropedImage] = useState(null)
  const [galleryTabOpened, setGalleryTabOpend] = useState(false)
  const [showImageTextEditor, setShowImageTextEditor] = useState(false)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false);
  const [showalert, setShowAlert] = useState(false)

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  const [sections, setSections] = useState([
    { id: 1, title: 'Außen', vehicle_category_images: [], isOpen: false, header: '', description: '' },
    { id: 2, title: 'Wohnen', vehicle_category_images: [], isOpen: false, header: '', description: '' },
    { id: 3, title: 'Küche', vehicle_category_images: [], isOpen: false, header: '', description: '' },
    { id: 4, title: 'Schlafen', vehicle_category_images: [], isOpen: false, header: '', description: '' },
    { id: 5, title: 'Cockpit', vehicle_category_images: [], isOpen: false, header: '', description: '' },
    { id: 6, title: 'Dach', vehicle_category_images: [], isOpen: false, header: '', description: '' },
    { id: 7, title: 'Keller', vehicle_category_images: [], isOpen: false, header: '', description: '' },

    // Add more sections as needed
  ]);

  const inputRefs = useRef(Array(sections.length).fill().map(() => createRef()));
  const sectionsDataRef = useRef(sections);

  useEffect(() => {
    setGalleryTabOpend(true)
  }, [galleryTabOpened]);


  useEffect(() => {
    if (galleryTabOpened !== true) {
      if (addId !== undefined && addId !== '') {
        callAddDetailApi(addId, '')
      } else if (!isEmptyObject(addDetails) && addDetails.vehicle_ad_overview_data.id) {
        callAddDetailApi(addDetails.vehicle_ad_overview_data.id, '')
      }
    }


    return () => {
      console.log('tabchangedgallery', tab)
      if (tab !== 'gallery' && tab !== '') {
        setGalleryTabOpend(false)
        handleNextClick('tabchange')
      }
    };
  }, [tab]);


  useEffect(() => {
    sectionsDataRef.current = sections;
  }, [sections]);

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const handleMouseEnter = (sectionIndex, imageIndex) => {
    setPopoverOpen({ sectionIndex, imageIndex });
  };

  const handleMouseLeave = () => {
    setPopoverOpen({ sectionIndex: null, imageIndex: null });
  };

  const handleDeleteClick = (sectionIndex, imageIndex) => {
    setPopoverOpen({ sectionIndex: null, imageIndex: null });
    console.log('sectionIndex', sectionIndex, 'imageIndex', imageIndex)
    setShowDeletePopover({ sectionIndex, imageIndex });
  };

  const handleEditClick = (sectionIndex, imgIndex) => {
    const section = sections[sectionIndex];
    const image = section.vehicle_category_images[imgIndex]
    setImgUrl((image.original_image instanceof File || image.original_image instanceof Blob) ? URL.createObjectURL(image.original_image) : image.original_image)
    setSectionIndex(sectionIndex)
    setImageIndex(imgIndex)
    setPopoverOpen({ sectionIndex: null, imageIndex: null });
    setShowModal(true);
  };



  const onCrop = () => {
    const cropper = cropperRef.current?.cropper;
    const croppedCanvas = cropper.getCroppedCanvas();
    console.log('blob', croppedCanvas); // This is the cropped canvas

    // Convert canvas to blob
    croppedCanvas.toBlob(blob => {
      // Log the blob object
      console.log('blob', blob);
      // Generate a URL for the blob
      const blobUrl = URL.createObjectURL(blob);
      setCropedImage(blob)
      // Log the blob URL
      console.log('blob URL', blobUrl);
    });
  };


  function generateRandomFileName() {
    const randomNumber = Math.floor(10 + Math.random() * 90); // Generates a random number between 10 and 99
    return `image_${randomNumber}.png`;
  }

  function createFileFromBlob(blob) {
    console.log('blob', blob)
    const fileName = generateRandomFileName();
    return new File([blob], fileName, { type: blob.type });
  }

  // const handleImageLoaded = (e) => {
  //   const img = e.target;
  //   setImageRef(img);
  // };

  const handleCropChange = (newCrop) => {
    setCrop(newCrop);
    if (imageRef && newCrop.width && newCrop.height) {
      // Get the cropped area from the original image
      const canvas = document.createElement('canvas');
      const scaleX = imageRef.naturalWidth / imageRef.width;
      const scaleY = imageRef.naturalHeight / imageRef.height;
      canvas.width = crop.width * scaleX;
      canvas.height = crop.height * scaleY;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(
        imageRef,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY
      );
      canvas.toBlob((blob) => {
        if (blob) {
          try {
            setCropedImage(blob)
          } catch (error) {
            console.error('Error creating object URL:', error);
          }
        } else {
          console.error('Failed to generate blob from canvas');
        }
      }, 'image/png');
    }
  };

  const saveImgCrop = () => {
    callAddGalleryUpdateApi(sections[sectionindex].title, cropedImage)
    // setSections(prevSections => {
    //   const updatedSections = [...prevSections];
    //   const sectionToUpdate = { ...updatedSections[sectionindex] };
    //   const updatedImages = [...sectionToUpdate.vehicle_category_images];

    //   // Update the image at the specified index
    //   if (imageIndex >= 0 && imageIndex < updatedImages.length) {
    //     updatedImages[imageIndex]['updated_image'] = cropedImage;
    //   } else {
    //     // Handle error if the image index is invalid
    //     console.error('Invalid image index');
    //   }

    //   sectionToUpdate.vehicle_category_images = updatedImages;
    //   updatedSections[sectionindex] = sectionToUpdate;
    //   return updatedSections;
    // });
    setShowModal(false); // Close the modal after saving changes
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseDeletePopover = (sectionIndex, imageIndex) => {
    setShowDeletePopover({ sectionIndex: null, imageIndex: null });
    setPopoverOpen({ sectionIndex, imageIndex });
  }

  const handleConfirmDelete = (sectionIndex, imageIndex) => {
    setShowDeletePopover({ sectionIndex: null, imageIndex: null });
    setPopoverOpen({ sectionIndex, imageIndex });
    const section = sections[sectionIndex];
    const updatedImages = [...section.vehicle_category_images];
    callAddGalleryDeleteApi(updatedImages[imageIndex])
  }


  const handleImageUpload = (event, index) => {

    //Old code for single image
    // console.log('images', event.target.files)
    // const file = event.target.files[0];
    // addImageToSection(index, file);


    //new code for multiple images

    const files = event.target.files;
    callAdGalleryCreateApi(sections[index].title, files);
    // if (files.length > 0) {
    //   uploadImagesSequentially(Array.from(files), index);
    // }
  };

  const uploadImagesSequentially = async (files, index) => {
    console.log('files', files)
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const success = addImageToSection(index, file);
      console.log('success:', success);
      if (!success) {
        console.log('Upload failed for file:', file);
      }
    }
    handleNextClick('gallery');

  };

  const addImageToSection = (index, imagefile) => {
    console.log('imagefile', imagefile)
    if (imagefile === undefined) {
      return
    }
    const updatedSections = [...sections]; // Create a copy of the sections array
    const imagobj = { 'original_image': imagefile, 'updated_image': imagefile, id: '', isLoaded: false }
    updatedSections[index].vehicle_category_images.push(imagobj);
    setSections(updatedSections);
    // callAdGalleryCreateApi(updatedSections[index].title, imagobj.updated_image)
    // console.log('updatedSections', updatedSections)


    const success = callAdGalleryCreateApi(updatedSections[index].title, imagobj.updated_image);
    console.log('updatedSections', updatedSections);

    return success;
  };

  const toggleSection = (sectionIndex) => {
    setSections(prevSections => {
      const newSections = [...prevSections];
      newSections[sectionIndex].isOpen = !newSections[sectionIndex].isOpen;
      return newSections;
    });
  };


  const handleImageLoaded = async (src, sectionIndex, imageIndex) => {
    const imageObj = sections[sectionIndex].vehicle_category_images[imageIndex];
    // Check if the image has already been loaded
    if (imageObj.isLoaded) {
      return;
    }
    try {
      let blob;
      if (src instanceof File || src instanceof Blob) {
        blob = src;
      } else {
        const response = await fetch(src);
        blob = await response.blob();
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const imgElement = new Image();
          imgElement.src = reader.result;

          imgElement.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // Set maximum width and height (adjust as needed)
            const maxWidth = 1920; // You can adjust this value
            const maxHeight = 1080; // You can adjust this value

            // Calculate new dimensions to fit within maxWidth and maxHeight while preserving aspect ratio
            let newWidth = imgElement.width;
            let newHeight = imgElement.height;

            if (newWidth > maxWidth || newHeight > maxHeight) {
              const aspectRatio = newWidth / newHeight;

              if (newWidth > maxWidth) {
                newWidth = maxWidth;
                newHeight = newWidth / aspectRatio;
              }

              if (newHeight > maxHeight) {
                newHeight = maxHeight;
                newWidth = newHeight * aspectRatio;
              }
            }

            canvas.width = newWidth;
            canvas.height = newHeight;

            // Draw the image on the canvas with new dimensions
            ctx.drawImage(imgElement, 0, 0, newWidth, newHeight);

            canvas.toBlob((blob) => {
              const file = new File([blob], 'image.jpg', {
                type: 'image/jpeg', // Change the type according to your original image
                lastModified: Date.now()
              });


              console.log(`${imageIndex}Converted business image file size:`, file.size);
              console.log("Converted business image file: ", file);
              const updatedSections = [...sections];
              updatedSections[sectionIndex].vehicle_category_images[imageIndex].updated_image = blob;
              updatedSections[sectionIndex].vehicle_category_images[imageIndex].isLoaded = true;
              setSections(updatedSections);
              console.log("updatedSections: ", updatedSections);
            }, 'image/jpeg', 0.9); // Adjust JPEG quality as needed (0.7 is just an example)
          };
        }
      }


    } catch (error) {
      console.error('Error loading image as Blob:', error);
    }

  };

  const handleBackClick = () => {
    if (addDetails && !isEmptyObject(addDetails)) {
      handleNextClick('back')
    } else {
      onBackPress()
    }

  }

  const handleNextClick = (btnAction) => {
    if (!isEmptyObject(addDetails) && !isEmptyObject(addDetails.vehicle_ad_gallery_category_descriptions_data)) {
      console.log('emptyobject', addDetails.vehicle_ad_gallery_category_descriptions_data)
      callAdGalleryDataUpdateApi(btnAction)
    } else {
      if (addDetails && !isEmptyObject(addDetails)) {
        callAdGalleryDataCreateApi(btnAction)
      }
    }
  }



  const handleInputChange = (value, sectionIndex) => {
    setSections(prevSections => {
      const updatedSections = prevSections.map((section, index) =>
        index === sectionIndex ? { ...section, header: value, description: section.description === '<p><br></p>' ? '' : section.description } : section
      );
      console.log('updatedSections', updatedSections)
      return updatedSections;
    });
  };


  const handleDescriptionChange = (content, sectionIndex) => {
    console.log('content', content)
    // setDescription(content);
    setSections(prevSections => {
      const updatedSections = prevSections.map((section, index) =>
        index === sectionIndex ? { ...section, description: content === '<p><br></p>' ? '' : content } : section
      );
      console.log('updatedSections', updatedSections)
      return updatedSections;
    });
  }


  const formatAddName = (name) => {
    return name
      .toLowerCase()                          // Convert to lowercase
      .replace(/[^a-z0-9]+/g, '-')            // Replace non-alphanumeric characters with a hyphen
      .replace(/^-+|-+$/g, '');               // Remove leading or trailing hyphens
  };

  const openNewTab = () => {
    // Check if running in development or production
    const formattedAddName = formatAddName(addDetails.vehicle_ad_overview_data.vehicle_name);
    // const baseUrl =
    //   process.env.NODE_ENV === "development"
    //     ? URLS.APP_LOCAL_URL
    //     : URLS.APP_STAGIN_URL;
    // const formattedCompanyName = formatCompanyName(userData.company_name);
    const baseUrl = newtaburl
    const componentPage = `ad/${addDetails.vehicle_ad_overview_data.id}`;
    const url = baseUrl + componentPage;
    window.open(url, "_blank");
  };


  const callAdGalleryDataUpdateApi = async (btnAction) => {
    setLoading(true)
    const url = `${URLS.BASE_URL}${URLS.VEHICLE_ADD_GALLERY_DATA_UPDATE}${addDetails.vehicle_ad_overview_data.id}/`;
    const vehicle_ad_overview = addDetails.vehicle_ad_overview_data.id;
    const result = sectionsDataRef.current.map(item => ({
      vehicle_feature_category: item.title,
      category_heading: item.header,
      category_description: item.description
    }));
    console.log('resultarray', result)
    const form = new FormData();
    form.append('vehicle_ad_overview', vehicle_ad_overview)
    form.append('category_descriptions_data', JSON.stringify(result))
    const formDataObject = {};
    form.forEach((value, key) => {
      formDataObject[key] = value;
    });

    console.log('formDataObject', formDataObject);
    axios.defaults.headers.common["Accept"] = "application/json";
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
    await axios.put(url, form, { headers: { 'Authorization': token } })
      .then((response) => {
        setLoading(false)
        console.log('response', response);
        if (response?.data.status === 1) {
          callAddDetailApi(addDetails.vehicle_ad_overview_data.id, btnAction)
          // if (btnAction == 'next') {
          //   onNextPress()
          // } else if (btnAction == 'preview') {
          //   openNewTab()
          // } else if (btnAction == 'back') {
          //   onBackPress()
          // } else if (btnAction == 'tabchange') {
          //   onClick()
          // }
        } else {
          console.log('response', response);
          setShowAlert(true)
          setError(response.data.message)
        }
      })
      .catch(function (error) {
        console.log('error', error);
        setLoading(false)
        if (error.response) {
          setShowAlert(true)
          setError(error.response.data.message)
        }
      });

  }

  const callAdGalleryDataCreateApi = async (btnAction) => {
    setLoading(true)
    const url = `${URLS.BASE_URL}${URLS.VEHICLE_ADD_GALLERY_DATA_CREATE}`;

    const vehicle_ad_overview = addDetails.vehicle_ad_overview_data.id;
    const result = sectionsDataRef.current.map(item => ({
      vehicle_feature_category: item.title,
      category_heading: item.header,
      category_description: item.description
    }));
    console.log('resultarray', result)
    const form = new FormData();
    form.append('vehicle_ad_overview', vehicle_ad_overview)
    form.append('category_descriptions_data', JSON.stringify(result))
    const formDataObject = {};
    form.forEach((value, key) => {
      formDataObject[key] = value;
    });

    console.log('formDataObject', formDataObject);
    axios.defaults.headers.common["Accept"] = "application/json";
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
    await axios.post(url, form, { headers: { 'Authorization': token } })
      .then((response) => {
        setLoading(false)
        console.log('response', response);
        if (response?.data.status === 1) {
          callAddDetailApi(addDetails.vehicle_ad_overview_data.id, btnAction)
          // if (btnAction == 'next') {
          //   onNextPress()
          // } else if (btnAction == 'preview') {
          //   openNewTab()
          // } else if (btnAction == 'back') {
          //   onBackPress()
          // } else if (btnAction == 'tabchange') {
          //   onClick()
          // }
        } else {
          console.log('response', response);
          setShowAlert(true)
          setError(response.data.message)
        }
      })
      .catch(function (error) {
        setLoading(false)
        console.log('error', error);
        if (error.response) {
          setShowAlert(true)
          setError(error.response.data.message)
        }
      });
  }

  const callAddGalleryUpdateApi = async (category, updatedImage) => {
    setLoading(true)
    const imageId = sections[sectionindex].vehicle_category_images[imageIndex].id
    const url = `${URLS.BASE_URL}${URLS.VEHICLE_ADD_GALLERY_UPDATE}${imageId}/`;
    let croppedImage = null
    if (updatedImage instanceof Blob) {
      croppedImage = createFileFromBlob(updatedImage)
    } else {
      croppedImage = updatedImage
    }
    setTimeout(async () => {
      const form = new FormData();
      form.append('vehicle_ad_overview', addDetails ? addDetails.vehicle_ad_overview_data.id : '')
      form.append('vehicle_feature_category', category)
      form.append('vehicle_category_image', croppedImage)

      const formDataObject = {};
      form.forEach((value, key) => {
        formDataObject[key] = value;
      });

      console.log('formDataObject', formDataObject);
      axios.defaults.headers.common["Accept"] = "application/json";
      axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
      await axios.put(url, form, { headers: { 'Authorization': token } })
        .then((response) => {
          setLoading(false)
          console.log('response', response);
          if (response?.data.status === 1) {
            console.log('response', response);
            callAddDetailApi(addDetails.vehicle_ad_overview_data.id, '')
          } else {
            console.log('response', response);
            setShowAlert(true)
            setError(response.data.message)
          }
        })
        .catch(function (error) {
          console.log('error', error);
          setLoading(false)
          if (error.response) {
            setShowAlert(true)
            setError(error.response.data.message)
          }
        });
    }, 200);

  }


  const callAdGalleryCreateApi = async (category, updatedimages) => {
    setLoading(true)
    const url = `${URLS.BASE_URL}${URLS.VEHICLE_ADD_GALLERY_CREATE}`;
    console.log('updatedimage', updatedimages)
    const form = new FormData();
    form.append('vehicle_ad_overview', addDetails ? addDetails.vehicle_ad_overview_data.id : '')
    form.append('vehicle_feature_category', category)
    Array.from(updatedimages).forEach((file, index) => {
      form.append(`vehicle_category_image`, file);
    });
    // form.append('vehicle_category_image', updatedimages)
    const formDataObject = {};
    form.forEach((value, key) => {
      formDataObject[key] = value;
    });

    console.log('formDataObject', formDataObject);
    axios.defaults.headers.common["Accept"] = "application/json";
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
    await axios.post(url, form, { headers: { 'Authorization': token } })
      .then((response) => {
        setLoading(false)
        console.log('response', response);
        if (response?.data.status === 1) {
          // callAddDetailApi(addDetails.vehicle_ad_overview_data.id)
          console.log('response', response);
          handleNextClick('gallery')
        } else {
          console.log('response', response);
          setShowAlert(true)
          setError(response.data.message)
          return false;
        }
      })
      .catch(function (error) {
        console.log('error', error);
        setLoading(false)
        if (error.response) {
          setShowAlert(true)
          setError(error.response.data.message)
        }
        return false;
      });
  }

  const callAddDetailApi = (addId, btnAction) => {
    setLoading(true)
    const url = `${URLS.BASE_URL}${URLS.FULL_ADD_DETAILS}${addId}`;
    setTimeout(async () => {
      await axios.get(url, { headers: { 'Authorization': token } })
        .then((response) => {
          setLoading(false)
          if (response?.data.status === 1) {
            setAddDetails(response.data)
            const imagesData = response.data.vehicle_ad_gallery_data.vehicle_feature_categories
            const descriptionData = response.data.vehicle_ad_gallery_category_descriptions_data.vehicle_feature_category_descriptions
            const updatedSections = sections.map(section => {
              // Find matching image data
              let matchingImageData = {}
              if (imagesData) {
                matchingImageData = imagesData.find(data => data.vehicle_feature_category === section.title);
              }

              const updatedSection = { ...section };

              if (!isEmptyObject(matchingImageData)) {
                const imagobjArray = matchingImageData.vehicle_category_images.map(imageObj => ({
                  original_image: imageObj.vehicle_category_image,
                  updated_image: imageObj.vehicle_category_image,
                  id: imageObj.id,
                  isLoaded: false
                }));
                updatedSection.vehicle_category_images = imagobjArray;
              } else {
                updatedSection.vehicle_category_images = [];
              }
              // Find matching description data
              if (descriptionData) {
                const matchingDescData = descriptionData.find(data => data.vehicle_feature_category === section.title);
                if (matchingDescData) {
                  updatedSection.header = matchingDescData.category_heading;
                  updatedSection.description = matchingDescData.category_description;
                }
              }


              return updatedSection;
            });
            setSections(updatedSections);
            if (btnAction == 'next') {
              onNextPress()
            } else if (btnAction == 'preview') {
              openNewTab()
            } else if (btnAction == 'back') {
              onBackPress()
            } else if (btnAction == 'tabchange') {
              onClick()
            }
          } else {
            console.log('response', response);
            setShowAlert(true)
            setError(response.data.message)
          }
        })
        .catch(function (error) {
          console.log('error', error);
          setLoading(false)
          if (error.response) {
            setShowAlert(true)
            setError(error.response.data.message)
          }
        });
    }, 2000);

  }


  const callAddGalleryDeleteApi = async (galleryobj) => {
    setLoading(true)
    const url = `${URLS.BASE_URL}${URLS.VEHICLE_ADD_GALLERY_DELETE}${galleryobj.id}/`;

    await axios.delete(url, { headers: { 'Authorization': token } })
      .then((response) => {
        setLoading(false)
        if (response?.data.status === 1) {
          console.log('responsedelete', response);
          callAddDetailApi(addDetails.vehicle_ad_overview_data.id, '')
        } else {
          console.log('response', response);
          setShowAlert(true)
          setError(response.data.message)
        }
      })
      .catch(function (error) {
        setLoading(false)
        console.log('error', error);
        if (error.response) {
          setShowAlert(true)
          setError(error.response.data.message)
        }
      });
  }


  return (
    <>

      <Row >
        {loading && (
          <div className="loader">
            <ClipLoader
              color={"#123abc"}
              loading={loading}
              css={override}
              size={50}
            />
          </div>
        )}
        <Col md={9}>
          <h6 className={'headertext2'}>{Strings.gallery}</h6>
          <div>
            {sections.map((section, sectionIndex) => {
              const currentIndex = sectionIndex;
              return (
                <Card key={sectionIndex}>
                  <Card.Header>
                    <button className='section_btn' onClick={() => toggleSection(sectionIndex)}>
                      <span style={{ marginRight: 20 }}>{section.title}</span>
                      {section.isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
                    </button>
                  </Card.Header>
                  <Collapse in={section.isOpen}>
                    <div>
                      <Card.Body className='card-body-add'>
                        <Row>
                          <Col md={9} xxl={6} xl={7} lg={8} sm={8}>
                            <TopLabelInput type={'text'} label="Überschrift" value={section.header} onChange={e => handleInputChange(e.target.value, currentIndex)} />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={9} xxl={6} xl={7} lg={8} sm={8}>
                            <ReactQuill
                              theme="snow"
                              modules={modules}
                              className='quill_gallery'
                              formats={formats}
                              value={section.description}
                              placeholder="Beschreibung ...."
                              onChange={(content) => { handleDescriptionChange(content, currentIndex) }}
                            />
                          </Col>
                        </Row>
                        {section.vehicle_category_images.length > 0 ?
                          <Row>
                            <Col md={12}>
                              <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                {section.vehicle_category_images.map((imageData, imgIndex) => (
                                  <div onMouseEnter={() => handleMouseEnter(sectionIndex, imgIndex)}
                                    onMouseLeave={handleMouseLeave} style={{ marginBottom: 10, flex: '0 0 auto' }} key={imgIndex} sm={2}>
                                    <OverlayTrigger
                                      show={showDeletePopover.sectionIndex !== null ? showDeletePopover.sectionIndex === sectionIndex && showDeletePopover.imageIndex === imgIndex : popoverOpen.sectionIndex === sectionIndex && popoverOpen.imageIndex == imgIndex}
                                      trigger={['hover', 'focus']}
                                      placement="top"
                                      overlay=
                                      {
                                        showDeletePopover.sectionIndex !== null ? (
                                          <Popover id={`deletepopover-${sectionIndex}-${imgIndex}`} placement="top">
                                            <div className='popover-content'>
                                              <span style={{ color: 'white', fontSize: 15, paddingRight: 10, paddingLeft: 10 }}>Loschen?</span>
                                              <Button variant="danger" className="m-1" onClick={() => handleConfirmDelete(sectionIndex, imgIndex)}>
                                                <TiTick size={15} color='white' />
                                              </Button>
                                              <Button className="m-1" onClick={() => handleCloseDeletePopover(sectionIndex, imgIndex)}>
                                                <MdOutlineClose size={15} color='whitw' />
                                              </Button>
                                            </div>
                                          </Popover>

                                        ) : (
                                          <Popover id={`popover-${sectionIndex}-${imgIndex}`} placement="top">
                                            <div className='popover-content'>
                                              <Button className="m-1" onClick={() => handleEditClick(sectionIndex, imgIndex)}>
                                                <MdEdit size={15} color='white' />
                                              </Button>
                                              {/* <Button className="m-1" onClick={() => handleEditText(sectionIndex, imgIndex)}>
                                                <RiMenu2Line size={15} color='white' />
                                              </Button> */}
                                              <Button className="m-1" onClick={() => handleDeleteClick(sectionIndex, imgIndex)}>
                                                <MdDelete size={15} color='white' />
                                              </Button>
                                            </div>
                                          </Popover>)
                                      }
                                    >
                                      <div className='add-dashed-view'>
                                        <img style={{ objectFit: 'contain' }} onLoad={(e) => handleImageLoaded(e.target.src, sectionIndex, imgIndex)} src={imageData.updated_image instanceof File || imageData.updated_image instanceof Blob ? URL.createObjectURL(imageData.updated_image) : imageData.updated_image} className='add-upload_image' alt={`Image ${imgIndex + 1}`} />
                                      </div>
                                    </OverlayTrigger>
                                  </div>
                                ))}
                                <div style={{ flex: '0 0 auto' }} className='add-dashed-view' onClick={() => inputRefs.current[sectionIndex].current.click()}>
                                  <input multiple={true} type="file" accept="image/*" style={{ display: 'none' }} onChange={(e) => {
                                    handleImageUpload(e, currentIndex)
                                  }} ref={inputRefs.current[sectionIndex]} />
                                  <img className='placeholder_img' src={require('../../assets/placeholder.png')} />
                                </div>
                              </div>

                            </Col>
                          </Row>
                          : <div className='add-dashed-view' onClick={() => inputRefs.current[sectionIndex].current.click()}>
                            <input multiple={true} type="file" accept="image/*" style={{ display: 'contents' }} onChange={(e) => {
                              handleImageUpload(e, currentIndex)
                            }} ref={inputRefs.current[sectionIndex]} />
                            <img className='placeholder_img' src={require('../../assets/placeholder.png')} />
                          </div>}
                      </Card.Body>

                    </div>
                  </Collapse>
                </Card>
              )
            }
            )}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20, marginBottom: 20 }}>
            <button className="add_back_btn" onClick={handleBackClick}>{Strings.back}</button>
            <button className="next-btn" onClick={() => { handleNextClick('next') }}>{Strings.next}</button>
            <button className="add_preview_btn" onClick={() => { handleNextClick('preview') }} >{Strings.preview}<IoIosDesktop style={{ marginLeft: 5 }} /></button>
          </div>

        </Col>
      </Row>
      <Modal show={showModal} onHide={handleCloseModal} style={{ backgroundColor: 'rgba(255,255,255,0.3)' }}>
        <Modal.Body style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {/* <ReactCrop
            crop={crop}
            onChange={handleCropChange}
            onImageLoaded={handleImageLoaded}
          >
            <img
              src={imageUrl}
              alt=""
              style={{ maxHeight: '30vh', width: '60vh', objectFit: 'contain' }}
              onLoad={(e) => setImageRef(e.target)}
            />
          </ReactCrop> */}
          <div style={{ height: "30vh", width: "60vh" }}>
            <Cropper
              ref={cropperRef}
              src={imageUrl}
              style={{ height: "100%", width: '100%' }}
              guides={true}
              scale={1}
              viewMode={1}
              cropend={onCrop}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-center'>
          <Button className="next-btn" onClick={saveImgCrop}>Übernehmen!</Button>
        </Modal.Footer>
      </Modal>
      {showalert &&
        <AlertComponent message={error} onClose={() => { setShowAlert(false) }}></AlertComponent>
      }
      {showImageTextEditor && <ImageTextEditor title={sections[sectionindex].title} imageData={{ 'header': sections[sectionindex].header, 'description': sections[sectionindex].description }} images={sections[sectionindex].vehicle_category_images} showHeader={true} showModal={showImageTextEditor} closeModal={() => setShowImageTextEditor(false)} />}
    </>
  )
}

export default AdGallery