import { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

const style = {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    /* Semi-transparent background */
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1050
}
function AlertComponent({ message, onClose }) {
    return (
        <div style={style}>
            <Alert show={true} variant="danger" onClose={onClose} dismissible>
                <Alert.Heading>Oh, Mist! Da haben wir einen Fehler gemacht:</Alert.Heading>
                <p>
                    {message}
                </p>
            </Alert>
        </div>
    );
}

export default AlertComponent;