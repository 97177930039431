import React, { useState, useEffect } from 'react';
import './DropDown.css'; // Import your CSS file for styling
import { IoIosArrowDown } from "react-icons/io";
import { AiOutlineCheck } from "react-icons/ai";
import { Row } from 'react-bootstrap';

const DropDown = ({ label, value, onChange, type, short, dropDownData, onSelect, singleselection, onToggle, isOpen }) => {

    const [singleSelectedItem, setSingleSelectedItem] = useState(null);
    const [multiSelectedItems, setMultiSelectedItems] = useState([]);

    useEffect(() => {
        setSingleSelectedItem(value)
    }, [value]);


    const handleSingleSelectionItemClick = (item) => {
        console.log('item', item)
        setSingleSelectedItem(item);
        onSelect(item);
        // toggleDropdown();
        onToggle();
    };

    const handleMultiSelectionItemClick = (item) => {
        const index = multiSelectedItems.indexOf(item);
        let updatedSelectedItems = [...multiSelectedItems];
        if (index === -1) {
            if (updatedSelectedItems.includes('Alle')) {
                const newArray = updatedSelectedItems.filter(item => item !== 'Alle');
                updatedSelectedItems = newArray
            }
            if (item !== 'Alle') {
                updatedSelectedItems.push(item);
            } else {
                updatedSelectedItems = ['Alle']
            }
        } else {
            updatedSelectedItems.splice(index, 1);
        }
        setMultiSelectedItems(updatedSelectedItems);
        console.log('updatedSelectedItems', updatedSelectedItems)
        onSelect(updatedSelectedItems)
    };


    return (
        <>
            <div className="dropdown_overview_wotlbl" onClick={onToggle} style={{ cursor: 'pointer' }}>
                <div className='div_dropdown_wotlbl' >
                    <input disabled={label === 'Typ' || label == 'Chasis' || label == 'Conversion'} className='input_dropdown_wotlbl' style={{ width: '80%' }} type={type} value={value} onInput={onChange}></input>
                    <div
                        className="dropdown-icon_wotlbl"
                        style={{ position: 'absolute', top: '50%', right: '8px', transform: 'translateY(-50%)', }}
                    // onClick={onToggle}
                    >
                        <IoIosArrowDown color='white' />
                    </div>

                </div>
                {isOpen && (
                    <div className="dropdown-menu-details_wotlbl">
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={() => { handleMultiSelectionItemClick('Alle') }}>
                            <AiOutlineCheck />
                            <span className='headertext' style={{ paddingTop: 5, paddingBottom: 4 }}>{'Alle'}</span>
                        </div>
                        {dropDownData.map((item, index) => (
                            <div style={{ backgroundColor: !singleselection ? multiSelectedItems.includes(item) && 'orange' : singleSelectedItem === item && 'orange', borderRadius: 5, color: !singleselection ? multiSelectedItems.includes(item) && 'white' : singleSelectedItem === item && 'white' }} key={index} className="dropdown-item_wotlbl"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    singleselection ? handleSingleSelectionItemClick(item) : handleMultiSelectionItemClick(item)
                                }}>
                                <span>{item.label ? item.label : item}</span>

                                {/* {!singleselection ? multiSelectedItems.includes(item.label) && <IoIosCheckmarkCircle color='green' /> : singleSelectedItem === item && <IoIosCheckmarkCircle color='green' />} */}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </>
    );
};

export default DropDown;