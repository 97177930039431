import React from 'react';
import '../../app.css'
import ChildHeader from '../ChildHeader/ChildHeader'

const DashboardScreen = () => {
  return (
    <>
      <ChildHeader />
      <div className="full-width-container overlayStyle" style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + "/magazine_bg.png"})`, backgroundPosition: 'cover', backgroundSize: 'cover', height: "100vh",
      }}>
        <div className='mx-5' style={{ alignItems: 'center', justifyContent: 'center' }}>
          <h4 className='mediumtextstyle'>Welcome to dashboard!</h4>
        </div>
      </div>
    </>
  );
};

export default DashboardScreen;