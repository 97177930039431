// Header.js
import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../../app.css'

const ChildHeader = () => {


    const navItems = [
        { label: 'Home', to: '/home' },
        { label: 'Ausbauer', to: '/businesses' },
    ];


    return (
        <div>
            <Navbar style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)', paddingTop: 20, paddingBottom: 20, backdropFilter: 'blur(9px)' }} expand="lg" fixed="top">
                <Navbar.Brand as={Link} to="/" style={{ marginLeft: 20 }}>
                    <img
                        src={require("../../../src/assets/logo.png")}
                        width="80"
                        height="50"
                        alt='logo'
                        className="d-inline-block align-top"
                    />
                </Navbar.Brand>
                <Navbar.Toggle
                    aria-controls="basic-navbar-nav"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        fill="white"
                        className="bi bi-list"
                        viewBox="0 0 16 16"
                    >
                        <path
                            fillRule="evenodd"
                            d="M1 2.5A.5.5 0 0 1 1.5 2h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-1 0zM1.5 6A.5.5 0 0 1 1 5.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5zM1 9.5A.5.5 0 0 1 1.5 9h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5zM1.5 13A.5.5 0 0 1 1 12.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5z"
                        />
                    </svg>
                </Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav" style={{ justifyContent: 'space-between', paddingRight: 10 }}>
                    <Nav className="mr-auto">
                        {navItems.map((item) => (
                            <Nav.Link as={Link} to={item.to} className="text-white mx-2" >{item.label}</Nav.Link>
                        ))}
                    </Nav>

                </Navbar.Collapse>
            </Navbar>
        </div >
    );
};

export default ChildHeader;
