import React, { useState, useEffect } from 'react';
import '../ChangePassword/ChangePassword.css'
import { useNavigate, useLocation } from 'react-router-dom';
import { getCookieValue, Strings, URLS } from '../../utilities/constants';
import axios from 'axios'
import { css } from '@emotion/react';
import { ClipLoader } from 'react-spinners';
import '../../app.css'
import { Row, Col, Form } from 'react-bootstrap';
import { FiAlertTriangle } from "react-icons/fi";
import AlertComponent from "../../Components/AlertComponent";
import { IoEye } from "react-icons/io5";
import { IoEyeOff } from "react-icons/io5";
import { trackPageView } from '../../hooks/analytics';
import { getCookieConsentValue } from 'react-cookie-consent';

const ChangePassword = ({ onClose, onForgotBtnClick }) => {

    const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [cnfPassword, setCnfPassword] = useState('');
    const [error, setError] = useState('');
    const [error1, setError1] = useState('');
    const [error2, setErro2] = useState('');
    const [err, setErr] = useState('')
    const [showalert, setShowAlert] = useState(false)
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);

    useEffect(() => {
        document.title = 'Passwort ändern'
        const consentCookie = getCookieValue('cookie_consent_level');
        if (consentCookie) {
            const consentStatus = JSON.parse(decodeURIComponent(consentCookie));
            console.log('consentStatus', consentStatus.tracking);
            if (consentStatus.tracking) {
                trackPageView(window.location.pathname, 'Passwort ändern')
            }
        }

    }, [])



    const togglePasswordVisibility = (pswtype) => {
        if (pswtype == 'password1') {
            setIsPasswordVisible(!isPasswordVisible);
        } else {
            setIsConfirmPasswordVisible(!isConfirmPasswordVisible)
        }
    };



    const validateForm = () => {
        if (!password.trim()) {
            setError1(Strings.password_err);
            setErro2('');
            return false;
        }

        if (!cnfPassword.trim()) {
            setErro2(Strings.confirm_password_err);
            setError1('');
            return false;
        }

        if (password.trim() !== cnfPassword.trim()) {
            setErro2(Strings.confirm_password_error2);
            setError1('');
            return false;
        }
        setError1('');
        setErro2('');

        return true;
    };

    const handleBtClick = () => {
        if (validateForm()) {
            callChangePasswordapi()
        }
    };

    const handleCancel = () => {
        navigate('/home')
    }

    const callChangePasswordapi = async () => {
        setLoading(true)
        const url = `${URLS.BASE_URL}${URLS.CHANGE_PASSWORD}`;
        console.log('url', url)
        const form = new FormData();
        form.append("password", password);
        form.append("password2", cnfPassword);

        console.log('form', form)
        axios.defaults.headers.common["Accept"] = "application/json";
        axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
        await axios.post(url, form, { headers: { 'Authorization': token } })
            .then((response) => {
                setLoading(false)
                console.log('response', response)
                if (response?.data.status === 1) {
                    navigate('/successpage')
                } else {
                    console.log('response', response);
                    setShowAlert(true)
                    setError(response.data.message)
                }
            })
            .catch(function (error) {
                setLoading(false)
                if (error.response) {
                    setShowAlert(true)
                    setError(error.response.data.message)
                }
                console.log('error', error);
            });
    }

    return (
        <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', height: '100vh' }}>

            {loading && (
                <div className="loader">
                    <ClipLoader color={'#123abc'} loading={loading} css={override} size={50} />
                </div>
            )}
            <div style={{ width: '50vh', alignItems: 'center', justifyContent: 'center', display: 'flex', marginBottom: 20, flexDirection: 'column' }}>
                <img
                    src={require("../../assets/logo.webp")}
                    width="150vh"
                    height="120vw"
                    alt='logo'
                    style={{ objectFit: 'contain' }}
                    className="d-inline-block align-top"
                />
                <span style={{ marginBottom: 20, marginTop: 20 }} className='text'>{Strings.change_password_text}</span>
                <div className='logininput changepwinput'>
                    <input
                        type={isPasswordVisible ? "text" : 'password'}
                        placeholder={Strings.password}
                        className='inputcpw'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <span
                        onClick={() => togglePasswordVisibility('password1')}
                    >
                        {isPasswordVisible ? <IoEyeOff color='black' size={20} /> : <IoEye color='black' size={20} />}
                    </span>
                </div>

                <div style={{ width: '100%', marginBottom: 10 }}>
                    {error1 && (
                        <Row >
                            <Col md={1} style={{ marginTop: -4 }}><FiAlertTriangle color='red' /></Col>
                            <Col md={11} style={{ marginLeft: -15 }}><div className="error-message">{error1}</div></Col>
                        </Row>
                    )}
                </div>
                <div className='logininput changepwinput'>
                    <input
                        type={isConfirmPasswordVisible ? "text" : 'password'}
                        placeholder={Strings.confirm_password}
                        className='inputcpw'
                        value={cnfPassword}
                        onChange={(e) => setCnfPassword(e.target.value)}
                    />
                    <span
                        onClick={() => togglePasswordVisibility('password2')}
                    >
                        {isConfirmPasswordVisible ? <IoEyeOff color='black' size={20} /> : <IoEye color='black' size={20} />}
                    </span>
                </div>
                <div style={{ width: '100%', marginBottom: 10 }}>
                    {error2 && (
                        <Row >
                            <Col md={1} style={{ marginTop: -4 }}><FiAlertTriangle color='red' /></Col>
                            <Col md={11} style={{ marginLeft: -15 }}><div className="error-message">{error2}</div></Col>
                        </Row>
                    )}
                </div>
            </div>

            {error && <p style={{ color: 'red', marginTop: -25, marginBottom: 20 }}>{error}</p>}

            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                }}
            >
                <button className="next-btn" onClick={handleBtClick}>{Strings.changePassword}</button>
                <button
                    className="cancel-btn"
                    onClick={handleCancel}
                >
                    {Strings.cancel}
                </button>
                {/* <button className="preview-btn" onClick={()=>{window.open('http://localhost:3000/businesspage','_blank')}} >Preview</button> */}
            </div>
            {showalert &&
                <AlertComponent message={err} onClose={() => { setShowAlert(false) }}></AlertComponent>
            }
        </div>
    );
};

export default ChangePassword;