import React, { useState, useRef, useEffect, useContext } from "react";

import { Row, Col } from "react-bootstrap";
import Header from "../../Components/Header/Header";
import "../../app.css";
import "../PrivateUserRegistration/PrivateUserRegistration.css"
import TopLabelInput from "../../Components/TpLabelInput/TopLabelInput";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../utilities/DataContext";
import { FiAlertTriangle } from "react-icons/fi";
import { getCookieValue, Strings, URLS } from "../../utilities/constants";
import axios from "axios";
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";
import "quill/dist/quill.snow.css";
import { trackPageView } from "../../hooks/analytics";

const PrivateUserRegistration = () => {

    const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

    const navigate = useNavigate();
    const fileInputRef = useRef(null);
    const [error, setError] = useState('')
    const [selectedFile, setSelectedFile] = useState(null);
    const [logoRef, setLogoRef] = useState(null);
    const [showalert, setShowAlert] = useState(false)
    const [loading, setLoading] = useState(false);
    const [countryCode, setCountryCode] = useState("DE");
    const { setPrivateUserData, setToken, setIsLoggedIn, userType, setUserData, setUserType, setCompanyInfo, setTab, setNoOfBedErr, setMessageCount, setChatData, setBusinessForm1data, setBusinessForm2data, setAddDetails } = useContext(DataContext)
    const [form1Data, setForm1Data] = useState({
        firstName: "",
        lastName: "",
        street: "",
        streetNumber: "",
        zipCode: "",
        city: "",
        country: "",
        telephone: "",
        email: "",
        password: "",
        confirmPassword: ""
    });

    const [form1Errors, setForm1Errors] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        apierror: '',
        logo: ''
    });

    useEffect(() => {
        document.title = 'Private Registrierung auf campervanspot.com'
        const consentCookie = getCookieValue('cookie_consent_level');
        if (consentCookie) {
            const consentStatus = JSON.parse(decodeURIComponent(consentCookie));
            console.log('consentStatus', consentStatus.tracking);
            if (consentStatus.tracking) {
                trackPageView(window.location.pathname, 'Private Registrierung auf campervanspot.com')
            }
        }
    }, []);



    useEffect(() => {
        setUserType('private-user')
    }, []);


    const handleInputChange = (label, value) => {
        console.log("label", label);
        if (label === "telephone") {
            console.log("value", value);
            setCountryCode(value.short);
            if (value.phone === '') {
                setForm1Data((prevFormData) => {
                    const updatedFormData = {
                        ...prevFormData,
                        [label]: "",
                    };
                    console.log("Updated form data1:", updatedFormData);
                    return updatedFormData;
                });
            } else {
                setForm1Data((prevFormData) => {
                    const updatedFormData = {
                        ...prevFormData,
                        [label]: `+${value.code} ${value.phone}`,
                    };
                    console.log("Updated form data2:", updatedFormData);
                    return updatedFormData;
                });
            }

        } else {
            setForm1Data((prevFormData) => {
                const updatedFormData = {
                    ...prevFormData,
                    [label]: value,
                };
                console.log("Updated form data:", updatedFormData);
                return updatedFormData;
            });
        }
    };


    const handleFileChange = (event) => {
        console.log("e.target.files[0]", event.target.files[0]);
        const file = event.target.files[0];
        handleFile(file);
    };


    const handleFile = (file) => {
        let errors = {};
        if (file) {
            if (file.size >= 4.5 * 1024 * 1024) { // Check if file size is greater than 5MB
                errors.logo = Strings.image_size_error
            } else {
                if (file.name.length > 100) {
                    errors.logo = Strings.image_filename_error
                } else {
                    setSelectedFile(file);
                }
            }
            setForm1Errors(errors)
        }
    };


    const handleLogoImageLoaded = async (e) => {
        console.log("logoRef", logoRef);
        if (logoRef === null) {
            let img = e.target.src;
            console.log("img", img);
            try {
                const response = await fetch(img);
                const blob = await response.blob();
                const imgUrl = URL.createObjectURL(blob);
                img = imgUrl;
                console.log("logourl", imgUrl);
                console.log("logoblob", blob);
                setSelectedFile(blob);
                setLogoRef(e.target)
            } catch (error) {
                console.error("error", error);
            } finally {
                console.log("Fetch operation complete");
            }
        }
    };

    const validateForm1Data = () => {
        const errors = {};
        if (form1Data.firstName === undefined || form1Data.firstName === "") {
            errors.firstName = "Bitte füllen Sie dieses Pflichtfeld aus.";
        }
        if (form1Data.lastName === undefined || form1Data.lastName === "") {
            errors.lastName = "Bitte füllen Sie dieses Pflichtfeld aus.";
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (form1Data.email === undefined || form1Data.email === "") {
            errors.email = 'Bitte füllen Sie dieses Pflichtfeld aus.'
        } else {
            if (!emailRegex.test(form1Data.email)) {
                errors.email = Strings.invalid_email
            }
        }
        if (form1Data.password === undefined || form1Data.password === "") {
            errors.password = "Bitte füllen Sie dieses Pflichtfeld aus.";
        }
        if (form1Data.confirmPassword === undefined || form1Data.confirmPassword === "" || form1Data.password !== form1Data.confirmPassword) {
            errors.confirmPassword = Strings.confirm_password_error2;
        }
        return errors;
    };

    const handleNextClick = () => {
        const errors = validateForm1Data();
        setForm1Errors(errors);
        if (Object.keys(errors).length === 0) {
            callRegistrationApi()
        }
    };

    async function convertBlobUrlToFile(blobUrl) {
        try {
            // Fetch the blob data from the URL
            const response = await fetch(blobUrl);
            const blobData = await response.blob();
            const fileExtension = getFileExtensionFromMimeType(blobData.type);
            // Generate a unique file name with the determined extension
            const randomNumbers = Math.floor(Math.random() * 1000);
            const fileName = `file_from_blob_${randomNumbers}.${fileExtension}`;
            const file = new File([blobData], fileName, { type: blobData.type });
            return file;
        } catch (error) {
            console.error("Error converting Blob URL to File:", error);
            return null;
        }
    }

    function getFileExtensionFromMimeType(mimeType) {
        const mimeTypeMap = {
            "image/jpeg": "jpg",
            "image/png": "png",
            "image/gif": "gif",
            "image/webp": "webp",
            // Add more MIME types and their corresponding extensions as needed
        };

        // Get the corresponding file extension for the given MIME type
        return mimeTypeMap[mimeType] || "";
    }

    function isBlobURL(url) {
        const blobUrlRegex = /^blob:/i;
        return blobUrlRegex.test(url);
    }

    function createFileFromBlob(blob) {
        console.log('blob', blob)
        const fileName = generateRandomFileName();
        return new File([blob], fileName, { type: blob.type });
    }

    function generateRandomFileName() {
        const randomNumber = Math.floor(10 + Math.random() * 90); // Generates a random number between 10 and 99
        return `image_${randomNumber}.png`;
    }

    const callRegistrationApi = async () => {
        setLoading(true)
        const url = `${URLS.BASE_URL1}${URLS.REGISTRATION_URL}`;
        console.log('url', url)
        let logofile = null
        const form = new FormData();
        if (isBlobURL(selectedFile)) {
            convertBlobUrlToFile(selectedFile).then((file) => {
                if (file) {
                    logofile = file;
                    console.log("logofile", logofile);
                } else {
                    console.log("Failed to convert Blob URL to File.");
                }
            });
        } else if (selectedFile instanceof File) {
            let url = URL.createObjectURL(selectedFile);
            console.log("CONVERTTED URL LOGO", url);
            logofile = selectedFile;
        } else if (selectedFile instanceof Blob) {
            let blobfile = createFileFromBlob(selectedFile)
            let url = URL.createObjectURL(blobfile);
            console.log("CONVERTTED URL LOGO", url);
            logofile = blobfile;
        }
        form.append("profile_image", logofile);
        form.append('user_type', userType)
        form.append('first_name', form1Data.firstName)
        form.append('last_name', form1Data.lastName)
        form.append('email', form1Data.email)
        form.append('password', form1Data.password)
        form.append('password2', form1Data.confirmPassword)
        form.append('street', form1Data.street)
        form.append('street-number', form1Data.streetNumber)
        form.append("street_number", form1Data.streetNumber ? form1Data.streetNumber : "");
        form.append("zip_code", form1Data.zipCode ? form1Data.zipCode : "");
        form.append("city", form1Data.city ? form1Data.city : "");
        form.append("country", form1Data.country ? form1Data.country : "");
        form.append("country_code", countryCode ? countryCode : "");
        form.append("user_phonenumber", form1Data.telephone ? form1Data.telephone : "");

        const formDataObject = {};
        form.forEach((value, key) => {
            formDataObject[key] = value;
        });

        console.log("formDataObject", formDataObject);

        axios.defaults.headers.common["Accept"] = "application/json";
        axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

        await axios.post(url, form)
            .then((response) => {
                setLoading(false)
                if (response?.data.status === 1) {
                    // alert(response.data.message);
                    console.log("response", response?.data);
                    const data = response?.data.user
                    setIsLoggedIn(true)
                    setPrivateUserData(data);
                    setToken(response?.data?.token)
                    setForm1Data({
                        firstName: '',
                        lastName: '',
                        email: '',
                        street: '',
                        streetNumber: '',
                        zipCode: '',
                        city: '',
                        country: '',
                        password: '',
                        telephone: ''
                    });
                    setCountryCode('')
                    navigate('/registration_success')
                    setLoading(false)
                } else {
                    setUserData({})
                    setCompanyInfo({})
                    setUserType('')
                    setTab('')
                    setNoOfBedErr('')
                    setMessageCount('')
                    setToken('')
                    setChatData([])
                    setBusinessForm1data({})
                    setBusinessForm2data({})
                    setIsLoggedIn(false)
                    setAddDetails({})
                }
            })
            .catch(function (error) {
                console.log('error', error);
                setLoading(false)
                const errors = {};
                errors.apierror = error.response.data.message;
                setForm1Errors(errors)
                setUserData({})
                setCompanyInfo({})
                setUserType('')
                setTab('')
                setNoOfBedErr('')
                setMessageCount('')
                setToken('')
                setChatData([])
                setBusinessForm1data({})
                setBusinessForm2data({})
                setIsLoggedIn(false)
                // alert(error.response.data.message);
            });

    }


    return (
        <>
            <Header />
            {loading && (
                <div className="loader">
                    <ClipLoader color={'#123abc'} loading={loading} css={override} size={50} />
                </div>
            )}
            <div style={{ position: 'absolute', marginTop: 75, height: '90%', right: 0, left: 0, display: 'flex', justifyContent: 'center' }}>
                <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Col xxl={8} xl={8} lg={10} md={11} xs={10} style={{ paddingTop: '8vmin', paddingLeft: '16vmin' }}>
                        <div>
                            <h1 className="headertext1">
                                Willkommen bei Campervanspot
                            </h1>
                            <h1 className="reg_subheadertext">
                                Bitte gib deine persönlichen Daten für dein Profil an
                            </h1>
                        </div>
                        <Row>
                            <Col xxl={6} xl={6} lg={6} md={6} xs={12}>
                                <h1 className="headertext2 mt5 mb4">
                                    Profil vervollständigen
                                </h1>
                                <div
                                    className="dashed-view"
                                    style={{ marginBottom: 20, marginTop: 20 }}
                                //   onDrop={handleDrop}
                                //   onDragOver={handleDragOver}
                                >
                                    <div htmlFor="fileInput" className="upload-label">
                                        {(selectedFile !== null) && (
                                            <img
                                                src={(selectedFile instanceof File ||
                                                    selectedFile instanceof Blob) ? URL.createObjectURL(selectedFile) : selectedFile}
                                                alt="Upload"
                                                onLoad={handleLogoImageLoaded}
                                                className="logo-image"
                                            />
                                        )}
                                        <div>
                                            {!selectedFile ? (
                                                <div className="upload-placeholder">
                                                    <img
                                                        alt="selectedFile"
                                                        style={{ width: 15, height: 15 }}
                                                        src={require("../../assets/placeholder.png")}
                                                    ></img>
                                                </div>
                                            ) : null}

                                            <input
                                                style={{
                                                    backgroundColor: "red",
                                                    position: "absolute",
                                                    top: 0,
                                                    left: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                    cursor: "pointer",
                                                }}
                                                ref={fileInputRef}
                                                type="file"
                                                accept="uploadedimage/*"
                                                onChange={handleFileChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {form1Errors.logo && (
                                    <div style={{ display: "flex", marginTop: 5, marginBottom: 10 }}>
                                        <FiAlertTriangle color="red" />
                                        <div className="error-message">
                                            {form1Errors.logo}
                                        </div>
                                    </div>
                                )}
                                <Row >
                                    <Col xxl={5} xl={5} lg={5} md={5} xs={6} className="reg-col-input">
                                        <TopLabelInput
                                            type={"text"}
                                            label="Vorname*"
                                            value={form1Data.firstName}
                                            onChange={(e) =>
                                                handleInputChange("firstName", e.target.value)
                                            }
                                        />

                                    </Col>
                                    <Col xxl={5} xl={5} lg={5} md={5} xs={6} className="reg-col-input">
                                        <TopLabelInput
                                            type={"text"}
                                            label="Nachname*"
                                            value={form1Data.lastName}
                                            onChange={(e) =>
                                                handleInputChange("lastName", e.target.value)
                                            }
                                        />
                                    </Col>
                                    {(form1Errors.firstName || form1Errors.lastName) && (
                                        <div
                                            style={{
                                                display: "flex",
                                                marginBottom: 10,
                                                marginTop: -10,
                                            }}
                                        >
                                            <FiAlertTriangle color="red" />
                                            <span className="error-message">{form1Errors.firstName !== undefined ? form1Errors.firstName : form1Errors.lastName}</span>
                                        </div>
                                    )}
                                </Row>

                            </Col>
                        </Row>
                        <Row>
                            <Col xxl={6} xl={6} lg={6} md={6} xs={12}>
                                <h1 className="reg_subheadertext mt2 mb2">
                                    Adresse
                                </h1>
                                <Row >
                                    <Col xxl={6} xl={7} lg={6} md={6} xs={8} className="reg-col-input" >
                                        <TopLabelInput
                                            type={"text"}
                                            label="Straße"
                                            value={form1Data.street}
                                            onChange={(e) =>
                                                handleInputChange("street", e.target.value)
                                            }
                                        />
                                    </Col>
                                    <Col xxl={4} xl={3} lg={4} md={4} xs={4} className="reg-col-input">
                                        <TopLabelInput
                                            type={"text"}
                                            label="Nr."
                                            value={form1Data.streetNumber}
                                            onChange={(e) =>
                                                handleInputChange("streetNumber", e.target.value)
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row >
                                    <Col xxl={5} xl={5} lg={5} md={5} xs={6} className="reg-col-input" >
                                        <TopLabelInput
                                            type={"text"}
                                            label="Postleitzahl"
                                            value={form1Data.zipCode}
                                            onChange={(e) =>
                                                handleInputChange("zipCode", e.target.value)
                                            }
                                        />
                                    </Col>
                                    <Col xxl={5} xl={5} lg={5} md={5} xs={6} className="reg-col-input">
                                        <TopLabelInput
                                            type={"text"}
                                            label="Stadt"
                                            value={form1Data.city}
                                            onChange={(e) =>
                                                handleInputChange("city", e.target.value)
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row >
                                    <Col xxl={10} xl={10} lg={10} md={10} xs={12} className="reg-col-input" >
                                        <TopLabelInput
                                            type={"text"}
                                            label="Land"
                                            value={form1Data.country}
                                            onChange={(e) =>
                                                handleInputChange("country", e.target.value)
                                            }
                                        />
                                    </Col>

                                </Row>

                            </Col>
                            <Col xxl={6} xl={6} lg={6} md={6} xs={12}>
                                <h1 className="reg_subheadertext mt2 mb2">
                                    Kontaktdaten
                                </h1>
                                <Row >
                                    <Col xxl={10} xl={10} lg={10} md={10} xs={12} className="reg-col-input" >
                                        <TopLabelInput
                                            type={"text"}
                                            label="Email*"
                                            value={form1Data.email}
                                            onChange={(e) =>
                                                handleInputChange("email", e.target.value)
                                            }
                                        />
                                        {(form1Errors.email) && (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    marginBottom: 10,
                                                    marginTop: -10,
                                                }}
                                            >
                                                <FiAlertTriangle color="red" />
                                                <span className="error-message">{form1Errors.email}</span>
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                                <Row >
                                    <Col xxl={10} xl={10} lg={10} md={10} xs={12} className="reg-col-input" >
                                        <TopLabelInput
                                            type={"tel"}
                                            label="Telefonnummer"
                                            short={countryCode}
                                            value={form1Data.telephone}
                                            onChange={(value) => {
                                                handleInputChange("telephone", {
                                                    code: value.code,
                                                    phone: value.phone,
                                                    short: value.short,
                                                });
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row >
                                    {/* <Col xxl={10} xl={10} lg={10} md={10} xs={12} className="reg-col-input" >
                                        <TopLabelInput
                                            type={"password"}
                                            label="Passwort*"
                                            value={form1Data.password}
                                            onChange={(e) =>
                                                handleInputChange("password", e.target.value)
                                            }
                                        />
                                    </Col> */}

                                    <Col xxl={5} xl={10} lg={10} md={10} xs={6} className="reg-col-input">
                                        <TopLabelInput
                                            type={"password"}
                                            label="Passwort*"
                                            value={form1Data.password}
                                            onChange={(e) =>
                                                handleInputChange("password", e.target.value)
                                            }
                                        />

                                    </Col>
                                    <Col xxl={5} xl={10} lg={10} md={10} xs={6} className="reg-col-input">
                                        <TopLabelInput
                                            type={"password"}
                                            label="Passwort bestätigen*"
                                            value={form1Data.confirmPassword}
                                            onChange={(e) =>
                                                handleInputChange("confirmPassword", e.target.value)
                                            }
                                        />
                                    </Col>
                                    {(form1Errors.password || form1Errors.confirmPassword) && (
                                        <div
                                            style={{
                                                display: "flex",
                                                marginBottom: 10,
                                                marginTop: -10,
                                            }}
                                        >
                                            <FiAlertTriangle color="red" />
                                            <span className="error-message">{form1Errors.password !== undefined ? form1Errors.password : form1Errors.confirmPassword}</span>
                                        </div>
                                    )}
                                </Row>
                            </Col>
                        </Row>
                        <span className="note">Mit einem * markierte Felder stellen Pflichtangaben dar</span>
                        <Col className='error-row' style={{ marginTop: 5, marginBottom: 20 }}>
                            {form1Errors.apierror && (
                                <Row style={{ alignItems: 'center', }}>
                                    <Col md={1} style={{ marginTop: -4 }}><FiAlertTriangle color='red' /></Col>
                                    <Col md={9} style={{ marginLeft: -10 }}><div className="error-message">{form1Errors.apierror}</div></Col>
                                </Row>
                            )}
                        </Col>
                        <div style={{ display: "flex", marginTop: "2vmin", marginBottom: '2vmin' }}>
                            <button
                                className="next-btn1"
                                onClick={handleNextClick}
                            >
                                Weiter
                            </button>
                        </div>
                    </Col>
                    <Col xxl={3} xl={3} lg={0} md={0} xs={0} className="col-reg">
                        <div style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL + "/registration_bg.jpg"})`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            height: '100%'
                        }} />
                    </Col>
                </Row >
            </div>
        </>)
}


export default PrivateUserRegistration