import React, { useEffect } from 'react';
import './MeasurementInput.css'; // Import your CSS file for styling

const MeasurementInput = ({ label, value, onChange, type, measurement, minimumNumber }) => {

    const handleInputChange = (event) => {
        if (type === 'number') {
            const newValue = event.target.valueAsNumber;
            if (newValue < minimumNumber) {
                onChange({ target: { value: minimumNumber } }); // Set value to minimumNumber if less than minimumNumber
            } else {
                onChange(event);
            }
        } else {
            onChange(event);
        }
    };

    return (
        <>
            <div className="measurement_overview">
                <label>{label}</label>
                <div className="div_measurement">
                    <input className='input_measurement' style={{ width: '80%' }} type={type} value={value} min={0} onInput={handleInputChange}></input>
                    <div
                        className="measurementinput-dropdown-icon"
                        style={{ position: 'absolute', top: '50%', right: '8px', transform: 'translateY(-50%)', cursor: 'pointer', }}
                    >
                        {measurement}
                    </div>
                </div>

            </div>
        </>
    );
};

export default MeasurementInput;