import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import HomeScreen from "./Home/Home";
import SearchScreen from "./Search/Search";
import SellScreen from "./Sell/Sell";
import MagazineScreen from "./Magazine/Magazine";
import ShopScreen from "./Shop/Shop";
import EventsScreen from "./Events/Events";
import FinisherScreen from "./Finisher/Finisher";
import DashBoardScreen from "./Dashboard/Dashboard";
import RegistrationScreen from "./Registration/Registration";
import BusinessFormPage from "./BusinessForm/BusinessForm";
import BusinessPage from "./BusinessPage/BusinessPage";
import AdsMainPage from "./Ads/AdsMainPage";
import AdPreviewPage from "./AdPreviewPage/AdPreviewPage";
import AdsList from "./AdsList/AdsList";
import ChangePassword from "./ChangePassword/ChangePassword";
import SuccessPage from "./SuccessPage/SuccessPage";
import ErrorPage from '../screens/ErrorPage/ErrorPage'
import UserSelectionPage from "./UserPage/UserSelectionPage";
import PrivateUserRegistration from "./PrivateUserRegistration/PrivateUserRegistration";
import Profile from "./UserProfile/Profile";
import Messaging from "./Messaging/Messaging";
import RegistrationSuccess from "./PrivateUserRegistration/RegistrationSuccess";
import ScrollToTop from "../Components/ScrollToTop";


const MainNavigator = () => {
    useEffect(() => {
        console.log('xmlDoc')
    })
    return (
        <Router>
            <ScrollToTop />
            <Routes>
                <Route index element={<HomeScreen />} />
                <Route path="/home" element={<HomeScreen />} />
                <Route path="/registrationtypeselection" element={<UserSelectionPage />} />
                <Route path="/registration" element={<RegistrationScreen />} />
                <Route path="/sichere-meine-unternehmensseite-auf-campervanspot" element={<RegistrationScreen />} />
                <Route path="/private_user_registration" element={<PrivateUserRegistration />} />
                {/* <Route path="/businessPage" element={<><BusinessPage /></>} /> */}
                <Route path="/businesspage/:companyName" element={<BusinessPage />} />
                <Route path="/businessform" element={<BusinessFormPage />} />
                <Route path="/search" element={<SearchScreen />} />
                <Route path="/sell" element={<SellScreen />} />
                <Route path="/magazine" element={<MagazineScreen />} />
                <Route path="/shop" element={<ShopScreen />} />
                <Route path="/events" element={<EventsScreen />} />
                <Route path="/businesses" element={<FinisherScreen />} />
                <Route path="/dashboard" element={<><DashBoardScreen /></>} />
                <Route path="/adcreate" element={<><AdsMainPage /></>} />
                {/* <Route path="/adpreview" element={<><AdPreviewPage /></>} /> */}
                <Route path="/ad/:adId" element={<AdPreviewPage />} />
                <Route path="/adslist" element={<><AdsList /></>} />
                <Route path="/changepassword" element={<ChangePassword />} />
                <Route path="/successpage" element={<SuccessPage />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/chat" element={<Messaging />} />
                <Route path="/registration_success" element={<RegistrationSuccess />} />
                <Route path='*' element={<ErrorPage />} />
                {/* Catch-all route */}
            </Routes>
        </Router>
    );
}

export default MainNavigator;