import React, { useState } from 'react';

const ImageSlider = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [businessImage, setBusinessImage] = useState('')
    const nextImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };

    function isBlobURL(url) {
        // Regular expression to match blob URLs
        const blobUrlRegex = /^blob:/i;
        return blobUrlRegex.test(url);
    }

    const prevImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    // Update the business image when currentIndex changes
    React.useEffect(() => {
        const img = isBlobURL(images[currentIndex].updated_image) ? images[currentIndex].updated_image : (images[currentIndex].updated_image instanceof File || images[currentIndex].updated_image instanceof Blob) ? URL.createObjectURL(images[currentIndex].updated_image) : images[currentIndex].updated_image
        setBusinessImage(img)
        // const img = isBlobURL(images[currentIndex]) ? images[currentIndex] :
        //     (images[currentIndex] instanceof File || images[currentIndex] instanceof Blob) ?
        //         URL.createObjectURL(images[currentIndex]) : images[currentIndex];

        console.log('img', img)
        // Clean up when component unmounts or when the image changes
        return () => {
            if (typeof img === 'string' && img.startsWith('blob:')) {
                URL.revokeObjectURL(img);
            }
        };
    }, [currentIndex, images, setBusinessImage]);

    return (
        <div>
            <div className="businees_image">
                <img
                    src={businessImage == null ? require("../../assets/placeholder.png") : businessImage}
                    alt='logo'
                    style={{ objectFit: businessImage == null ? 'center' : 'cover', borderRadius: 5 }}
                    className="businessimage"
                />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <button onClick={prevImage} className="slider-button"><span>&#9664;</span></button>
                <button onClick={nextImage} className="slider-button"><span>&#9654;</span></button>
            </div>
        </div>
    );
};

export default ImageSlider;
