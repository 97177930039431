
import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import '../ImageDetail/ImageDetail.css'
import { ClipLoader } from "react-spinners";
import { css } from '@emotion/react';

const ImageModal = ({ imageDetails, closeModal, open, gallery, index }) => {
    const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
    // const [currentIndex, setCurrentIndex] = useState(index);
    const [currentImage, setCurrentImage] = useState(imageDetails)
    const [loading, setLoading] = useState(false);


    const showNextImage = () => {
        setLoading(true);
        const currentIndex = gallery.findIndex((image) => image.id === currentImage.id);
        const nextIndex = (currentIndex + 1) % gallery.length;
        const nextImage = gallery[nextIndex];
        const img = new Image();
        img.src = nextImage.vehicle_category_image_4k;
        img.onload = () => {
            setCurrentImage(nextImage);
            setLoading(false);
        };
    };


    const showPrevImage = () => {
        setLoading(true);
        const currentIndex = gallery.findIndex((image) => image.id === currentImage.id);
        const prevIndex = (currentIndex - 1 + gallery.length) % gallery.length;
        const prevImage = gallery[prevIndex];
        const img = new Image();
        img.src = prevImage.vehicle_category_image_4k;
        img.onload = () => {
            setCurrentImage(prevImage);
            setLoading(false);
        };
    };



    // const showNextImage = () => {
    //     const currentIndex = gallery.findIndex((image) => image.id === currentImage.id);
    //     const nextIndex = (currentIndex + 1) % gallery.length;
    //     console.log('nextIndex', gallery[nextIndex])
    //     setCurrentImage(gallery[nextIndex]);
    // };

    // const showPrevImage = () => {
    //     const currentIndex = gallery.findIndex((image) => image.id === currentImage.id);
    //     const prevIndex = (currentIndex - 1 + gallery.length) % gallery.length;
    //     setCurrentImage(gallery[prevIndex]);
    // };


    return (
        <div id="myModal" className="modal-image" style={{ display: open ? 'block' : 'none' }}>
            <span className="close" onClick={closeModal}>&times;</span>
            {gallery.length > 1 ? <div className="modal-content-container" onClick={closeModal}>
                <button className="prev" onClick={(e) => {
                    showPrevImage()
                    e.stopPropagation();
                }}>&#10094;</button>
                {loading ? (
                    <div className="loader">
                        <ClipLoader
                            color={"white"}
                            loading={loading}
                            css={override}
                            size={50}
                        />
                    </div>
                ) : (
                    <img
                        className="modal-content-image"
                        id="img01"
                        src={currentImage.vehicle_category_image_4k}
                        alt="Image Detail"
                    />
                )}
                {/* <img className="modal-content-image" id="img01" src={currentImage.vehicle_category_image_4k} alt="Image Detail" /> */}
                <button className="next" onClick={(e) => {
                    showNextImage()
                    e.stopPropagation();
                }}>&#10095;</button>
            </div> : <div className="modal-content-container" onClick={closeModal}>
                {loading ? (
                    <div className="loader">
                        <ClipLoader
                            color={"#123abc"}
                            loading={loading}
                            css={override}
                            size={50}
                        />
                    </div>
                ) : (
                    <img
                        className="modal-content-image"
                        id="img01"
                        src={currentImage.vehicle_category_image_4k}
                        alt="Image Detail"
                    />
                )}
                {/* <img className="modal-content-image" id="img01" src={currentImage.vehicle_category_image_4k} alt="Image Detail" /> */}
            </div>}
            <div id="caption"></div>
        </div>
    );
};

export default ImageModal;
