import React from 'react';
import '../../app.css'
import Header from '../../Components/Header/Header';

const ShopScreen = () => {
  return (
    <>
    <Header />
    <div className="full-width-container overlayStyle" style={{
      backgroundImage: `url(${process.env.PUBLIC_URL + "/shop_bg.png"})`, backgroundPosition: 'cover', backgroundSize: 'cover', height: "100vh",
    }}>
      <div className='mx-5' style={{ alignItems: 'center', justifyContent: 'center' }}>
        <h4 className='mediumtextstyle'>Hello Shop!</h4>
      </div>
    </div>
    </>
  );
};

export default ShopScreen;