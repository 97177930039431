import React, { useEffect } from "react";
import { Breakpoint, BreakpointProvider } from 'react-socks';
import MainNavigator from './screens/MainNavigator'

function App() {
  if (process.env.NODE_ENV === 'production') {
    console.log = function () { };
  }

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js').then(registration => {
          console.log('SW registered: ', registration);
        }).catch(registrationError => {
          console.log('SW registration failed: ', registrationError);
        });
      });
    }
  }, []);

  return (
    <>
      <BreakpointProvider>
        <Breakpoint></Breakpoint>
        <MainNavigator />
      </BreakpointProvider>
    </>
  );
}

export default App;

