
import React, { useState, useRef, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Form } from 'react-bootstrap';
import '../Registration/Registration.css'
import { FiAlertTriangle } from "react-icons/fi";
import '../../app.css'
import { getCookieValue, Strings, URLS } from '../../utilities/constants';
import axios from 'axios'
import { css } from '@emotion/react';
import { ClipLoader } from 'react-spinners';
import { DataContext } from '../../utilities/DataContext';
import Footer from '../../Components/Footer/Footer';
import { IoEye } from "react-icons/io5";
import { IoEyeOff } from "react-icons/io5";
import { trackPageView } from '../../hooks/analytics';

const RegistrationPage = () => {

  const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

  const mainDivRef = useRef(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const input1Ref = useRef(null);
  const { setUserData, setToken, setIsLoggedIn, userType, setCompanyInfo, setUserType, setTab, setNoOfBedErr, setMessageCount, setChatData, setBusinessForm1data, setBusinessForm2data } = useContext(DataContext)
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);

  const [formData, setFormData] = useState({
    companyName: '',
    domainName: '',
    firstName: '',
    lastName: '',
    role: '',
    emailMarketing: '',
    password: '',
    confirmPassword: '',
    terms: false,
    privacy: false,
  });

  const [formErrors, setFormErrors] = useState({
    companyName: '',
    domainName: '',
    firstName: '',
    lastName: '',
    role: '',
    emailMarketing: '',
    password: '',
    confirmPassword: '',
    terms: '',
    privacy: '',
    apierror: ''
  });


  useEffect(() => {
    document.title = 'Registrierung auf campervanspot.com'
    const consentCookie = getCookieValue('cookie_consent_level');
    if (consentCookie) {
      const consentStatus = JSON.parse(decodeURIComponent(consentCookie));
      console.log('consentStatus', consentStatus.tracking);
      if (consentStatus.tracking) {
        trackPageView(window.location.pathname, 'Registrierung auf campervanspot.com')
      }
    }

  }, []);



  useEffect(() => {
    setUserType('business-user')
  }, []);

  const togglePasswordVisibility = (pswtype) => {
    if (pswtype == 'password1') {
      setIsPasswordVisible(!isPasswordVisible);
    } else {
      setIsConfirmPasswordVisible(!isConfirmPasswordVisible)
    }
  };

  const handleCheckboxChange = (checkboxtype) => {
    if (checkboxtype === 'checkbox1') {
      setFormData({
        ...formData,
        terms: !formData.terms,
      });
    } else {
      setFormData({
        ...formData,
        privacy: !formData.privacy,
      });
      // setStorePermChecked(!storePermcheck)
    }
  };

  const handleInputChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleSubmit = (e) => {
    console.log('e.target.value', e)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    e.preventDefault();
    // Validate form fields
    const errors = {};
    // Implement your validation logic for each field
    if (!formData.companyName.trim()) {
      errors.companyName = Strings.company_name_required
    }
    if (!formData.domainName.trim()) {
      errors.domainName = Strings.domain_required
    }
    if (!formData.firstName.trim()) {
      errors.lastName = Strings.first_name_required
    }
    if (!formData.lastName.trim()) {
      errors.lastName = Strings.last_name_required
    }
    if (!formData.emailMarketing.trim()) {
      errors.emailMarketing = Strings.email_err
    } else if (!emailRegex.test(formData.emailMarketing.trim())) {
      errors.emailMarketing = Strings.invalid_email
    }
    if (!formData.password.trim()) {
      errors.confirmPassword = Strings.password_err
    }
    if (!formData.confirmPassword.trim()) {
      errors.confirmPassword = Strings.confirm_password_err
    }
    if (formData.password.trim() !== formData.confirmPassword.trim()) {
      errors.confirmPassword = Strings.confirm_password_error2
    }
    if (!formData.terms) {
      errors.terms = Strings.required_error
    }
    if (!formData.privacy) {
      errors.privacy = Strings.required_error
    }
    // Add similar validations for other fields
    setFormErrors(errors);
    // If there are no errors, proceed with form submission logic
    if (Object.keys(errors).length === 0) {
      callRegisterapi()
    }
  };


  const callRegisterapi = async () => {
    setLoading(true)
    const url = `${URLS.BASE_URL1}${URLS.REGISTRATION_URL}`;
    console.log('url', url)
    const form = new FormData();
    // form.append('user_type', userType)
    form.append('company_name', formData.companyName)
    form.append('domain_name', formData.domainName)
    form.append('first_name', formData.firstName)
    form.append('last_name', formData.lastName)
    form.append('password', formData.password)
    form.append('password2', formData.confirmPassword)
    form.append('roll_company', formData.role)
    form.append('email', formData.emailMarketing)
    form.append('user_type', userType)

    console.log('formdata', form)

    axios.defaults.headers.common["Accept"] = "application/json";
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    await axios.post(url, form)
      .then((response) => {
        setLoading(false)
        if (response?.data.status === 1) {
          // alert(response.data.message);
          const data = response?.data.user
          setIsLoggedIn(true)
          setUserData(data);
          setCompanyInfo(response?.data.company_information)
          setToken(response?.data?.token)
          setFormData({
            companyName: '',
            domainName: '',
            firstName: '',
            lastName: '',
            role: '',
            emailMarketing: '',
            password: '',
            confirmPassword: '',
            terms: false,
            privacy: false,
          });
          navigate('/businessform', { state: { isfrom: 'registration', message: response.data.message } })

          setLoading(false)
        } else {
          setLoading(false)
          setUserData({})
          setCompanyInfo({})
          setUserType('')
          setTab('')
          setNoOfBedErr('')
          setMessageCount('')
          setToken('')
          setChatData([])
          setBusinessForm1data({})
          setBusinessForm2data({})
          setIsLoggedIn(false)
        }
      })
      .catch(function (error) {
        console.log('error', error);
        setLoading(false)
        const errors = {};
        errors.apierror = error.response.data.message;
        setFormErrors(errors)
        setUserData({})
        setCompanyInfo({})
        setUserType('')
        setTab('')
        setNoOfBedErr('')
        setMessageCount('')
        setToken('')
        setChatData([])
        setBusinessForm1data({})
        setBusinessForm2data({})
        setIsLoggedIn(false)
        // alert(error.response.data.message);
      });
  }

  return (
    <div ref={mainDivRef} style={{ overflowY: 'scroll' }}>
      {loading && (
        <div className="loader">
          <ClipLoader color={'#123abc'} loading={loading} css={override} size={50} />
        </div>
      )}
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', backgroundColor: 'white', height: '15vmin' }}>
        <img
          src={require("../../../src/assets/logo.webp")}
          alt='logo'
          className="d-inline-block align-top"
          onClick={() => { navigate('/home') }}
        />
      </div>
      <div style={{ height: '200vh', backgroundColor: 'white', }} >
        <div style={{
          padding: '10vh',
          backgroundImage: `url(${process.env.PUBLIC_URL + "/reg_back.jpg"})`, backgroundPosition: 'center', backgroundSize: 'cover', alignItems: 'center', display: 'flex', justifyContent: 'center'
        }}>
          <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Col xl={7} lg={6} md={6} sm={10} style={{ marginTop: '25vmin', paddingRight: '0vmin' }}>
              <p style={{ padding: 10, }}>
                <span className='normaltext'>
                  {Strings.registration_desc1}
                </span>{' '}
                <span className='boldtext'>
                  {Strings.registration_desc2}
                </span>
                <span className='normaltext'>{Strings.registration_desc3}</span>{' '}
                <span className='normaltext'>{Strings.registration_desc4}</span>
              </p>
            </Col>
            <Col md={1} xl={1}></Col>
            <Col className='shadowdiv' >
              <Col style={{ display: 'flex', justifyContent: 'center', marginTop: '2vh', marginBottom: 20 }}>
                <span className='headersignup'>
                  {Strings.registration} <div className='borderstyle'></div> </span>
              </Col>

              <Form onSubmit={handleSubmit} >
                <Row>
                  <Col className='col-input' >
                    <Form.Control
                      ref={input1Ref}
                      type="text"
                      placeholder={Strings.company_name}
                      value={formData.companyName}
                      onChange={(e) => handleInputChange('companyName', e.target.value)}
                    />
                  </Col>
                </Row>
                {/* <div className='mb-3'>
                  <Form.Control
                    ref={input1Ref}
                    type="text"
                    placeholder={Strings.company_name}
                    value={formData.companyName}
                    onChange={(e) => handleInputChange('companyName', e.target.value)}
                  />
                </div> */}
                <Col className='error-row'>
                  {formErrors.companyName && (
                    <Row >
                      <Col md={1} style={{ marginTop: -4 }}><FiAlertTriangle color='red' /></Col>
                      <Col md={11} style={{ marginLeft: -15 }}><div className="error-message">{formErrors.companyName}</div></Col>
                    </Row>
                  )}
                </Col>
                <Row>
                  <Col className='col-input' >
                    <Form.Control
                      // ref={input1Ref}
                      placeholder={Strings.domain_name}
                      value={formData.domainName}
                      type="text"
                      onChange={(e) => handleInputChange('domainName', e.target.value)}
                    />
                  </Col>
                </Row>
                {/* <div className='mb-3'>
                  <Form.Control
                    // ref={input1Ref}
                    placeholder={Strings.domain_name}
                    value={formData.domainName}
                    type="text"
                    onChange={(e) => handleInputChange('domainName', e.target.value)}
                  />
                </div> */}
                <Col className='error-row'>
                  {formErrors.domainName && (
                    <Row className='error-row'>
                      <Col md={1} style={{ marginTop: -4 }}><FiAlertTriangle color='red' /></Col>
                      <Col md={11} style={{ marginLeft: -15 }}><div className="error-message">{formErrors.domainName}</div></Col>
                    </Row>
                  )}
                </Col>
                <Row>
                  <Col className='col-input' >
                    <Form.Control
                      // ref={input1Ref}
                      placeholder={Strings.first_name}
                      type="text"
                      value={formData.firstName}
                      onChange={(e) => handleInputChange('firstName', e.target.value)}
                    />
                  </Col>
                  <Col className='col-input'>
                    <Form.Control
                      // ref={input1Ref}
                      placeholder={Strings.last_name}
                      type="text"
                      value={formData.lastName}
                      onChange={(e) => handleInputChange('lastName', e.target.value)}
                    />
                  </Col>
                </Row>

                {/* <div className='rownames'>
                  <div style={{ marginRight: 5 }}>
                    <Form.Control
                      // ref={input1Ref}
                      placeholder={Strings.first_name}
                      type="text"
                      value={formData.firstName}
                      onChange={(e) => handleInputChange('firstName', e.target.value)}
                    />
                  </div>
                  <div style={{ marginLeft: 5 }} >
                    <Form.Control
                      // ref={input1Ref}
                      placeholder={Strings.last_name}
                      type="text"
                      value={formData.lastName}
                      onChange={(e) => handleInputChange('lastName', e.target.value)}
                    />
                  </div>
                </div> */}
                <Col className='error-row'>
                  {formErrors.lastName && (
                    <Row style={{ alignItems: 'center', }}>
                      <Col md={1} style={{ marginTop: -4 }}><FiAlertTriangle color='red' /></Col>
                      <Col md={11} style={{ marginLeft: -10 }}><div className="error-message">{formErrors.lastName}</div></Col>
                    </Row>
                  )}
                </Col>
                <Row>
                  <Col className='col-input' >
                    <Form.Control
                      // ref={input1Ref}
                      placeholder={Strings.email}
                      type="email"
                      value={formData.emailMarketing}
                      onChange={(e) => handleInputChange('emailMarketing', e.target.value)}
                    />
                  </Col>
                </Row>
                <Col className='error-row'>
                  {formErrors.emailMarketing && (
                    <Row style={{ alignItems: 'center', }}>
                      <Col md={1} style={{ marginTop: -5 }}><FiAlertTriangle color='red' /></Col>
                      <Col md={11} style={{ marginLeft: -10 }}><div className="error-message">{formErrors.emailMarketing}</div></Col>
                    </Row>
                  )}
                </Col>
                <Row>
                  <Col className='col-input' >
                    <div style={{ position: 'relative' }}>
                      <Form.Control
                        // ref={input1Ref}
                        placeholder={Strings.password}
                        type={isPasswordVisible ? "text" : 'password'}
                        value={formData.password}
                        style={{ paddingRight: 30 }}
                        onChange={(e) => handleInputChange('password', e.target.value)}
                      />
                      <span
                        onClick={() => togglePasswordVisibility('password1')}
                        style={{
                          position: 'absolute',
                          right: 10,
                          top: '50%',
                          transform: 'translateY(-50%)',
                          cursor: 'pointer',
                        }}
                      >
                        {isPasswordVisible ? <IoEyeOff color='white' /> : <IoEye color='white' />}
                      </span>

                    </div>
                  </Col>

                  <Col className='col-input' >
                    <div style={{ position: 'relative' }}>
                      <Form.Control
                        // ref={input1Ref}
                        placeholder={Strings.confirm_password}
                        type={isConfirmPasswordVisible ? "text" : 'password'}
                        style={{ paddingRight: 30 }}
                        value={formData.confirmPassword}
                        onChange={(e) => handleInputChange('confirmPassword', e.target.value)}
                      />
                      <span
                        onClick={() => togglePasswordVisibility('password2')}
                        style={{
                          position: 'absolute',
                          right: 10,
                          top: '50%',
                          transform: 'translateY(-50%)',
                          cursor: 'pointer',
                        }}
                      >
                        {isConfirmPasswordVisible ? <IoEyeOff color='white' /> : <IoEye color='white' />}
                      </span>

                    </div>
                  </Col>

                </Row>
                <Col className='error-row'>
                  {formErrors.confirmPassword && (
                    <Row style={{ alignItems: 'center', }}>
                      <Col md={1} style={{ marginTop: -4 }}><FiAlertTriangle color='red' /></Col>
                      <Col md={11} style={{ marginLeft: -10 }}><div className="error-message">{formErrors.confirmPassword}</div></Col>
                    </Row>
                  )}
                </Col>
                {/* <div className='rownames'>
                  <div style={{ marginRight: 5 }}>
                    <Form.Control
                      // ref={input1Ref}
                      placeholder={Strings.password}
                      type="password"
                      value={formData.password}
                      onChange={(e) => handleInputChange('password', e.target.value)}
                    />
                  </div>
                  <div style={{ marginLeft: 5 }}>
                    <Form.Control
                      // ref={input1Ref}
                      placeholder={Strings.confirm_password}
                      type="password"
                      value={formData.confirmPassword}
                      onChange={(e) => handleInputChange('confirmPassword', e.target.value)}
                    />
                  </div>
                </div> */}
                <Row>
                  <Col className='col-input' >
                    <Form.Control
                      // ref={input1Ref}
                      placeholder={Strings.role}
                      type="text"
                      value={formData.role}
                      onChange={(e) => handleInputChange('role', e.target.value)}
                    />
                  </Col>
                </Row>
                <Col className='error-row' style={{ marginTop: 5, marginBottom: 20 }}>
                  {formErrors.apierror && (
                    <Row style={{ alignItems: 'center', }}>
                      <Col md={1} style={{ marginTop: -4 }}><FiAlertTriangle color='red' /></Col>
                      <Col md={11} style={{ marginLeft: -10 }}><div className="error-message">{formErrors.apierror}</div></Col>
                    </Row>
                  )}
                </Col>
                {/* <div className='mb-3'>
                  <Form.Control
                    // ref={input1Ref}
                    placeholder={Strings.role}
                    type="text"
                    value={formData.role}
                    onChange={(e) => handleInputChange('role', e.target.value)}
                  />
                </div> */}
                <Col className='error-row'>
                  {formErrors.emailMarketing && (
                    <Row style={{ alignItems: 'center', }}>
                      <Col md={1} style={{ marginTop: -5 }}><FiAlertTriangle color='red' /></Col>
                      <Col md={11} style={{ marginLeft: -10 }}><div className="error-message">{formErrors.emailMarketing}</div></Col>
                    </Row>
                  )}
                </Col>


                {/* <div className='mb-3'>
                  <Form.Control
                    // ref={input1Ref}
                    placeholder={Strings.email}
                    type="email"
                    value={formData.emailMarketing}
                    onChange={(e) => handleInputChange('emailMarketing', e.target.value)}
                  />
                </div> */}

                <p className='form-check-label'>
                  {Strings.terms1}
                </p>
                <Form.Check
                  type="checkbox"
                  className='form-check'
                  label={Strings.checkbox1}
                  checked={formData.terms}
                  onChange={() => { handleCheckboxChange('checkbox1') }}
                />
                <Col className='error-row1'>
                  {formErrors.terms && (
                    <Row style={{ alignItems: 'center', }}>
                      <Col md={1} style={{ marginTop: -5 }}><FiAlertTriangle color='red' /></Col>
                      <Col md={11} style={{ marginLeft: -10 }}><div className="error-message">{formErrors.terms}</div></Col>
                    </Row>
                  )}
                </Col>
                <p className='form-check-label' style={{ marginTop: 10 }}>
                  {Strings.terms1}
                </p>
                <Form.Check
                  type="checkbox"
                  className='form-check'
                  label={Strings.checkbox2}
                  checked={formData.privacy}
                  onChange={() => { handleCheckboxChange('checkbox2') }}
                />
                <Col className='error-row1'>
                  {formErrors.privacy && (
                    <Row style={{ alignItems: 'center', }}>
                      <Col md={1} style={{ marginTop: -5 }}><FiAlertTriangle color='red' /></Col>
                      <Col md={11} style={{ marginLeft: -10 }}><div className="error-message">{formErrors.privacy}</div></Col>
                    </Row>
                  )}
                </Col>
                <p className='form-check-label' style={{ marginTop: 10 }}>
                  {Strings.terms2}
                </p>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                  <button type="submit" className='btnsubmit'>
                    {Strings.send}
                  </button>
                </div>


              </Form>

            </Col>
          </Row>
        </div>

        <Row style={{ display: 'flex', justifyContent: 'space-between', padding: '10vmin' }}>
          <Col md={5} >
            <span className='varumtext'>{Strings.why}</span>
            <p style={{ fontSize: '2.3vmin', marginTop: 20 }}>{Strings.app_description}</p>
            {/* <div style={{ marginTop: '5vmin' }}>
              <span style={{ color: 'black' }}>{Strings.website}</span>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: 10 }}>
                <a href="https://www.instagram.com/campervanspot_com/" target="_blank" rel="noopener noreferrer">
                  <div style={{ backgroundColor: 'rgb(33,150,243)', width: 34, height: 34, marginRight: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 17 }}>
                    <FaInstagram style={{ height: 20, width: 20 }} color='white' />
                    {/* <img style={{ height: 40, width: 40, marginRight: 30 }} src={require("../../../src/assets/instagram.jpeg")} /> */}
            {/* </div>
                </a>
                <a href="mailto:bb@campervanspot.com" target="_blank" rel="noopener noreferrer">
                  <div style={{ backgroundColor: 'rgb(33,150,243)', width: 34, height: 34, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 17 }}>
                    <HiMail style={{ height: 20, width: 20 }} color='white' /> */}
            {/* <img style={{ height: 40, width: 40, marginRight: 30 }} src={require("../../../src/assets/instagram.jpeg")} /> */}
            {/* </div>
                </a>
              </div> */}
            {/* </div> */}
          </Col>
          <Col md={6} >
            <p style={{ fontSize: '2.3vmin', marginTop: 20 }}>{Strings.company_page_guide}</p>
            <ul className="custom-list" style={{ fontSize: '2.3vmin', }} >
              <li >{Strings.company_page_guide1}</li>
              <li>{Strings.company_page_guide2}</li>
              <li>{Strings.company_page_guide3}</li>
            </ul>
          </Col>
        </Row>
      </div>
      <Footer />
    </div>
  );
};

export default RegistrationPage;
