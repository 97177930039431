import React, { useState } from 'react';
import './TopLabelInput.css'; // Import your CSS file for styling
import en from 'world_countries_lists/data/countries/en/world.json';
import CountryPhoneInput, { ConfigProvider } from 'antd-country-phone-input';
import { IoEye } from "react-icons/io5";
import { IoEyeOff } from "react-icons/io5";

const TopLabelInput = ({ label, value, onChange, type, short, minimumNumber }) => {

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleInputChange = (event) => {
    if (type === 'number') {
      const newValue = event.target.valueAsNumber;
      if (newValue < minimumNumber) {
        onChange({ target: { value: minimumNumber } }); // Set value to minimumNumber if less than minimumNumber
      } else {
        onChange(event);
      }
    } else {
      onChange(event);
    }
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <>
      {type === 'tel' ?
        <div>
          <ConfigProvider locale={en} areaMapper={(area) => {
            return {
              ...area,
              emoji: null,
            };
          }}
          >
            <CountryPhoneInput
              maxLength={14}
              name='phone'
              placeholder='Telefonnummer'
              style={{ marginBottom: 15 }}
              inline
              defaultValue={{ short: short }}
              value={{ phone: value.replace(/^\+\d+\s*/, ''), code: value.split(' ')[0], short: short }}
              onChange={onChange}
            />
          </ConfigProvider>
        </div>
        :
        <div className="top-label-input">
          <label>{label}</label>
          {type === 'textarea' ? (
            <textarea style={{ padding: 10 }} value={value} onChange={onChange}></textarea>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input
                className='toplabaleinput'
                type={isPasswordVisible ? "text" : type}
                min={minimumNumber || 0}
                value={value}
                style={{ paddingRight: 35 }}
                onInput={handleInputChange}
              />
              {type === 'password' && (
                <span
                  onClick={togglePasswordVisibility}
                  style={{
                    position: 'absolute',
                    right: 10,
                    cursor: 'pointer',
                  }}
                >
                  {isPasswordVisible ? <IoEyeOff size={20} /> : <IoEye size={20} />}
                </span>
              )}
            </div>
          )}
        </div>
      }
    </>
  );
};

export default TopLabelInput;
