import React, { useState, useRef, useEffect, useContext, createRef } from 'react';
import { Row, Col, Card, CardBody, Collapse } from 'react-bootstrap';
import '../../app.css'
import '../Ads/Ads.css';
import { newtaburl, Strings, URLS } from '../../utilities/constants';
import { modules, formats } from '../../utilities/constants'
import { css } from '@emotion/react';
import axios from 'axios';
import ReactQuill from 'react-quill'
import 'quill/dist/quill.snow.css'
import { IoIosDesktop } from "react-icons/io";
import { RxCross1 } from "react-icons/rx";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { DataContext } from '../../utilities/DataContext';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from "react-spinners";
import AlertComponent from "../../Components/AlertComponent";
import { FiAlertTriangle } from "react-icons/fi";

const AdTechicleDetails = ({ onBackPress, onNextPress, tabchanged, onClick }) => {
  const navigate = useNavigate();
  const { userData, token, addDetails, setAddDetails, tab, noOfBedErr, setNoOfBedErr } = useContext(DataContext);
  const [sections, setSections] = useState([
    { id: 1, title: 'Wasser', isOpen: false },
    { id: 2, title: 'Schlafen', isOpen: false },
    { id: 3, title: 'Strom', isOpen: false },
    { id: 4, title: 'Außen', isOpen: false },
    { id: 5, title: 'Wohnen', isOpen: false },
    { id: 6, title: 'Küche', isOpen: false },
    { id: 7, title: 'Dach', isOpen: false },
    { id: 8, title: 'Keller', isOpen: false },
    { id: 9, title: 'Gas', isOpen: false },
    { id: 10, title: 'Heizung/Klimaanlage', isOpen: false },
    // Add more sections as needed
  ]);
  const inputRefs = useRef(Array(sections.length).fill().map(() => createRef()));
  const [techincalDescription, setTechDescription] = useState('')
  const [doublebedcount, setDoubleBedCount] = useState('');
  const [sinlgeBedCount, setSinlgeBedCount] = useState('');
  const [doubleBedSizes, setDoubleBedSizes] = useState([]);
  const [singleBedSizes, setSingleBedSizes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('')
  const [showalert, setShowAlert] = useState(false)
  const [techTabOpend, setTechinTabOpend] = useState(false)
  const [btnNextClick, setBtnNextClick] = useState(false)

  const [technicalValues, setTechnicalValues] = useState({
    wateWater: '',
    freshWater: '',
    socket1: '',
    socket2: '',
    usbports: '',
    solarpannel: ''
  });

  const [categoryDesc, setCatDesc] = useState({
    Wasser: "",
    Schlafen: "",
    Strom: "",
    Außen: "",
    Wohnen: "",
    Küche: "",
    Dach: "",
    Keller: "",
    Gas: "",
    "Heizung/Klimaanlage": ""
  });

  const categoryDescRef = useRef(categoryDesc);
  const techincalDescriptionRef = useRef(techincalDescription);
  const singleBedSizesRef = useRef(singleBedSizes);
  const doubleBedSizesRef = useRef(doubleBedSizes);
  const technicalValuesRef = useRef(technicalValues);


  const [formdataError, setFormDataError] = useState({
    bedSize: '',
  });


  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  useEffect(() => {
    setTechinTabOpend(true)
  }, [techTabOpend]);

  useEffect(() => {
    categoryDescRef.current = categoryDesc;
  }, [categoryDesc]);

  useEffect(() => {
    techincalDescriptionRef.current = techincalDescription;
  }, [techincalDescription]);

  useEffect(() => {
    singleBedSizesRef.current = singleBedSizes;
  }, [singleBedSizes]);

  useEffect(() => {
    doubleBedSizesRef.current = doubleBedSizes;
  }, [doubleBedSizes]);

  useEffect(() => {
    technicalValuesRef.current = technicalValues;
  }, [technicalValues]);

  useEffect(() => {
    if (noOfBedErr !== '') {
      let error = {}
      error.bedSize = "Bitte füllen Sie dieses Pflichtfeld aus.";
      setFormDataError(error)
    }
    toggleSection(1)

  }, []);

  useEffect(() => {
    if (techTabOpend !== true) {
      if (!isEmptyObject(addDetails) && addDetails.vehicle_ad_overview_data.id) {
        callAddDetailApi(addDetails.vehicle_ad_overview_data.id, 'details')
      }
    }

    return () => {
      console.log('tabchangedtechnicalData', tab)
      if (tab !== 'technicalData' && tab !== '') {
        setTechinTabOpend(false)
        handleNextClick('tabchange')
      }
    };
  }, [tab]);


  const validateFormData = () => {
    // console.log('singleBedCount', singleBedCount, "doubleBedCount", doubleBedCount);
    const errors = {};

    // Check if both arrays are empty
    if (singleBedSizesRef.current.length == 0 && doubleBedSizesRef.current.length == 0) {
      errors.bedSize = "Bitte füllen Sie dieses Pflichtfeld aus.";
      return errors; // Return early if both arrays are empty
    }

    // Function to check if an object has both height and length
    const hasHeightAndLength = (bedSize) => {
      return bedSize.hasOwnProperty('height') && bedSize.hasOwnProperty('length');
    };

    let singleBedsValid = true;
    let doubleBedsValid = true;

    // Check each object in singleBedSizesRef
    singleBedSizesRef.current.forEach((bedSize, index) => {
      if (!hasHeightAndLength(bedSize)) {
        errors.bedSize = "Bitte füllen Sie dieses Pflichtfeld aus.";
        singleBedsValid = false;
      }
    });

    // Check each object in doubleBedSizesRef
    doubleBedSizesRef.current.forEach((bedSize, index) => {
      if (!hasHeightAndLength(bedSize)) {
        errors.bedSize = "Bitte füllen Sie dieses Pflichtfeld aus.";
        doubleBedsValid = false;
      }
    });

    // If both arrays are non-empty but some objects are invalid, return errors
    if (!singleBedsValid || !doubleBedsValid) {
      return errors;
    }

    // If there are no errors, return an empty object
    return errors;
  }




  const toggleSection = (sectionIndex) => {
    setSections(prevSections => {
      const newSections = [...prevSections];
      newSections[sectionIndex].isOpen = !newSections[sectionIndex].isOpen;
      return newSections;
    });
  };

  const handleDoubleBedChange = (e) => {
    console.log('double', e.target.value)
    setDoubleBedSizes([])
    if (e.target.value == '') {
      setDoubleBedCount('');
    } else {
      let newValue = parseInt(e.target.value);
      if (newValue < 1) {
        newValue = 1;
      } else if (newValue > 5) {
        newValue = 5;
      }
      setDoubleBedCount(newValue);
    }
    // setDoubleBedCount(parseInt(e.target.value));
  };

  const handleSingleBedChange = (e) => {
    console.log('sinlge', e.target.value)
    setSingleBedSizes([])
    if (e.target.value == '') {
      setSinlgeBedCount('');
    } else {
      let newValue = parseInt(e.target.value);
      if (newValue < 1) {
        newValue = 1;
      } else if (newValue > 5) {
        newValue = 5;
      }
      setSinlgeBedCount(newValue);
    }

  };

  const handleDescChange = (content, title) => {
    console.log('content', content)
    console.log('title', title)
    setCatDesc(prevData => {
      const updatedData = {
        ...prevData,
        [title]: content === '<p><br></p>' ? '' : content
      };
      console.log('Updated  data:', updatedData);
      return updatedData;
    });
  }

  const handleTechDesChange = (content) => {
    console.log('content', content)
    setTechDescription(content)
  }

  const handleBackClick = () => {
    if (!isEmptyObject(addDetails)) {
      handleNextClick('back')
    } else {
      onBackPress()
    }
  }

  const handleNextClick = (btnAction) => {
    const errors = validateFormData();
    console.log('Object.keys(errors', errors)
    setFormDataError(errors);
    if (Object.keys(errors).length === 0) {
      setNoOfBedErr('')
      if (!isEmptyObject(addDetails) && !isEmptyObject(addDetails.vehicle_ad_feature_technical_data)) {
        callAddTechnicalDataUpdateApi(btnAction)
      } else {
        if (!isEmptyObject(addDetails)) {
          callAddTechnicalDataCreateApi(btnAction)
        }
      }
    } else {
      setSections(prevSections => {
        const newSections = [...prevSections];
        newSections[1].isOpen = true;
        return newSections;
      });
    }

  }

  const formatAddName = (name) => {
    return name
      .toLowerCase()                          // Convert to lowercase
      .replace(/[^a-z0-9]+/g, '-')            // Replace non-alphanumeric characters with a hyphen
      .replace(/^-+|-+$/g, '');               // Remove leading or trailing hyphens
  };

  const openNewTab = () => {
    // Check if running in development or production
    const formattedAddName = formatAddName(addDetails.vehicle_ad_overview_data.vehicle_name);
    // const baseUrl =
    //   process.env.NODE_ENV === "development"
    //     ? URLS.APP_LOCAL_URL
    //     : URLS.APP_STAGIN_URL;
    // const formattedCompanyName = formatCompanyName(userData.company_name);
    const baseUrl = newtaburl
    const componentPage = `ad/${addDetails.vehicle_ad_overview_data.id}`;
    const url = baseUrl + componentPage;
    window.open(url, "_blank");
  };


  const handleInputChange = (label, value) => {
    // Limiting input length to 5

    if (value.length <= 10) {
      console.log('value', value)
      if (value === '') {
        setTechnicalValues(prevData => {
          const updatedData = {
            ...prevData,
            [label]: ''
          };
          console.log('Updated  data:', updatedData);
          return updatedData;
        });
      } else {
        setTechnicalValues(prevData => {
          const updatedData = {
            ...prevData,
            // [label]: parseInt(value, 10)
            [label]: value
          };
          console.log('Updated  data:', updatedData);
          return updatedData;
        });
      }

    }

  };

  const handleStromInputChange = (label, value) => {
    // Limiting input length to 5
    setTechnicalValues(prevData => {
      const updatedData = {
        ...prevData,
        [label]: value
      };
      console.log('Updated  data:', updatedData);
      return updatedData;
    });
  }

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };



  const callAddTechnicalDataCreateApi = async (btnAction) => {
    setLoading(true)
    const url = `${URLS.BASE_URL}${URLS.ADD_TECHNICALDATA_CREATE}`;
    const form = new FormData();
    const bedsizes = []
    const singleBedSizesArray = singleBedSizesRef.current.map(item => ({
      length_cm: item.length, // converting cm to meters and then to string
      width_cm: item.height   // converting cm to meters and then to string
    }));
    const doubleBedSizesArray = doubleBedSizesRef.current.map(item => ({
      length_cm: item.length, // converting cm to meters and then to string
      width_cm: item.height   // converting cm to meters and then to string
    }));

    const catDescription = [
      { "category": "Wasser", "category_description": categoryDescRef.current.Wasser, "technical_data": { "waste_water_ltr": (technicalValuesRef.current.wateWater !== null && technicalValuesRef.current.wateWater !== "0") ? technicalValuesRef.current.wateWater : '', "fresh_water_ltr": (technicalValuesRef.current.freshWater !== null && technicalValuesRef.current.freshWater !== "0") ? technicalValuesRef.current.freshWater : '' } },
      { "category": "Schlafen", "category_description": categoryDescRef.current.Schlafen, "technical_data": { "single": singleBedSizesArray, "double": doubleBedSizesArray } },
      { "category": "Strom", "category_description": categoryDescRef.current.Strom, "technical_data": { "sockets_230v": (technicalValuesRef.current.socket1 !== null && technicalValuesRef.current.socket1 !== "0") ? technicalValuesRef.current.socket1 : '', "sockets_12v": (technicalValuesRef.current.socket2 !== null && technicalValuesRef.current.socket2 !== "0") ? technicalValuesRef.current.socket2 : '', "connections_usb_c": (technicalValuesRef.current.usbports !== null && technicalValuesRef.current.usbports !== "0") ? technicalValuesRef.current.usbports : '', "solar_power_w": (technicalValuesRef.current.solarpannel !== null && technicalValuesRef.current.solarpannel !== "0") ? technicalValuesRef.current.solarpannel : '' } },
      { "category": "Außen", "category_description": categoryDescRef.current.Außen, "technical_data": {} },
      { "category": "Wohnen", "category_description": categoryDescRef.current.Wohnen, "technical_data": {} },
      { "category": "Küche", "category_description": categoryDescRef.current.Küche, "technical_data": {} },
      { "category": "Dach", "category_description": categoryDescRef.current.Dach, "technical_data": {} },
      { "category": "Keller", "category_description": categoryDescRef.current.Keller, "technical_data": {} },
      { "category": "Gas", "category_description": categoryDescRef.current.Gas, "technical_data": {} },
      { "category": "Heizung/Klimaanlage", "category_description": categoryDescRef.current['Heizung/Klimaanlage'], "technical_data": {} },
    ]
    form.append('vehicle_ad_overview', addDetails ? addDetails.vehicle_ad_overview_data.id : '')
    form.append('technical_data_description', techincalDescriptionRef.current)
    form.append('technical_data_category_descriptions', JSON.stringify(catDescription))
    const formDataObject = {};
    form.forEach((value, key) => {
      formDataObject[key] = value;
    });
    console.log('formDataObject', formDataObject);
    axios.defaults.headers.common["Accept"] = "application/json";
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
    await axios.post(url, form, { headers: { 'Authorization': token } })
      .then((response) => {
        setLoading(false)
        console.log('response', response);
        if (response?.data.status === 1) {
          callAddDetailApi(addDetails.vehicle_ad_overview_data.id, btnAction)
          // setTimeout(() => {
          //   if (btnAction == 'next') {
          //     onNextPress()
          //   } else if (btnAction == 'preview') {
          //     openNewTab()
          //   } else if (btnAction == 'back') {
          //     onBackPress()
          //   } else if (btnAction == 'tabchange') {
          //     onClick()
          //   }
          // }, 200);

        } else {
          console.log('response', response);
          setShowAlert(true)
          setError(response.data.message)
        }
      })
      .catch(function (error) {
        setLoading(false)
        console.log('error', error);
        if (error.response) {
          setShowAlert(true)
          setError(error.response.data.message)
        }
      });
  }

  const callAddTechnicalDataUpdateApi = async (btnAction) => {
    // console.log('technicalValuesRef.current.freshWater', technicalValuesRef.current.freshWater)
    setLoading(true)
    const url = `${URLS.BASE_URL}${URLS.ADD_TECHNICALDATA_UPDATE}${addDetails.vehicle_ad_overview_data.id}/`;
    const form = new FormData();
    const bedsizes = []
    const singleBedSizesArray = singleBedSizesRef.current.map(item => ({
      length_cm: item.length, // converting cm to meters and then to string
      width_cm: item.height   // converting cm to meters and then to string
    }));
    const doubleBedSizesArray = doubleBedSizesRef.current.map(item => ({
      length_cm: item.length, // converting cm to meters and then to string
      width_cm: item.height   // converting cm to meters and then to string
    }));

    const catDescription = [
      { "category": "Wasser", "category_description": categoryDescRef.current.Wasser, "technical_data": { "waste_water_ltr": (technicalValuesRef.current.wateWater !== null && technicalValuesRef.current.wateWater !== "0") ? technicalValuesRef.current.wateWater : '', "fresh_water_ltr": (technicalValuesRef.current.freshWater !== null && technicalValuesRef.current.freshWater !== "0") ? technicalValuesRef.current.freshWater : '' } },
      { "category": "Schlafen", "category_description": categoryDescRef.current.Schlafen, "technical_data": { "single": singleBedSizesArray, "double": doubleBedSizesArray } },
      { "category": "Strom", "category_description": categoryDescRef.current.Strom, "technical_data": { "sockets_230v": (technicalValuesRef.current.socket1 !== null && technicalValuesRef.current.socket1 !== "0") ? technicalValuesRef.current.socket1 : '', "sockets_12v": (technicalValuesRef.current.socket2 !== null && technicalValuesRef.current.socket2 !== "0") ? technicalValuesRef.current.socket2 : '', "connections_usb_c": (technicalValuesRef.current.usbports !== null && technicalValuesRef.current.usbports !== "0") ? technicalValuesRef.current.usbports : '', "solar_power_w": (technicalValuesRef.current.solarpannel !== null && technicalValuesRef.current.solarpannel !== "0") ? technicalValuesRef.current.solarpannel : '' } },
      { "category": "Außen", "category_description": categoryDescRef.current.Außen, "technical_data": {} },
      { "category": "Wohnen", "category_description": categoryDescRef.current.Wohnen, "technical_data": {} },
      { "category": "Küche", "category_description": categoryDescRef.current.Küche, "technical_data": {} },
      { "category": "Dach", "category_description": categoryDescRef.current.Dach, "technical_data": {} },
      { "category": "Keller", "category_description": categoryDescRef.current.Keller, "technical_data": {} },
      { "category": "Gas", "category_description": categoryDescRef.current.Gas, "technical_data": {} },
      { "category": "Heizung/Klimaanlage", "category_description": categoryDescRef.current['Heizung/Klimaanlage'], "technical_data": {} },
    ]
    form.append('vehicle_ad_overview', addDetails ? addDetails.vehicle_ad_overview_data.id : '')
    form.append('technical_data_description', techincalDescriptionRef.current)
    form.append('technical_data_category_descriptions', JSON.stringify(catDescription))
    const formDataObject = {};
    form.forEach((value, key) => {
      formDataObject[key] = value;
    });

    console.log('formDataObject', formDataObject);
    axios.defaults.headers.common["Accept"] = "application/json";
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
    await axios.put(url, form, { headers: { 'Authorization': token } })
      .then((response) => {
        setLoading(false)
        console.log('response', response);
        if (response?.data.status === 1) {
          callAddDetailApi(addDetails.vehicle_ad_overview_data.id, btnAction)
          // if (btnAction == 'next') {
          //   onNextPress()
          // } else if (btnAction == 'preview') {
          //   openNewTab()
          // } else if (btnAction == 'back') {
          //   onBackPress()
          // } else if (btnAction == 'tabchange') {
          //   onClick()
          // }
        } else {
          console.log('response', response);
          setShowAlert(true)
          setError(response.data.message)
        }
      })
      .catch(function (error) {
        setLoading(false)
        console.log('error', error);
        if (error.response) {
          setShowAlert(true)
          setError(error.response.data.message)
        }
      });
  }


  const callAddDetailApi = async (addId, btnAction) => {
    setLoading(true)
    const url = `${URLS.BASE_URL}${URLS.FULL_ADD_DETAILS}${addId}`;
    await axios.get(url, { headers: { 'Authorization': token } })
      .then((response) => {
        console.log('response', response);
        setLoading(false)
        if (response?.data.status === 1) {
          setAddDetails(response.data)
          let technicalobject = response.data.vehicle_ad_feature_technical_data
          if (!isEmptyObject(technicalobject)) {
            setTechDescription(technicalobject.technical_data_description)
            setTechnicalValues({
              wateWater: technicalobject.technical_data_category_descriptions[0].technical_data.water_specifications.waste_water_ltr !== 0 ? technicalobject.technical_data_category_descriptions[0].technical_data.water_specifications.waste_water_ltr : '',
              freshWater: technicalobject.technical_data_category_descriptions[0].technical_data.water_specifications.fresh_water_ltr !== 0 ? technicalobject.technical_data_category_descriptions[0].technical_data.water_specifications.fresh_water_ltr : '',
              socket1: technicalobject.technical_data_category_descriptions[2].technical_data.electricity_specifications.sockets_230v !== 0 ? technicalobject.technical_data_category_descriptions[2].technical_data.electricity_specifications.sockets_230v : '',
              socket2: technicalobject.technical_data_category_descriptions[2].technical_data.electricity_specifications.sockets_12v !== 0 ? technicalobject.technical_data_category_descriptions[2].technical_data.electricity_specifications.sockets_12v : '',
              usbports: technicalobject.technical_data_category_descriptions[2].technical_data.electricity_specifications.connections_usb_c !== 0 ? technicalobject.technical_data_category_descriptions[2].technical_data.electricity_specifications.connections_usb_c : '',
              solarpannel: technicalobject.technical_data_category_descriptions[2].technical_data.electricity_specifications.solar_power_w !== 0 ? technicalobject.technical_data_category_descriptions[2].technical_data.electricity_specifications.solar_power_w : '',
            })

            const object = {
              Wasser: technicalobject.technical_data_category_descriptions[0].category_description,
              Schlafen: technicalobject.technical_data_category_descriptions[1].category_description,
              Strom: technicalobject.technical_data_category_descriptions[2].category_description,
              Außen: technicalobject.technical_data_category_descriptions[3].category_description,
              Wohnen: technicalobject.technical_data_category_descriptions[4].category_description,
              Küche: technicalobject.technical_data_category_descriptions[5].category_description,
              Dach: technicalobject.technical_data_category_descriptions[6].category_description,
              Keller: technicalobject.technical_data_category_descriptions[7].category_description,
              Gas: technicalobject.technical_data_category_descriptions[8].category_description,
              "Heizung/Klimaanlage": technicalobject.technical_data_category_descriptions[9].category_description,
            }
            console.log('waterdec', object)
            setCatDesc(object)

            setSinlgeBedCount(technicalobject.technical_data_category_descriptions[1].technical_data.single_bed_specifications.length)
            setDoubleBedCount(technicalobject.technical_data_category_descriptions[1].technical_data.double_bed_specifications.length)
            const transformedSingleBedArray = technicalobject.technical_data_category_descriptions[1].technical_data.single_bed_specifications.map(item => ({
              length: item.length_cm, // converting cm to meters and then to string
              height: item.width_cm   // converting cm to meters and then to string
            }));
            setSingleBedSizes(transformedSingleBedArray)
            const transformedDoubleBedArray = technicalobject.technical_data_category_descriptions[1].technical_data.double_bed_specifications.map(item => ({
              length: item.length_cm, // converting cm to meters and then to string
              height: item.width_cm   // converting cm to meters and then to string
            }));
            setDoubleBedSizes(transformedDoubleBedArray)
          }
          if (btnAction == 'next') {
            onNextPress()
          } else if (btnAction == 'preview') {
            openNewTab()
          } else if (btnAction == 'back') {
            onBackPress()
          } else if (btnAction == 'tabchange') {
            onClick()
          }
        } else {
          console.log('response', response);
          setShowAlert(true)
          setError(response.data.message)
        }
      })
      .catch(function (error) {
        console.log('error', error);
        setLoading(false)
        if (error.response) {
          setShowAlert(true)
          setError(error.response.data.message)
        }
      });
  }

  const handleDoubleBedSizeChange = (index, key, value) => {
    const updatedBedSizes = [...doubleBedSizes];
    if (!updatedBedSizes[index]) {
      updatedBedSizes[index] = {}; // Initialize as an empty object if it doesn't exist
    }
    updatedBedSizes[index][key] = value;
    setDoubleBedSizes(updatedBedSizes);
    console.log('updatedBedSizes', updatedBedSizes)
  };

  const handleSingleBedSizeChange = (index, key, value) => {
    const updatedBedSizes = [...singleBedSizes];
    if (!updatedBedSizes[index]) {
      updatedBedSizes[index] = {}; // Initialize as an empty object if it doesn't exist
    }
    updatedBedSizes[index][key] = value;
    setSingleBedSizes(updatedBedSizes);
    console.log('updatedBedSizes', updatedBedSizes)
  };

  const renderdoublesizesInputs = () => {
    let bedsizesInputs = [];
    for (let i = 0; i < doublebedcount; i++) {
      bedsizesInputs.push(
        <Row key={i} style={{ marginTop: 5 }}>
          <Col md={3} lg={3} xl={4} sm={4} xs={4}>
            <input value={doubleBedSizes[i] ? doubleBedSizes[i].length : ''} className='numberinput' type='number' min={0} maxLength={5} onChange={(e) => handleDoubleBedSizeChange(i, 'length', e.target.value)}></input>
          </Col>
          <Col md={1} lg={1} xl={1} sm={1} xs={1}>
            <RxCross1 size={10} />
          </Col>
          <Col md={3} lg={3} xl={4} sm={4} xs={4} >
            <input value={doubleBedSizes[i] ? doubleBedSizes[i].height : ''} className='numberinput' type='number' min={0} maxLength={5} onChange={(e) => handleDoubleBedSizeChange(i, 'height', e.target.value)}></input>
          </Col>
          <Col md={3} lg={3} xl={2} sm={2} xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <span style={{ fontSize: 12 }}> Zontimeter</span>
          </Col>
        </Row>
      );
    }
    return bedsizesInputs;
  };


  const rendersinglebedsizesInputs = () => {
    let bedsizesInputs = [];
    for (let i = 0; i < sinlgeBedCount; i++) {
      bedsizesInputs.push(
        <Row key={i} style={{ marginTop: 5 }}>
          <Col md={3} lg={3} xl={4} sm={4} xs={4}>
            <input value={singleBedSizes[i] ? singleBedSizes[i].length : ''} className='numberinput' type='number' min={0} maxLength={5} onChange={(e) => handleSingleBedSizeChange(i, 'length', e.target.value)}></input>
          </Col>
          <Col md={1} lg={1} xl={1} sm={1} xs={1}>
            <RxCross1 size={10} />
          </Col>
          <Col md={3} lg={3} xl={4} sm={4} xs={4} >
            <input value={singleBedSizes[i] ? singleBedSizes[i].height : ''} className='numberinput' type='number' min={0} maxLength={5} onChange={(e) => handleSingleBedSizeChange(i, 'height', e.target.value)}></input>
          </Col>
          <Col md={3} lg={3} xl={2} sm={2} xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <span style={{ fontSize: 12 }}> Zontimeter</span>
          </Col>
        </Row>
      );
    }
    return bedsizesInputs;
  };
  return (
    <div>
      {loading && (
        <div className="loader">
          <ClipLoader
            color={"#123abc"}
            loading={loading}
            css={override}
            size={50}
          />
        </div>
      )}
      <Row>
        <Col md={9}>
          <ReactQuill
            theme="snow"
            modules={modules}
            value={techincalDescription}
            className='quill_adds'
            formats={formats}
            placeholder={'Beschreibung'}
            onChange={handleTechDesChange}
          />
          <div style={{ marginTop: 20 }}>
            {sections.map((section, sectionIndex) => {
              return (
                <Card key={sectionIndex}>
                  <Card.Header>
                    <button className='section_btn' onClick={() => toggleSection(sectionIndex)}>
                      <span style={{ marginRight: 20 }}>{section.title}</span>
                      {section.isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
                    </button>
                  </Card.Header>
                  <Collapse in={section.isOpen}>
                    <Card.Body>
                      {sectionIndex === 0 ?
                        <Row>
                          <Col md={8} lg={6} xl={5} xs={12} sm={10}>
                            <Row style={{ display: 'flex', alignItems: 'center', }}>
                              <Col md={5} lg={5} xl={4} xs={4}>
                                <input className='numberinput' type='number' min={0} maxLength={5} value={technicalValues.wateWater} onChange={e => handleInputChange('wateWater', e.target.value)}></input>
                              </Col>
                              <Col md={7} lg={7} xl={8} xs={7}>
                                <span>Liter Abwassertank</span>
                              </Col>
                            </Row>
                            <Row style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
                              <Col md={5} lg={5} xl={4} xs={4}>
                                <input className='numberinput' type='number' min={0} maxLength={5} value={technicalValues.freshWater} onChange={e => handleInputChange('freshWater', e.target.value)}></input>
                              </Col>
                              <Col md={7} lg={7} xl={8} xs={7}>
                                <span>Liter Frischwassertank</span>
                              </Col>
                            </Row>
                          </Col>
                          <Col md={12} lg={12} xl={7} xs={12} style={{ marginTop: 10 }}>
                            <ReactQuill
                              theme="snow"
                              modules={modules}
                              className='quill_adds'
                              formats={formats}
                              value={categoryDesc.Wasser}
                              placeholder={'Wasser'}
                              onChange={(content) => handleDescChange(content, section.title)}
                            />
                          </Col>
                        </Row>
                        : sectionIndex == 1 ?
                          <Row>
                            <Col md={12} lg={12} xxl={7} xl={10} xs={12} sm={12} style={{ marginBottom: 10 }}>
                              <Row>
                                <Col md={10} xxl={6} xl={6} lg={9} xs={8} sm={8} style={{ marginBottom: 10 }}>
                                  <Row style={{ display: 'flex', alignItems: 'center', }}>
                                    <Col md={3} lg={3} xl={4} xs={6} sm={4}>
                                      <input className='numberinput' type='number' min={0} max={5} value={doublebedcount} onChange={handleDoubleBedChange}></input>
                                    </Col>
                                    <Col md={7} lg={8} xl={8} xs={6} sm={6} >
                                      <span>Doppelbett</span>
                                    </Col>
                                  </Row>
                                  {renderdoublesizesInputs()}
                                </Col>
                                <Col md={10} xxl={6} xl={6} lg={9} xs={8} sm={8}>
                                  <Row style={{ display: 'flex', alignItems: 'center', }}>
                                    <Col md={3} lg={3} xl={4} xs={6} sm={4}>
                                      <input className='numberinput' type='number' min={0} max={5} value={sinlgeBedCount} onChange={handleSingleBedChange}></input>
                                    </Col>
                                    <Col md={7} lg={8} xl={8} xs={6} sm={6}  >
                                      <span>Einzelbett</span>
                                    </Col>
                                  </Row>
                                  {rendersinglebedsizesInputs()}
                                </Col>

                              </Row>

                            </Col>

                            <Col md={12} xxl={5} lg={12} xl={12} xs={12} sm={10} >
                              <ReactQuill
                                theme="snow"
                                modules={modules}
                                className='quill_adds'
                                formats={formats}
                                value={categoryDesc.Schlafen}
                                placeholder={'Schlafen'}
                                onChange={(content) => handleDescChange(content, section.title)}
                              />
                            </Col>
                            {(formdataError.bedSize) ? (
                              <div style={{ width: '100%', display: 'flex', alignItems: 'flex-start', flexDirection: 'row' }}>
                                <div><FiAlertTriangle color='red' /></div>
                                <div style={{ marginTop: 2.5 }}><div className="error-message">{formdataError.bedSize}</div></div>
                              </div>) : null}

                          </Row>
                          : sectionIndex == 2 ?
                            <Row>
                              <Col md={10} xxl={6} xl={6} lg={8} sm={12} xs={12} style={{ marginBottom: 10 }}>
                                <Row style={{ display: 'flex', alignItems: 'center', }}>
                                  <Col md={3} sm={3} xs={3} lg={3} xl={3}>
                                    <input className='numberinput' type='number' min={0} maxLength={5} value={technicalValues.socket1} onChange={e => handleStromInputChange('socket1', e.target.value)}></input>
                                  </Col>
                                  <Col md={1} sm={1} xs={1} lg={1} xl={1} style={{ marginRight: 10, marginTop: 5 }}>
                                    <RxCross1 size={10} />
                                  </Col>
                                  <Col md={5} sm={4} xs={4} lg={5} xl={4}>
                                    <span>230v Steckdosen</span>
                                  </Col>
                                </Row>
                                <Row style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
                                  <Col md={3} sm={3} xs={3} lg={3} xl={3}>
                                    <input className='numberinput' type='number' min={0} maxLength={5} value={technicalValues.socket2} onChange={e => handleStromInputChange('socket2', e.target.value)}></input>
                                  </Col>
                                  <Col md={1} sm={1} xs={1} lg={1} xl={1} style={{ marginRight: 10, marginTop: 5 }}>
                                    <RxCross1 size={10} />
                                  </Col>
                                  <Col md={5} sm={4} xs={4} lg={5} xl={4}>
                                    <span>12v Steckdosen</span>
                                  </Col>
                                </Row>
                                <Row style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
                                  <Col md={3} sm={3} xs={3} lg={3} xl={3}>
                                    <input className='numberinput' type='number' min={0} maxLength={5} value={technicalValues.usbports} onChange={e => handleStromInputChange('usbports', e.target.value)}></input>
                                  </Col>
                                  <Col md={1} sm={1} xs={1} lg={1} xl={1} style={{ marginRight: 10, marginTop: 5 }}>
                                    <RxCross1 size={10} />
                                  </Col>
                                  <Col md={5} sm={4} xs={4} lg={5} xl={4}>
                                    <span>USB-C Anschlüsse</span>
                                  </Col>
                                </Row>
                                <Row style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
                                  <Col md={4} sm={4} xs={4} lg={4} xl={4} xxl={4}>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                      <input className='numberinput' type='number' min={0} maxLength={5} value={technicalValues.solarpannel} onChange={e => handleStromInputChange('solarpannel', e.target.value)}></input>
                                      <span style={{ marginLeft: 4, width: 40 }}>w</span>
                                    </div>
                                  </Col>
                                  <Col md={1} sm={1} xs={1} lg={1} xl={1} xxl={1} style={{ marginRight: 10, marginTop: 5 }}>
                                    <RxCross1 size={10} />
                                  </Col>
                                  <Col md={5} sm={4} xs={4} lg={5} xl={4} xxl={4}>
                                    <span>Solar-Leistung</span>
                                  </Col>
                                </Row>
                              </Col>
                              <Col md={12} xxl={6} xl={6} lg={12} sm={12} xs={12}>
                                <ReactQuill
                                  theme="snow"
                                  modules={modules}
                                  className='quill_adds'
                                  formats={formats}
                                  value={categoryDesc.Strom}

                                  placeholder={'Strom'}
                                  onChange={(content) => handleDescChange(content, section.title)}
                                />
                              </Col>
                            </Row>
                            :
                            <div>
                              <ReactQuill
                                theme="snow"
                                modules={modules}
                                className='quill_adds'
                                formats={formats}
                                placeholder={section.title}
                                value={categoryDesc[section.title] || ''}
                                onChange={(content) => handleDescChange(content, section.title)}
                              />
                            </div>
                      }
                    </Card.Body>
                  </Collapse>
                </Card>
              )
            }
            )}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20, marginBottom: 20 }}>
            <button className="add_back_btn" onClick={handleBackClick}>{Strings.back}</button>
            <button className="next-btn" onClick={() => {
              handleNextClick('next')
            }}>{Strings.next}</button>
            <button className="add_preview_btn" onClick={() => {
              handleNextClick('preview')

            }} >{Strings.preview}<IoIosDesktop style={{ marginLeft: 5 }} /></button>
          </div>
        </Col>
        <Col md={3} />
      </Row>
      {showalert &&
        <AlertComponent message={error} onClose={() => { setShowAlert(false) }}></AlertComponent>
      }
    </div >
  )
}

export default AdTechicleDetails