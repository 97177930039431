import React, { useEffect, useContext, useState } from "react";
import "../Home/styles.css";
import "../../app.css";
import { BsArrowRight } from "react-icons/bs";
import Header from "../../../src/Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { Col, Row } from "react-bootstrap";
import { Strings, URLS, getCookieValue, instaAccessToken, instaUserId } from "../../utilities/constants";
import { DataContext } from "../../utilities/DataContext";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";
import { FaSearch } from "react-icons/fa";
import CountryFlag from '../Search/CountryFlag';
import { MdEventSeat } from "react-icons/md";
import { MdBed } from "react-icons/md";
import { FaCircleCheck } from "react-icons/fa6";
import { trackPageView } from "../../hooks/analytics";


const portalId = "143650051";
const formId = "c660dded-f3c4-45d2-b813-15bb0fff03d6";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;


const HomeScreen = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const [addsList, setAddsList] = useState([])
  const [companiesData, setCompaniesData] = useState([]);
  const [blogsData, setBlogsData] = useState([])
  const [expandedStates, setExpandedStates] = useState(new Array(blogsData.length).fill(false));
  const { setBusinessForm1data, setBusinessForm2data, token, setIsLoggedIn, setToken, setUserData, setCompanyInfo, setUserType, userData, setChatData } = useContext(DataContext);
  const [loading, setLoading] = useState(false);
  const [instaPosts, setInstaPosts] = useState([])
  const [color, setColor] = useState('green')
  const [message, setMessage] = useState('');

  useEffect(() => {
    document.title = 'campervanspot.com - Der Marktplatz für hochwertige Camper Vans und Wohnmobile';
    const consentCookie = getCookieValue('cookie_consent_level');
    if (consentCookie) {
      const consentStatus = JSON.parse(decodeURIComponent(consentCookie));
      console.log('consentStatus', consentStatus.tracking);
      if (consentStatus.tracking) {
        trackPageView(window.location.pathname, 'campervanspot.com - Der Marktplatz für hochwertige Camper Vans und Wohnmobile')
      }
    }
  }, [])


  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const token = searchParams.get('token');
    const uuid = searchParams.get('uuid');
    const usertype = searchParams.get('user_type');

    console.log('token', token);
    console.log('uuid', uuid);
    if (uuid != null && token != null && usertype == 'private-user') {
      setUserType(usertype)
      getverifyStatus(uuid, token)
    }

    if (userData && userData.id) {
      getChatList()
    }

    callAddsListApi()
    getCompanies();
    fetchBlogsData()
    fetchInstMedia()

    const createHubSpotForm = () => {
      createHubSpotForm1();
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: portalId,
          formId: formId,
          target: "#hubspotFormContainer",
          onFormReady: function () {
            // This function will be called when the form is ready
            myFunction();
          },
          onFormSubmit: function ($form) {
            // This function will be called when the form is submitted
            console.log("Form submitted!");
            // Now, you can wait for the success message iframe to appear and then access its content
            setTimeout(getSuccessMessageIframeContent, 1000); // Adjust the timeout as needed
          }
        });
      }
    };


    const getSuccessMessageIframeContent = () => {
      const successMessageIframe = document.querySelector("iframe.hs-form-iframe");

      if (successMessageIframe) {
        const successMessageIframeDoc = successMessageIframe.contentWindow.document;
        console.log('successMessageIframeDoc', successMessageIframeDoc)
        const pTagElement = successMessageIframeDoc.querySelector('p');
        console.log(pTagElement.textContent);
        if (pTagElement) {
          pTagElement.textContent = 'Vielen Dank für die Einsendung, wir halten Sie jetzt auf dem Laufenden über unsere weitere Entwicklung.';
          pTagElement.style.color = '#52c41a';
          pTagElement.style.fontWeight = 400;
          pTagElement.style.fontSize = 20
        }
      } else {
        console.error("Success message iframe not found");
      }
    };

    const createHubSpotForm1 = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: portalId,
          formId: formId,
          target: "#hubspotFormContainer1",
          onFormReady: function () {
            // This function will be called when the form is ready
            myFunction1();
          },
        });
      }
    };

    // Check if HubSpot forms script is loaded, then create the form
    if (window.hbspt && window.hbspt.forms) {
      createHubSpotForm();
    } else {
      // If not loaded, wait for the script to load
      const script = document.createElement("script");
      script.src = "https://js.hsforms.net/forms/v2.js";
      script.charset = "utf-8";
      script.async = true;
      script.onload = createHubSpotForm;
      document.body.appendChild(script);
    }

    // Clean up
    return () => {
      // Remove the HubSpot form container when the component unmounts
      const hubspotFormContainer = document.getElementById(
        "hubspotFormContainer"
      );
      if (hubspotFormContainer) {
        hubspotFormContainer.innerHTML = "";
      }
      const hubspotFormContainer1 = document.getElementById(
        "hubspotFormContainer1"
      );
      if (hubspotFormContainer1) {
        hubspotFormContainer1.innerHTML = "";
      }
    };
  }, []);


  const getverifyStatus = async (uuid, token) => {
    const url = `${URLS.BASE_URL}${URLS.VERIFY_EMAIL}${uuid}`;
    axios.defaults.headers.common["Accept"] = "application/json";
    axios.defaults.headers.common["Content-Type"] = "application/json";
    await axios.get(url, { headers: { 'Authorization': token } })
      .then((response) => {
        if (response?.data.status === 1) {
          console.log("response", response);
          // if (response?.data.message === 'Benutzerkonto bereits verifiziert') {
          setMessage(response?.data.message)
          const token = response?.data.token
          const userData = response?.data.user
          const compnayinfo = response?.data.private_user_profile_information
          setUserData(userData);
          setCompanyInfo(compnayinfo)
          setIsLoggedIn(true)
          setToken(token)
          if (response?.data.message == 'Benutzerkonto bereits verifiziert') {
            setMessage('')

          } else {
            setColor('green')
          }
        } else {
          setIsLoggedIn(false)
          setMessage(response?.data.message)
        }
      })
  }

  const myFunction1 = () => {
    var iframe = document.querySelector("iframe.hs-form-iframe");

    if (iframe) {
      console.log("iframe", iframe);
      var iframeDoc = iframe.contentWindow.document;
      var actionsDiv = iframeDoc.querySelector(".actions");
      if (actionsDiv) {
        actionsDiv.style.justifyContent = "flex-end";
        actionsDiv.style.display = "flex";
        actionsDiv.style.padding = 0;
      } else {
        console.error("Element with class 'actions' not found in the iframe");
      }
      var input = iframeDoc.querySelector("input.hs-button.primary.large");

      if (input) {
        input.style.backgroundColor = "black"; // Example style change
        input.style.borderColor = "black";
        input.style.color = 'white'
        // Add more style changes here as needed
      }

      const inputfirstname = iframeDoc.getElementById(
        "firstname-c660dded-f3c4-45d2-b813-15bb0fff03d6"
      );

      if (inputfirstname) {
        inputfirstname.style.backgroundColor = "transparent"
        inputfirstname.style.color = "black"
        inputfirstname.style.borderColor = 'black'
      }

      const inputlastname = iframeDoc.getElementById(
        "lastname-c660dded-f3c4-45d2-b813-15bb0fff03d6"
      );

      if (inputlastname) {
        inputlastname.style.backgroundColor = "transparent"
        inputlastname.style.color = "black"
        inputlastname.style.borderColor = 'black'
      }

      const inputemail = iframeDoc.getElementById(
        "email-c660dded-f3c4-45d2-b813-15bb0fff03d6"
      );

      if (inputemail) {
        inputemail.style.backgroundColor = "transparent"
        inputemail.style.color = "black"
        inputemail.style.borderColor = 'black'
      }
    } else {
      console.error("Iframe element with ID 'myFrame' not found");
    }
  };

  const myFunction = () => {

    var iframe = document.querySelector("iframe.hs-form-iframe");

    if (iframe) {
      console.log('iframe', iframe)
      var iframeDoc = iframe.contentWindow.document;
      var actionsDiv = iframeDoc.querySelector(".actions");
      if (actionsDiv) {
        actionsDiv.style.justifyContent = "flex-end";
        actionsDiv.style.display = "flex";
        actionsDiv.style.padding = 0;
      } else {
        console.error("Element with class 'actions' not found in the iframe");
      }
      const inputfirstname = iframeDoc.getElementById(
        "firstname-c660dded-f3c4-45d2-b813-15bb0fff03d6"
      );

      const inputlastname = iframeDoc.getElementById(
        "lastname-c660dded-f3c4-45d2-b813-15bb0fff03d6"
      );

      const inputemail = iframeDoc.getElementById(
        "email-c660dded-f3c4-45d2-b813-15bb0fff03d6"
      );

      const labelfirstname = iframeDoc.getElementById(
        "label-firstname-c660dded-f3c4-45d2-b813-15bb0fff03d6"
      );
      const labellastname = iframeDoc.getElementById(
        "label-lastname-c660dded-f3c4-45d2-b813-15bb0fff03d6"
      );
      const labelemail = iframeDoc.getElementById(
        "label-email-c660dded-f3c4-45d2-b813-15bb0fff03d6"
      );
      var input = iframeDoc.querySelector("input.hs-button.primary.large");

      if (input) {
        input.style.backgroundColor = "transparent"; // Example style change
        input.style.borderColor = "white";
        // Add more style changes here as needed
      }

      if (labelfirstname) {
        labelfirstname.style.display = "none";
      }

      if (inputfirstname) {
        inputfirstname.style.backgroundColor = "transparent";
        inputfirstname.style.color = "white";
      }
      if (labellastname) {
        labellastname.style.display = "none";
      }
      if (inputlastname) {
        inputlastname.style.backgroundColor = "transparent";
        inputlastname.style.color = "white";
      }
      if (labelemail) {
        labelemail.style.display = "none";
      }
      if (inputemail) {
        inputemail.style.backgroundColor = "transparent";
        inputemail.style.color = "white";
      }

    } else {
      console.error("Iframe element with ID 'myFrame' not found");
    }
  };

  const toggleExpand = (index) => {
    const newExpandedStates = [...expandedStates];
    newExpandedStates[index] = !newExpandedStates[index];
    setExpandedStates(newExpandedStates);
  }
  const nextClicked = (company) => {
    setBusinessForm1data({});
    setBusinessForm2data({});
    const formattedCompanyName = formatCompanyName(company.company_name);
    navigate(`/businesspage/${formattedCompanyName}`, { state: { id: company.id } });
  };

  const blogClicked = (blog) => {
    window.open(blog.link, '_blank', 'noopener,noreferrer');
  }

  const instaClicked = (link) => {
    window.open(link, '_blank', 'noopener,noreferrer');
  }
  const formatCompanyName = (name) => {
    return name
      .toLowerCase()                          // Convert to lowercase
      .replace(/[^a-z0-9]+/g, '-')            // Replace non-alphanumeric characters with a hyphen
      .replace(/^-+|-+$/g, '');               // Remove leading or trailing hyphens
  };



  const moreClicked = (company) => {
    navigate("/businesses");
  };

  const moreAdsClicked = () => {
    navigate("/search");
  };

  const searchClicked = (company) => {
    navigate("/search");
  };

  const getChatList = async () => {
    const url = `${URLS.BASE_URL}${URLS.CHAT_LIST}`;
    await axios.get(url, { headers: { Authorization: token } })
      .then((response) => {
        setLoading(false);
        if (response?.data.status === 1) {
          if (response?.data.data.length > 0) {
            setChatData(response?.data.data);
          }
        } else {
          console.log('response', response);
        }
      })
      .catch(function (error) {
        console.log('error', error);
        if (error.response) {
          // Handle error response
        }
      });
  };


  const getCompanies = async () => {
    setLoading(true);
    const url = `${URLS.BASE_URL}${URLS.ALL_COMPANIES}?order=descending`;
    axios.defaults.headers.common["Accept"] = "application/json";
    axios.defaults.headers.common["Content-Type"] = "application/json";
    await axios
      .get(url)
      .then((response) => {
        setLoading(false);
        if (response?.data.status === 1) {
          const data = response?.data.data;
          const filteredCompanies = data.filter(company => company.companybusinesspage !== null);
          setCompaniesData(filteredCompanies);
        } else {
          alert("", response.data.message);
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  const fetchBlogsData = async () => {

    fetch('https://app2.campervanspot.com/blog/rss.xml')
      .then(response => response.text())
      .then(data => {
        // Parse the XML data
        const parser = new DOMParser();
        const xml = parser.parseFromString(data, 'text/xml');
        const itemNodes = xml.getElementsByTagName('item');

        // Convert XML nodes to JavaScript objects
        const itemsArray = Array.from(itemNodes).map(item => {
          const descriptionNode = new DOMParser().parseFromString(item.getElementsByTagName('description')[0].textContent, 'text/html');
          const imgSrc = descriptionNode.querySelector('img')?.src;
          const pContent = descriptionNode.querySelector('p')?.textContent;
          const linkHref = descriptionNode.querySelector('a')?.href;

          return {
            title: item.getElementsByTagName('title')[0].textContent,
            link: item.getElementsByTagName('link')[0].textContent,
            pubDate: item.getElementsByTagName('pubDate')[0].textContent,
            category: item.getElementsByTagName('category')[0].textContent,
            author: item.getElementsByTagName('author')[0].textContent,
            imgSrc,
            pContent,
            linkHref,
          };
        });
        console.log('setBlogsData', itemsArray)
        setBlogsData(itemsArray);
      }).catch(error => console.error('Error fetching or parsing XML:', error));

  }

  const fetchInstMedia = async () => {
    if (!instaUserId || !instaAccessToken) {
      console.log('userId or accessToken is undfined:', { instaUserId, instaAccessToken })
      return
    }
    const instaurl = `https://graph.instagram.com/${instaUserId}/media?access_token=${instaAccessToken}`
    const response = await fetch(instaurl)
    const json = (await response.json()).data
    console.log('json', json)
    const fetcheditems = []
    if (json) {
      for (let i = 0; i < json.length && i < 9; i++) {
        const item = json[i]
        const itemId = item.id
        const instaItem = await fetchInstaimages(itemId)
        if (fetcheditems.length < 6) {
          if (instaItem.mediaType !== "VIDEO") {
            fetcheditems.push(instaItem)
          }
        }
      }
      console.log('fetcheditems', fetcheditems)
      setInstaPosts(fetcheditems)
    }

  }

  const fetchInstaimages = async (id) => {
    const mediaurl = `https://graph.instagram.com/${id}?access_token=${instaAccessToken}&fields=media_url,permalink,media_type`
    const response = await fetch(mediaurl)
    const json = (await response.json())
    const item = {
      'permalink': json.permalink,
      'mediaUrl': json.media_url,
      'mediaType': json.media_type
    }
    return item
  }

  const adClicked = (item) => {
    // navigate('/adcreate', { state: { id: item.id } })
    const componentPage = `/ad/${item.id}`;
    navigate(componentPage)
  }

  const buildUrlWithParams = (baseUrl, params) => {
    const query = Object.keys(params)
      .map(key => {
        const value = params[key];
        if (Array.isArray(value)) {
          return `${key}=${encodeURIComponent(JSON.stringify(value))}`;
        }
        return `${key}=${encodeURIComponent(value)}`;
      })
      .join('&');
    return `${baseUrl}?${query}`;
  };

  const callAddsListApi = async () => {
    setLoading(true)

    const url = buildUrlWithParams(`${URLS.BASE_URL}${URLS.SEARCH_ADDS}`, {
      search: '',
      chasis_brand: '',
      conversion_brand: '',
      min_mileage: '',
      max_mileage: '',
      min_price: '',
      max_price: '',
      type: '',
      total_no_of_beds: ''
    });

    // const url = `${URLS.BASE_URL}${URLS.SEARCH_ADDS}search=${formData.vehiclename}&chasis_brand=${formData.chasisbrand.join(',')}&conversion_brand=${formData.conversionbrnd.join(',')}&min_mileage=${formData.milagerangefrom}&max_mileage=${formData.milagerangeto}&min_price=${formData.pricerangefrom}&max_price=${formData.pricerangeto}&type=${formData.type.join(',')}`;
    console.log('adurl', url)
    await axios.get(url, { headers: { 'Authorization': token } })
      .then((response) => {
        setLoading(false)
        if (response?.data.status === 1) {
          const data = response.data.data
          if (data.length > 3) {
            const slicedArray = data.slice(0, 3)
            setAddsList(slicedArray)
          } else {
            setAddsList(data)
          }

        } else {
          console.log('response', response);
          alert(response.data.message);
        }
      })
      .catch(function (error) {
        setLoading(false)
        console.log('error', error);
        alert(error.response.data.message);
      });
  }

  const formatMilageNumber = (value) => {
    return new Intl.NumberFormat('de-DE').format(value);
  }

  const formatCurrency = (value, currency) => {
    console.log('currency', currency)
    if (value !== '' && currency !== '') {
      let locale = 'de-DE'; // German locale for both EUR and CHF
      let formattedValue = new Intl.NumberFormat(locale, {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value);
      return `${formattedValue} ${currency.toUpperCase()}`;
    } else {
      return ''
    }

  };

  const InstaGrid = () => {
    return (
      <div className="gridStyle">
        {instaPosts.map((item, index) => (
          <div key={index} className="itemContainer" onClick={() => { instaClicked(item.permalink) }}>
            <img src={item.mediaUrl} className="itemStyle" alt={`Post ${index + 1}`} />
          </div>
        ))}
      </div>
    );
  }

  return (
    <>

      <Header />
      <div style={{ height: "100vh" }}>
        {(message !== '') ?
          <div style={{ backgroundColor: color == 'green' ? 'lightgreen' : 'orange', paddingLeft: 20, paddingRight: 20, width: '100%', display: 'flex', justifyContent: 'space-between', zIndex: 5000, position: 'absolute', marginTop: 0, flexDirection: 'row' }}>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FaCircleCheck size={20} style={{ color: color == 'green' ? 'green' : 'red', marginRight: 10, marginLeft: 10 }} />
              <span style={{ color: color == 'green' ? 'green' : 'red' }}>{message}</span>
            </div>
            <span className="close-button-home" onClick={() => { setMessage('') }}>&times;</span>
          </div> : null
        }
        <div
          className='home-banner  home-dnd_area-module'
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + "/natureimage.jpeg"
              })`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="dashboard_overlay" />
          <div className="container">
            <div className='banner-content-wrapper'>
              <div>
                <div className="normaltextrow">
                  <div className="view_line" />
                  <div className="normaltextStyle">Camper & Vans</div>
                </div>
                <div>
                  {/* <Col md={12} sm={12} lg={12} xl={8} xs={12}> */}
                  <h6 className="mediumtextstyle">
                    Der Marktplatz für hochwertige Campervans und Wohnmobile
                  </h6>
                  <h2 className="boldTextStyle">Von Abenteurern Für Abenteurer</h2>
                  {/* </Col> */}
                </div>
                <div className="formdiv">
                  <span className="hubspotheader">
                    Bleiben Sie auf dem Laufenden!
                  </span>
                  <div
                    id="hubspotFormContainer"
                    style={{
                      marginTop: 20,
                      marginBottom: 20,
                    }}
                  ></div>
                  ;
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', position: 'absolute', paddingLeft: '4vw', paddingRight: '4vw' }}>
                  <div className="div-search">
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <button className="search-text1" style={{ backgroundColor: 'white', padding: '8px 40px 8px 40px' }}>Suche</button>
                      <button className="search-text1" style={{ backgroundColor: 'rgba(0,0,0,0.5)', padding: '8px 40px 8px 40px', color: 'white' }}>Filter</button>
                    </div>

                    <div className="shadow-div">
                      {/* <span className="search-text">Wir stehen kurz davor die Fahrzeugsuche zu starten!</span> */}
                      <div style={{ borderRadius: 5, width: '100%', height: 50, paddingLeft: '5vmin', display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={(e) => {
                        searchClicked()
                      }}>
                        <input disabled={true} placeholder='Was suchen Sie?' className='home_search_input' style={{ color: 'black', fontSize: 20, flex: 1, pointerEvents: 'none' }} onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                        }} />
                        <div style={{ padding: 15, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 2, paddingRight: '5vmin', paddingLeft: '5vmin' }}>
                          <FaSearch color='black' size={20} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: "flex", paddingTop: "10vh", flexDirection: "column" }}>
          <div className='cardcontainer'>
            <div className="heading">
              <h3 className="newbusiness_headingtext">
                {"Neueste"} <strong>{"Fahrzeug Anzeigen"}</strong>{" "}
              </h3>
              <div className="divider" />
            </div>
            {/* <div className='home-adcontainer' > */}
            <div className='section_cards'>
              {addsList.map((item, index) => (
                <div onClick={() => adClicked(item)} className='imagecard' style={{ backgroundImage: item.hero_image_url != null ? `url(${item.hero_image_url})` : null, alignItems: 'flex-end', display: 'flex', justifyContent: 'flex-end' }}>
                  {/* <div className='adoverlay' /> */}
                  <div className='adddata' style={{ width: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', paddingLeft: 20, width: '100%', paddingTop: 5, paddingBottom: 5 }}>
                      <CountryFlag countryCode={item.country_code} />
                      {/* <h1 className='adtitle' style={{ fontSize: 17, fontWeight: 400, color: 'white', marginBottom: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.vehicle_name !== '' ? item.vehicle_name : '--'}</h1> */}
                      <h1 style={{ fontSize: 17, fontWeight: 400, color: 'white', marginBottom: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.vehicle_name !== '' ? item.vehicle_name : '--'}</h1>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div>
                        <div>
                          <MdEventSeat color='white' />
                          <span className='addetails'>{item.no_of_seats !== null && item.no_of_seats !== 0 ? item.no_of_seats : '--'}</span>
                        </div>
                        <div>
                          <MdBed color='white' size={15} />
                          <span className='addetails'>{item.total_no_of_beds !== null && item.total_no_of_beds !== 0 ? item.total_no_of_beds : '--'}</span>
                        </div>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div>

                          <div md={3}>
                            <span className='addetails'>{item.year_of_manufacture !== null ? item.year_of_manufacture : '--'}</span> </div>
                          <div md={9}><span className='addetails'>{item.mileage_km !== null && item.mileage_km !== 0 ? `${formatMilageNumber(item.mileage_km)} km` : '--'}</span></div>
                        </div>
                        <div>
                          <span className='addetails'>{(item.price !== null && item.currency !== null) ? formatCurrency(item.price, item.currency) : '--'}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* </div> */}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 0,
            }}
          >
            <button className="btnmore" onClick={moreAdsClicked}>
              {Strings.all_btn}
            </button>
          </div>
        </div>
        <div style={{ display: "flex", paddingTop: "2vh", flexDirection: "column" }}>
          <div className='cardcontainer'>
            <div className="heading">
              <h3 className="newbusiness_headingtext">
                {"Neueste"} <strong>{"Camper Van Ausbauer"}</strong>{" "}
              </h3>
              <div className="divider" />
            </div>
            <div className="section_cards">
              {loading && (
                <div className="loader">
                  <ClipLoader
                    color={"#123abc"}
                    loading={loading}
                    css={override}
                    size={50}
                  />
                </div>
              )}
              {companiesData.map((company) => (
                <div
                  onClick={() => nextClicked(company)}
                  className="imagecard"
                  style={{
                    backgroundImage:
                      company.companybusinesspage != null &&
                        company.companybusinesspage.hero_image
                        ? `url(${company.companybusinesspage.hero_image})`
                        : `url(${process.env.PUBLIC_URL + "/placeholder.webp"})`,
                  }}
                >
                  <Row className="content row-card">
                    <Col>
                      <h4 className='title' style={{ marginRight: 10 }}>
                        {company.company_name}
                      </h4>
                      <h6 className='subtitle'> {`${company.city},
                                            ${company.country}`}</h6>
                      {/* <div className="title"><span>{company.company_name}</span></div> */}
                      {/* <div className="subtitle">
                      <span>
                        {`${company.city},
                                            ${company.country}`}</span>
                    </div> */}
                    </Col>
                    <Col md={3} xl={2} xs={2} lg={3} className="arrow-icon">
                      <BsArrowRight size={30} style={{ color: "white" }} />
                    </Col>
                  </Row>
                </div>
              ))}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 20,
            }}
          >
            <button className="btnmore" onClick={moreClicked}>
              {Strings.all_btn}
            </button>
          </div>
        </div>

        <div style={{ display: "flex", paddingTop: 10, flexDirection: "column" }}>
          <div className='cardcontainer'>
            <div className="heading">
              <h3 className="newbusiness_headingtext">
                {"Aktuelle"} <strong>{"Magazin Beiträge"}</strong>{" "}
              </h3>
              <div className="divider" />
            </div>
            <div className="section_cards" >
              {blogsData.map((blog, index) => (
                <div style={{ marginTop: 20 }}>
                  <div
                    onClick={() => blogClicked(blog)}
                    className="blogimagecard"
                    style={{
                      backgroundImage:
                        blog.imgSrc != null &&
                          blog.imgSrc
                          ? `url(${blog.imgSrc})`
                          : `url(${process.env.PUBLIC_URL + "/placeholder.webp"})`,
                    }}
                  >
                  </div>
                  <span className="cattext">{blog.category}</span>
                  <h6 style={{ marginTop: 10 }}>{blog.title}</h6>
                  <div className="expandable-text">
                    <p className={expandedStates[index] ? 'expanded' : 'collapsed'} dangerouslySetInnerHTML={{ __html: blog ? blog.pContent : '' }}></p>
                    {blog && blog.pContent !== '' && (
                      <button onClick={() => toggleExpand(index)} style={{ color: 'orange', textDecoration: 'underline' }}>
                        {expandedStates[index] ? 'Weniger sehen' : 'Mehr ansehen'}
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div style={{ display: "flex", paddingTop: 10, flexDirection: "column", paddingBottom: 30 }}>
          <div className='cardcontainer'>
            <div className="heading">
              <h3 className="newbusiness_headingtext">
                {"Top"} <strong>{"Instagram Posts"}</strong>{" "}
              </h3>
              <div className="divider" />
            </div>
            {instaPosts.length > 0 && <InstaGrid />}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default HomeScreen;
