import React, { useEffect } from 'react';
import '../SuccessPage/SucessPage.css'
import { useNavigate } from 'react-router-dom';
import '../../app.css'
import { trackPageView } from '../../hooks/analytics';
import { getCookieValue } from '../../utilities/constants';



const SuccessPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Erfolgreich Passwort geändert'
        const consentCookie = getCookieValue('cookie_consent_level');
        if (consentCookie) {
            const consentStatus = JSON.parse(decodeURIComponent(consentCookie));
            console.log('consentStatus', consentStatus.tracking);
            if (consentStatus.tracking) {
                trackPageView(window.location.pathname, 'Erfolgreich Passwort geändert')
            }
        }

    }, []);



    useEffect(() => {
        const timeout = setTimeout(() => {
            // Navigate to Screen B after 3 seconds
            navigate('/home');
        }, 5000);

        // Clean up the timeout when the component unmounts or when navigating away
        return () => {
            clearTimeout(timeout)
        };
    }, [navigate]);

    return (
        <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', height: '100vh' }}>

            <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex', marginBottom: 20, flexDirection: 'column' }}>
                <img
                    src={require("../../assets/logo.webp")}
                    width="120vh"
                    height="100vw"
                    alt='logo'
                    style={{ objectFit: 'contain' }}
                    className="d-inline-block align-top"
                />
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '10vh' }}>
                    <img
                        src={require("../../assets/successtick.png")}
                        height={40}
                        width={40}
                        style={{ marginRight: 20, objectFit: 'contain' }}
                        className="d-inline-block align-top"
                    />
                    <span style={{ marginBottom: 20, marginTop: 20 }} className='success_text'>Vielen Dank für Deine Passwort Änderung. Du kannst Dich jetzt mit Deinem neuen Passwort anmelden</span>
                </div>


            </div>
        </div>
    );
};

export default SuccessPage;