import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import '../../app.css';
import '../Messaging/Messaging.css';
import LeftPanel from './LeftPanel';
import RightPanel from './RightPanel';
import Header from '../../Components/Header/Header';
import { getCookieValue, newtaburl, URLS } from '../../utilities/constants';
import AlertComponent from "../../Components/AlertComponent";
import { DataContext } from '../../utilities/DataContext';
import axios from 'axios';
import { ClipLoader } from "react-spinners";
import { css } from '@emotion/react';
import { trackPageView } from '../../hooks/analytics';


const calculatePageSize = (width, height) => {

    if (width < 600) {
        // Small devices like mobile
        return Math.floor((height / 100) * 0.8); // Adjust based on height
    } else if (width < 1024) {
        // Tablets
        return Math.floor((height / 100) * 1.2);
    } else {
        // Desktops
        return Math.floor((height / 100) * 1.5);
    }
};

const Messaging = () => {

    const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(false)
    const [showLeftPanel, setShowLeftPanel] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [error, setError] = useState('');
    const [showSpinner, setShowSpinner] = useState(false)
    const [loading, setLoading] = useState(false);
    const [showalert, setShowAlert] = useState(false);
    const { userData, token, companyInfo, setMessageCount } = useContext(DataContext);
    const [chatDetails, setChatDetails] = useState(null)
    const [initialLoad, setInitialLoad] = useState(false);
    const [messages, setMessages] = useState([])
    const [contacts, setContacts] = useState([]);
    const [initialContacts, setInitialContacts] = useState([]);
    const [selectedContactId, setSelectedContactId] = useState(null);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [adId, setAdId] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(7);
    const [hasMoreMessages, setHasMoreMessages] = useState(false);
    const [newMessage, setNewMessage] = useState('');

    useEffect(() => {
        document.title = 'Chat'
        const consentCookie = getCookieValue('cookie_consent_level');
        if (consentCookie) {
            const consentStatus = JSON.parse(decodeURIComponent(consentCookie));
            console.log('consentStatus', consentStatus.tracking);
            if (consentStatus.tracking) {
                trackPageView(window.location.pathname, 'Chat')
            }
        }
    }, [])





    useEffect(() => {
        if (window.innerWidth <= 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [window.innerWidth]);

    useEffect(() => {
        getChatList();
    }, []);

    useEffect(() => {
        const updatePageSize = () => {
            const width = window.innerWidth;
            const height = window.innerHeight;
            const calculatedPageSize = calculatePageSize(width, height);
            setPageSize(calculatedPageSize);
        };
        // Set initial page size
        updatePageSize();

        // Update page size on window resize
        window.addEventListener('resize', updatePageSize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', updatePageSize);
        };
    }, []);

    const handleContactClick = async (contact) => {
        console.log('isMobile', isMobile);
        setInitialLoad(true)
        if (isMobile) {
            setShowLeftPanel(false)
        }
        setSelectedContactId(contact.id);
        setSelectedUserId(contact.other_user_id);
        setAdId(contact.vehicle_ad_overview);
        setPageNumber(1)
        setMessages([])
        const updatedContacts = contacts.map(chat =>
            chat.id === contact.id ? { ...chat, unread_count: 0 } : chat
        );
        setContacts(updatedContacts);
        setInitialContacts(updatedContacts)
        setLoading(true)
        getChatDetails([], contact.id, 1, pageSize);
        callUnreadMessageCount()
    };

    const handleSendMessage = () => {
        sendMessage()
    };

    const handleEditMessage = (messageId, newText) => {
        updateMessage(messageId, newText)
    };

    const handleDeleteMessage = (messageId) => {
        deleteMessage(messageId)
    };

    const loadMoreMessages = async () => {
        if (selectedContactId && hasMoreMessages) {
            setInitialLoad(false)
            getChatDetails(messages, selectedContactId, pageNumber, pageSize);
        }
    };

    const handleSearchContacts = (searchText) => {
        setSearchQuery(searchText);
        if (searchText.trim() === '') {
            setContacts(initialContacts); // Reset to initial contacts if search text is empty
        } else {
            const filteredContacts = initialContacts.filter(contact =>
                contact.profile_name.toLowerCase().includes(searchText.toLowerCase()) ||
                contact.vehicle_name.toLowerCase().includes(searchText.toLowerCase())
            );
            setContacts(filteredContacts);
        }
    };

    const formatCompanyName = (name) => {
        return name
            .toLowerCase()                          // Convert to lowercase
            .replace(/[^a-z0-9]+/g, '-')            // Replace non-alphanumeric characters with a hyphen
            .replace(/^-+|-+$/g, '');               // Remove leading or trailing hyphens
    };

    const formatAddName = (name) => {
        return name
            .toLowerCase()                          // Convert to lowercase
            .replace(/[^a-z0-9]+/g, '-')            // Replace non-alphanumeric characters with a hyphen
            .replace(/^-+|-+$/g, '');               // Remove leading or trailing hyphens
    };

    const openNewTab = (url) => {
        window.open(url, "_blank");
    };

    const gotoBusinessPage = (contact) => {
        const baseUrl = newtaburl
        const formattedCompanyName = formatCompanyName(contact.profile_name);
        const componentPage = `businesspage/${formattedCompanyName}`;
        const url = baseUrl + componentPage;
        openNewTab(url)
    }

    const gotoAdDetail = (contact) => {
        // navigate(`/ad/${contact.vehicle_ad_overview}`)
        const baseUrl = newtaburl
        const componentPage = `ad/${contact.vehicle_ad_overview}`;
        const url = baseUrl + componentPage;
        openNewTab(url)
    }

    const callUnreadMessageCount = async () => {
        const url = `${URLS.BASE_URL}${URLS.UNREAD_MESSAGE_COUNT}`;
        await axios.get(url, { headers: { 'Authorization': token } })
            .then((response) => {
                console.log(response)
                setLoading(false)
                if (response?.data.status === 1) {
                    setMessageCount(response.data.data.total_unread_count)
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const getChatDetails = async (messagelist, chatId, page, size, returnResponse = false) => {
        console.log('pagenumber+++', page);
        setShowSpinner(true);
        const url = `${URLS.BASE_URL}${URLS.CHAT_DETAILS}${chatId}/?page=${page}&page_size=${size}`;

        try {
            const response = await axios.get(url, { headers: { Authorization: token } });
            setShowSpinner(false);

            if (response?.data.status === 1) {
                setLoading(false)
                console.log('response', response);
                const newMessages = response.data.chat_messages_data;
                setMessages(prevMessages => [...prevMessages, ...newMessages]);
                setPageNumber(response.data.next !== null ? response.data.next : page);
                setHasMoreMessages(response.data.next !== null ? true : false);
                if (returnResponse) {
                    return response.data;
                }
            } else {
                setLoading(false)
                console.log('response', response);
            }
        } catch (error) {
            setLoading(false)
            setShowSpinner(false);
            console.log('error', error);
        }
    };

    // const getChatDetails = async (messagelist, chatId, page, size) => {
    //     console.log('pagenumber+++', page)
    //     setShowSpinner(true)
    //     const url = `${URLS.BASE_URL}${URLS.CHAT_DETAILS}${chatId}/?page=${page}&page_size=${size}`;
    //     await axios.get(url, { headers: { Authorization: token } })
    //         .then((response) => {
    //             setLoading(false);
    //             if (response?.data.status === 1) {
    //                 setShowSpinner(false)
    //                 console.log('response', response)
    //                 const newMessages = response.data.chat_messages_data;
    //                 if (messagelist.length > 0) {
    //                     // Create a set of existing message IDs
    //                     const existingMessageIds = new Set(messagelist
    //                         .filter(message => message.chat === chatId) // Filter messages for the selected chat
    //                         .map(message => message.id)
    //                     );
    //                     // Filter new messages to only include those that aren't already in state
    //                     const uniqueNewMessages = newMessages.filter(message =>
    //                         !existingMessageIds.has(message.id)
    //                     );
    //                     // Update state with unique new messages for the selected contact
    //                     setMessages(prevMessages => [...prevMessages, ...uniqueNewMessages]);
    //                 } else {
    //                     setMessages(prevMessages => [...prevMessages, ...newMessages]);
    //                 }
    //                 setPageNumber(response.data.next !== null ? response.data.next : page)
    //                 setHasMoreMessages(response.data.next !== null ? true : false);
    //             } else {
    //                 setShowSpinner(false)
    //                 console.log('response', response);
    //             }
    //         })
    //         .catch(function (error) {
    //             // setLoading(false);
    //             setShowSpinner(false)
    //             console.log('error', error);
    //             if (error.response) {
    //                 // Handle error response
    //             }
    //         });
    // };

    const getChatList = async () => {
        setLoading(true);
        const url = `${URLS.BASE_URL}${URLS.CHAT_LIST}`;
        await axios.get(url, { headers: { Authorization: token } })
            .then((response) => {
                setLoading(false);
                if (response?.data.status === 1) {
                    if (response?.data.data.length > 0) {
                        setContacts(response?.data.data);
                        setInitialContacts(response?.data.data)
                    }
                } else {
                    console.log('response', response);
                }
            })
            .catch(function (error) {
                setLoading(false);
                console.log('error', error);
                if (error.response) {
                    // Handle error response
                }
            });
    };

    const sendMessage = async () => {
        setLoading(true)
        const url = `${URLS.BASE_URL}${URLS.CHAT_MESSAGE_CREATE}`;
        const form = new FormData();
        form.append('vehicle_ad_overview', adId)
        form.append('message', newMessage)
        form.append('recipient_user', selectedUserId)
        form.append('sender_user', userData.id)
        form.append('chat', selectedContactId)
        const formDataObject = {};
        form.forEach((value, key) => {
            formDataObject[key] = value;
        });


        console.log('form >>', formDataObject)
        axios.defaults.headers.common["Accept"] = "application/json";
        axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
        await axios.post(url, form, { headers: { Authorization: token } })
            .then(async (response) => {
                setLoading(false)
                console.log('response', response);
                if (response?.data.status === 1) {
                    setNewMessage('');
                    setPageNumber(1)
                    // setMessages([])
                    // getChatDetails([], selectedContactId, 1, pageSize)
                    const chatDetailsResponse = await getChatDetails([], selectedContactId, 1, pageSize, true);
                    if (chatDetailsResponse) {
                        const newMessages = chatDetailsResponse.chat_messages_data;
                        setMessages(newMessages);
                    }
                    setInitialLoad(true)
                } else {
                    console.log('response', response);
                    setShowAlert(true)
                    setError(response.data.message)
                }
            })
            .catch(function (error) {
                setLoading(false)
                console.log('error', error);
                if (error.response) {
                    setShowAlert(true)
                    setError(error.response.data.message)
                }
            });
    }

    const updateMessage = async (messageId, message) => {
        setLoading(true)
        const url = `${URLS.BASE_URL}${URLS.UPDATE_MESSAGE}${messageId}/`;
        const form = new FormData();
        form.append('message', message)
        const formDataObject = {};
        form.forEach((value, key) => {
            formDataObject[key] = value;
        });


        console.log('form >>', formDataObject)
        axios.defaults.headers.common["Accept"] = "application/json";
        axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
        await axios.patch(url, form, { headers: { Authorization: token } })
            .then((response) => {
                setLoading(false)
                console.log('response', response);
                if (response?.data.status === 1) {
                    setNewMessage('');

                    const updatedMessage = response.data.chat_message_data;
                    // Update the messages list with the new message data
                    setMessages(prevMessages =>
                        prevMessages.map(msg =>
                            msg.id === updatedMessage.id ? { ...msg, ...updatedMessage } : msg // Merge the updated fields
                        )
                    );

                    // getChatDetails(messages, selectedContactId, pageNumber, pageSize)
                } else {
                    console.log('response', response);
                    setShowAlert(true)
                    setError(response.data.message)
                }
            })
            .catch(function (error) {
                setLoading(false)
                console.log('error', error);
                if (error.response) {
                    setShowAlert(true)
                    setError(error.response.data.message)
                }
            });
    }


    const deleteMessage = async (messageId) => {
        setLoading(true)
        const url = `${URLS.BASE_URL}${URLS.DELETE_MESSAGE}${messageId}/`
        await axios.delete(url, { headers: { Authorization: token } })
            .then((response) => {
                setLoading(false)
                console.log('response', response);
                if (response?.data.status === 1) {
                    // getChatDetails(messages, selectedContactId, pageNumber, pageSize)
                    setMessages(prevMessages =>
                        prevMessages.filter(msg => msg.id !== messageId) // Filter out the message with the specified ID
                    );
                } else {
                    console.log('response', response);
                    setShowAlert(true)
                    setError(response.data.message)
                }
            })
            .catch(function (error) {
                setLoading(false)
                console.log('error', error);
                if (error.response) {
                    setShowAlert(true)
                    setError(error.response.data.message)
                }
            });
    }

    return (
        <>
            <Header />
            {loading && (
                <div className="loader">
                    <ClipLoader
                        color={"#123abc"}
                        loading={loading}
                        css={override}
                        size={50}
                    />
                </div>
            )}
            <Container fluid style={{ paddingTop: 75, height: '100vh' }}>
                <Row className="h-100 flex-nowrap overflow-auto">
                    <Col md={4} lg={3} className={isMobile ? `left-panel ${showLeftPanel ? '' : 'hidden'}` : 'left-panel'}>
                        <LeftPanel
                            loginUserData={userData}
                            profileInfo={companyInfo}
                            contacts={contacts}
                            handleContactClick={handleContactClick}
                            selectedContactId={selectedContactId}
                            handelSearch={handleSearchContacts}
                            handleProfileNameClick={gotoBusinessPage}
                            handleAdClick={gotoAdDetail}
                        />
                    </Col>
                    <Col style={{ paddingLeft: 0, paddingRight: 0 }} md={8} lg={9} className={isMobile ? `right-panel ${showLeftPanel ? 'hidden' : ''}` : 'right-panel'}>
                        <RightPanel
                            messages={messages}
                            newMessage={newMessage}
                            loadMoreMessages={loadMoreMessages}
                            setNewMessage={setNewMessage}
                            handleSendMessage={handleSendMessage}
                            selectedContactId={selectedContactId}
                            loggedInUserId={userData.id}
                            handleEditMessage={handleEditMessage}
                            handleDeleteMessage={handleDeleteMessage}
                            hasMoreMessages={hasMoreMessages}
                            showSpinner={showSpinner}
                            pagenumber={pageNumber}
                            intialLoad={initialLoad}
                            showRightPannel={!showLeftPanel}
                            gotoleft={() => { setShowLeftPanel(true) }}
                        />
                    </Col>
                </Row>
            </Container>
            {showalert &&
                <AlertComponent message={error} onClose={() => { setShowAlert(false) }}></AlertComponent>
            }
        </>
    );
};

export default Messaging;
