import React from 'react';
import '../../app.css'
import Header from '../../Components/Header/Header';


const EventsScreen = () => {
  const iframeSrc = "https://campervanspot.com/megamobil-detail?hs_preview=zHUfnwEO-88601140434";
  return (
    <>
    <Header isLoggedIn={false} />
    <div className="full-width-container overlayStyle" style={{
      backgroundImage: `url(${process.env.PUBLIC_URL + "/events_bg.png"})`, backgroundPosition: 'cover', backgroundSize: 'cover', height: "100vh",
    }}>
      <div className='mx-5' style={{ alignItems: 'center', justifyContent: 'center' }}>
        <h4 className='mediumtextstyle'>Welcome Events!</h4>
      </div>
    </div>
    </>
    // <div  className="full-width-container overlayStyle" >
    //   <iframe
    //       title="External Page"
    //       src={iframeSrc}
    //       width="100%"
    //       height="100%"
        
    //     />
    // </div>
      
  )
};

export default EventsScreen;