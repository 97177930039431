import React, { useState, useRef, useEffect, useContext } from "react";

import { Row, Col, OverlayTrigger, Button, Popover } from "react-bootstrap";
import Header from "../../Components/Header/Header";
import "../../app.css";
import "../BusinessForm/BusinessForm.css";
import TopLabelInput from "../../Components/TpLabelInput/TopLabelInput";
import ImageCard from "../../Components/ImageCard/ImageCard";
import { useLocation, useNavigate } from "react-router-dom";
import { DataContext } from "../../utilities/DataContext";
import { FiAlertTriangle } from "react-icons/fi";
import { getCookieValue, newtaburl, Strings, URLS } from "../../utilities/constants";
import axios from "axios";
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";
import ImageTextEditor from "../../Components/ImageTextEditor/ImageTextEditor";
import "quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import AlertComponent from "../../Components/AlertComponent";
import heic2any from 'heic2any';
import validator from 'validator'
import { FaCircleCheck } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import { trackPageView } from "../../hooks/analytics";
import { getCookieConsentValue } from "react-cookie-consent";


const MAX_IMAGE_SIZE_BYTES = 4.5 * 1024 * 1024; // 4.5 MB in bytes

const BusinessFormPage = () => {

  const navigate = useNavigate();
  const heroRef = useRef();
  const [businessImgRefs, setBusinessImgRefs] = useState(Array(5).fill(null)); // State to hold references to hero images
  const [disable, setDisable] = useState(false)
  const [editImageIndex, setEditedImageIndex] = useState(0);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('')
  const [showalert, setShowAlert] = useState(false)
  const [showDeletePop, setShowDeletePop] = useState(false)
  const [pageStaus, setPageStatus] = useState('')
  const {
    setUserData,
    setIsLoggedIn,
    token,
    setToken,
    businessForm1data,
    setBusinessForm1data,
    setBusinessForm2data,
    setCompanyInfo,
    companyInfo
  } = useContext(DataContext);

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [heading, setHeading] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [heroImageFile, setHeroImageFile] = useState(null);
  const [images, setImages] = useState([]);
  const [showImageTextEditor, setShowImageTextEditor] = useState(false);
  const [countryCode, setCountryCode] = useState("DE");
  const fileInputRef = useRef(null);
  const [logoRef, setLogoRef] = useState(null);
  const [heroImgRef, setHeroImgRef] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [color, setColor] = useState('green')
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  var modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] },
      ],
      [
        {
          color: [
            "#000000",
            "#e60000",
            "#ff9900",
            "#ffff00",
            "#008a00",
            "#0066cc",
            "#9933ff",
            "#ffffff",
            "#facccc",
            "#ffebcc",
            "#ffffcc",
            "#cce8cc",
            "#cce0f5",
            "#ebd6ff",
            "#bbbbbb",
            "#f06666",
            "#ffc266",
            "#ffff66",
            "#66b966",
            "#66a3e0",
            "#c285ff",
            "#888888",
            "#a10000",
            "#b26b00",
            "#b2b200",
            "#006100",
            "#0047b2",
            "#6b24b2",
            "#444444",
            "#5c0000",
            "#663d00",
            "#666600",
            "#003700",
            "#002966",
            "#3d1466",
            "custom-color",
          ],
        },
      ],
    ],
  };

  var formats = [
    "header",
    "height",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "color",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "size",
  ];

  const [form1Data, setForm1Data] = useState({
    companyName: "",
    street: "",
    streetNumber: "",
    zipCode: "",
    city: "",
    country: "",
    telephone: "",
    email: "",
    logoHorizontalposition: '',
    logoVerticlePosition: ''
  });

  const [form2Data, setForm2Data] = useState({
    hilight1: "",
    hilight2: "",
    hilight3: "",
    hilight4: "",
    hilight5: "",
    hilight6: "",
    description: "",
    fburl: "",
    youtubeurl: "",
    siteurl: "",
    instaurl: "",
  });

  const [form1Errors, setForm1Errors] = useState({
    street: "",
    streetNumber: "",
    city: "",
    country: "",
    email: "",
    telephone: "",
    zipCode: "",
    logo: "",
    businessType: "",
  });

  const [form2Errors, setForm2Errors] = useState({
    heading: "",
    hilight1: "",
    hilight2: "",
    hilight3: "",
    hilight4: "",
    hilight5: "",
    hilight6: "",
    description: "",
    siteurl: "",
    heroImageFile: "",
    images: "",
    youtubeurl: '',
    instaurl: '',
    fburl: '',
    siteurl: ''
  });

  const location = useLocation();
  const { state } = location;
  const data = state && state.isfrom;
  const messagefromreg = state && state.message;
  const fileInputRefs = useRef([]);

  useEffect(() => {
    document.title = 'Unternehmensseite auf campervanspot.com pflegen'
    const consentCookie = getCookieValue('cookie_consent_level');
    if (consentCookie) {
      const consentStatus = JSON.parse(decodeURIComponent(consentCookie));
      console.log('consentStatus', consentStatus.tracking);
      if (consentStatus.tracking) {
        trackPageView(window.location.pathname, 'Unternehmensseite auf campervanspot.com pflegen')
      }
    }

  }, [])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (messagefromreg) {
      setMessage(messagefromreg)
    }
    const token = searchParams.get('token');
    const uuid = searchParams.get('uuid');
    if (uuid != null && token != null) {
      getverifyStatus(uuid, token)
    } else {
      console.log('companyInfo', companyInfo);
      getCompanyDetails(companyInfo.id);
    }
  }, []);


  const handleProcedureContentChange = (content) => {
    console.log("content---->", content);
    setForm2Data((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        description: content,
      };
      console.log("Updated form data:", updatedFormData);
      return updatedFormData;
    });
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const formatCompanyName = (name) => {
    return name
      .toLowerCase()                          // Convert to lowercase
      .replace(/[^a-z0-9]+/g, '-')            // Replace non-alphanumeric characters with a hyphen
      .replace(/^-+|-+$/g, '');               // Remove leading or trailing hyphens
  };



  // Function to set src of the image
  const setSrcofheroimage = (src) => {
    const img = new Image();
    img.onload = handleHeroImageLoaded;
    img.src = src;
  };

  const setSrcofbusinesimage = (src, index) => {
    const img = new Image();
    img.src = src;
    img.onload = () => handleBusinessImageLoaded(img.src, index);
  };

  const getverifyStatus = async (uuid, token) => {
    const url = `${URLS.BASE_URL}${URLS.VERIFY_EMAIL}${uuid}`;
    axios.defaults.headers.common["Accept"] = "application/json";
    axios.defaults.headers.common["Content-Type"] = "application/json";
    await axios.get(url, { headers: { 'Authorization': token } })
      .then((response) => {
        if (response?.data.status === 1) {
          console.log("response", response);
          // if (response?.data.message === 'Benutzerkonto bereits verifiziert') {
          setMessage(response?.data.message)
          const token = response?.data.token
          const userData = response?.data.user
          setUserData(userData);
          const compnaydata = response?.data.company_information
          setCompanyInfo(compnaydata)
          setIsLoggedIn(true)
          setToken(token)
          if (response?.data.message == '') {
            setColor('orange')
          } else {
            setColor('green')
          }
          if (response?.data.is_companyinfo_completed === true) {
            const formattedCompanyName = formatCompanyName(compnaydata.company_name);
            navigate(`/businesspage/${formattedCompanyName}`);
            // navigate("/businessPage");
          } else {
            getCompanyDetails(compnaydata.id);
            setMessage(response?.data.message)
          }
        } else {
          setIsLoggedIn(false)
          setMessage(response?.data.message)
          setTimeout(() => {
            navigate('/home')
          }, 500);
        }
      })
  }

  const getCompanyDetails = async (id) => {
    setLoading(true)
    const url = `${URLS.BASE_URL}${URLS.COMPANY_DETAILS}${id}`;
    axios.defaults.headers.common["Accept"] = "application/json";
    axios.defaults.headers.common["Content-Type"] = "application/json";
    await axios
      .get(url)
      .then((response) => {
        setLoading(false)
        if (response?.data.status === 1) {
          const data = response?.data.data;
          setUserData(data.user);
          // setCompanyInfo(data.company_information)
          console.log("response", data);
          const country_code =
            data.country_code !== null
              ? data.country_code
              : !isEmptyObject(businessForm1data)
                ? businessForm1data.country_code
                : "DE";
          setCountryCode(country_code);
          setPhoneNumber(data.company_phonenumber);
          setPageStatus(data.businesspage_status)
          setForm1Data({
            companyName: data.company_name !== null ? data.company_name : "",
            street: data.street !== null ? data.street : "",
            streetNumber: data.street_number !== null ? data.street_number : "",
            zipCode: data.zip_code !== null ? data.zip_code : "",
            city: data.city !== null ? data.city : "",
            country: data.country !== null ? data.country : "",
            telephone: data.company_phonenumber !== null ? data.company_phonenumber : "",
            email: data.user.email !== null ? data.user.email : "",
            logoHorizontalposition: data.company_logo_horizontal_position !== null ? data.company_logo_horizontal_position : "",
            logoVerticlePosition: data.company_logo_vertical_position !== null ? data.company_logo_vertical_position : "",
          });
          // setForm1Data({
          //   companyName: data.company_name !== null ? data.company_name : !isEmptyObject(businessForm1data) ? businessForm1data.company_name : "",
          //   street: data.street !== null ? data.street : !isEmptyObject(businessForm1data) ? businessForm1data.street : "",
          //   streetNumber: data.street_number !== null ? data.street_number : !isEmptyObject(businessForm1data) ? businessForm1data.street_number : "",
          //   zipCode: data.zip_code !== null ? data.zip_code : !isEmptyObject(businessForm1data) ? businessForm1data.zip_code : "",
          //   city: data.city !== null ? data.city : !isEmptyObject(businessForm1data) ? businessForm1data.city : "",
          //   country: data.country !== null ? data.country : !isEmptyObject(businessForm1data) ? businessForm1data.country : "",
          //   telephone: data.company_phonenumber !== null ? data.company_phonenumber : !isEmptyObject(businessForm1data) ? businessForm1data.company_phonenumber : "",
          //   email: data.user.email !== null ? data.user.email : !isEmptyObject(businessForm1data) ? businessForm1data.company_email : "",
          // });
          const businessdata = data.companybusinesspage;
          setForm2Data({
            hilight1: businessdata !== null ? businessdata.highlights_1 : "",
            hilight2: businessdata !== null ? businessdata.highlights_2 : "",
            hilight3: businessdata !== null ? businessdata.highlights_3 : "",
            hilight4: businessdata !== null ? businessdata.highlights_4 : "",
            hilight5: businessdata !== null ? businessdata.highlights_5 : "",
            hilight6: businessdata !== null ? businessdata.highlights_6 : "",

            description: businessdata !== null ? businessdata.description : "",
            siteurl: businessdata !== null ? businessdata.website_url : "",
            fburl: businessdata !== null ? businessdata.facebook_url : "",
            instaurl: businessdata !== null ? businessdata.instagram_url : "",
            youtubeurl: businessdata !== null ? businessdata.youtube_url : "",
          });
          setHeading(businessdata !== null ? businessdata.headline : "");
          setSelectedFile(data.company_logo !== null ? data.company_logo : null);
          const heroimg = businessdata !== null ? businessdata.hero_image : null
          setHeroImageFile(heroimg)
          let businessType = data.type_of_business && data.type_of_business.length > 0 ? data.type_of_business : [];
          setSelectedOptions(businessType);

          if (businessdata != null || !isEmptyObject(businessdata)) {
            let imagesArray = [];

            const businessImages = businessdata.companybusinesspage_images;
            if (businessImages && businessImages.length > 0) {
              businessImages.forEach((imageFile, index) => {
                let updatedimage = imageFile.business_page_image;
                const originalimage = imageFile.business_page_image;
                const imageDescription = imageFile.image_description;
                const obj = {
                  original_image: originalimage,
                  updated_image: updatedimage,
                  image_description: imageDescription,
                };
                imagesArray.push(obj);
              });
              setImages(imagesArray);
              console.log('imagesArray', imagesArray)
            }
          }
        } else {
          setLoading(false)
          setShowAlert(true)
          setError(response.data.message)
        }
      })
      .catch(function (error) {
        setLoading(false)

        if (error.response) {
          setShowAlert(true)
          setError(error.response.data.message)
        }

      });
  };

  const handleHeroImageChange = (e) => {
    const file = e.target.files[0]
    let errors = {}
    if (file.size >= 4.5 * 1024 * 1024) { // Check if file size is greater than 5MB
      errors.heroImageFile = Strings.image_size_error
    } else {
      errors.heroImageFile = ''
      if (file.name.length > 100) {
        errors.heroImageFile = Strings.image_filename_error
      } else {
        setHeroImageFile(file);
      }
    }
    setForm2Errors(errors)
  };

  const handleFileChange = (event) => {
    console.log("e.target.files[0]", event.target.files[0]);
    const file = event.target.files[0];
    handleFile(file);
  };

  const handleFile = async (file) => {
    setLoading(true)
    let errors = {};
    if (file) {
      if (file.size >= 4.5 * 1024 * 1024) { // Check if file size is greater than 5MB
        errors.logo = Strings.image_size_error
        setLoading(false)
      } else {
        if (file.name.length > 100) {
          errors.logo = Strings.image_filename_error
          setLoading(false)
        } else {
          if (file && file.type === 'image/heic') {
            try {
              const convertedBlob = await heic2any({ blob: file, toType: 'image/jpeg' });
              setSelectedFile(convertedBlob);
              setLoading(false)
            } catch (error) {
              console.error('Error converting HEIC file:', error);
              setSelectedFile(file);
              setLoading(false)
            }
          } else {
            setLoading(false)
            setSelectedFile(file);
            setLoading(false)
          }
        }
      }
      setForm1Errors(errors)
    }
  };


  const handleLogoImageLoaded = async (e) => {
    console.log("logoRef", logoRef);
    if (logoRef === null) {
      let img = e.target.src;
      console.log("img", img);
      try {
        const response = await fetch(img);
        const blob = await response.blob();
        const imgUrl = URL.createObjectURL(blob);
        img = imgUrl;
        console.log("logourl", imgUrl);
        console.log("logoblob", blob);
        setSelectedFile(blob);
        setLogoRef(e.target)
      } catch (error) {
        console.error("error", error);
      } finally {
        console.log("Fetch operation complete");
      }
    }
  };

  const handleBusinessImageLoaded = async (src, index) => {
    console.log('index', index)
    if (images[index]?.updated_image != null && images[index]?.updated_image instanceof Blob && images[index]?.original_image != null && images[index]?.original_image instanceof Blob) {
      console.log('instance of blob ', images[index]?.updated_image, images[index]?.original_image)
      return;
    }

    if (!businessImgRefs[index]) {
      let img = src;
      try {
        const response = await fetch(img);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const blob = await response.blob();

        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const imgElement = new Image();
          imgElement.src = reader.result;

          imgElement.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // Set maximum width and height (adjust as needed)
            const maxWidth = 1920; // You can adjust this value
            const maxHeight = 1080; // You can adjust this value

            // Calculate new dimensions to fit within maxWidth and maxHeight while preserving aspect ratio
            let newWidth = imgElement.width;
            let newHeight = imgElement.height;

            if (newWidth > maxWidth || newHeight > maxHeight) {
              const aspectRatio = newWidth / newHeight;

              if (newWidth > maxWidth) {
                newWidth = maxWidth;
                newHeight = newWidth / aspectRatio;
              }

              if (newHeight > maxHeight) {
                newHeight = maxHeight;
                newWidth = newHeight * aspectRatio;
              }
            }

            canvas.width = newWidth;
            canvas.height = newHeight;

            // Draw the image on the canvas with new dimensions
            ctx.drawImage(imgElement, 0, 0, newWidth, newHeight);

            canvas.toBlob((blob) => {
              const file = new File([blob], 'image.jpg', {
                type: 'image/jpeg', // Change the type according to your original image
                lastModified: Date.now()
              });


              console.log(`${index}Converted business image file size:`, file.size);
              console.log("Converted business image file: ", file);
              setImages((prevImages) => {
                const updatedImages = [...prevImages];
                // Update only the 'updated_image' key for the specified index

                updatedImages[index] = {
                  ...updatedImages[index],
                  updated_image: file,
                };
                console.log("updatedImages", updatedImages);
                setBusinessImgRefs((prevRefs) => {
                  const updatedRefs = [...prevRefs];
                  updatedRefs[index] = img; // Update the reference for this index
                  return updatedRefs;
                });
                return updatedImages;
              });
            }, 'image/jpeg', 0.9); // Adjust JPEG quality as needed (0.7 is just an example)
          };
        };
      } catch (error) {
        console.error("error", error);
      }
    }

  };

  const handleHeroImageLoaded = async (e) => {
    if (heroImgRef === null) {
      let img = e.target.src;
      console.log("heroImage", img);
      try {
        const response = await fetch(img);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const blob = await response.blob();
        const imgUrl = URL.createObjectURL(blob);
        img = imgUrl;

        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const imgElement = new Image();
          imgElement.src = reader.result;

          imgElement.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // Set maximum width and height (adjust as needed)
            const maxWidth = 1920; // You can adjust this value
            const maxHeight = 1080; // You can adjust this value

            // Calculate new dimensions to fit within maxWidth and maxHeight while preserving aspect ratio
            let newWidth = imgElement.width;
            let newHeight = imgElement.height;

            if (newWidth > maxWidth || newHeight > maxHeight) {
              const aspectRatio = newWidth / newHeight;

              if (newWidth > maxWidth) {
                newWidth = maxWidth;
                newHeight = newWidth / aspectRatio;
              }

              if (newHeight > maxHeight) {
                newHeight = maxHeight;
                newWidth = newHeight * aspectRatio;
              }
            }

            canvas.width = newWidth;
            canvas.height = newHeight;

            // Draw the image on the canvas with new dimensions
            ctx.drawImage(imgElement, 0, 0, newWidth, newHeight);

            canvas.toBlob((blob) => {
              const file = new File([blob], 'image.jpg', {
                type: 'image/jpeg', // Change the type according to your original image
                lastModified: Date.now()
              });
              setHeroImageFile(file);
            }, 'image/jpeg', 0.9); // Adjust JPEG quality as needed (0.7 is just an example)
          };
        };
        setHeroImgRef(e.target);
      } catch (error) {
        console.error("error", error);
      } finally {
      }
    }
  };

  const goToPage = (page) => {
    if (page === 2) {
      handleNext1Click()
    } else {
      handleNext2Click("profile2")
    }
  };

  const handleInputChange = (label, value) => {
    console.log("label", label);
    if (label === "telephone") {
      console.log("value", value);
      setCountryCode(value.short);
      setPhoneNumber(value.phone);
      if (value.phone === '') {
        setForm1Data((prevFormData) => {
          const updatedFormData = {
            ...prevFormData,
            [label]: "",
          };
          console.log("Updated form data1:", updatedFormData);
          return updatedFormData;
        });
      } else {
        setForm1Data((prevFormData) => {
          const updatedFormData = {
            ...prevFormData,
            [label]: `+${value.code} ${value.phone}`,
          };
          console.log("Updated form data2:", updatedFormData);
          return updatedFormData;
        });
      }

    } else {
      setForm1Data((prevFormData) => {
        const updatedFormData = {
          ...prevFormData,
          [label]: value,
        };
        console.log("Updated form data:", updatedFormData);
        return updatedFormData;
      });
    }
  };

  const validateUrl = (url) => {
    const urlPattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!urlPattern.test(url);
  };

  const handleForm2InputChange = (label, value) => {
    console.log("label, value", label, value);
    // if (label == 'siteurl') {
    //   if (!validator.isURL(form2Data.siteurl)) {
    //     setForm2Errors({ siteurl: `Bitte geben Sie eine valide URL in dem Format ‘https://beispiel.com/` })
    //   } else {
    //     setForm2Errors({ siteurl: '' })
    //   }
    // } else if (label == 'fburl') {
    //   if (!validator.isURL(form2Data.fburl)) {
    //     setForm2Errors({ fburl: `Bitte geben Sie eine valide URL in dem Format ‘https://beispiel.com/` })
    //   } else {
    //     setForm2Errors({ fburl: '' })
    //   }
    // } else if (label == 'instaurl') {
    //   if (!validator.isURL(form2Data.instaurl)) {
    //     setForm2Errors({ instaurl: `Bitte geben Sie eine valide URL in dem Format ‘https://beispiel.com/` })
    //   } else {
    //     setForm2Errors({ instaurl: '' })
    //   }
    // } else if (label == 'youtubeurl') {
    //   if (!validator.isURL(form2Data.youtubeurl)) {
    //     setForm2Errors({ youtubeurl: `Bitte geben Sie eine valide URL in dem Format ‘https://beispiel.com/` })
    //   } else {
    //     setForm2Errors({ youtubeurl: '' })
    //   }
    // }
    setForm2Data((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        [label]: value,
      };
      console.log("Updated form data:", updatedFormData);
      return updatedFormData;
    });
  };

  const validateForm2Data = () => {
    const errors = {};
    // if (heading === undefined || heading === "") {
    //   errors.heading = "Bitte füllen Sie dieses Pflichtfeld aus.";
    // }
    // if (form2Data.hilight1 === undefined || form2Data.hilight1 === "") {
    //   errors.hilight1 = "Bitte füllen Sie dieses Pflichtfeld aus.";
    // }
    // if (form2Data.hilight2 === undefined || form2Data.hilight2 === "") {
    //   errors.hilight2 = "Bitte füllen Sie dieses Pflichtfeld aus.";
    // }
    // if (form2Data.hilight3 === undefined || form2Data.hilight3 === "") {
    //   errors.hilight3 = "Bitte füllen Sie dieses Pflichtfeld aus.";
    // }
    // if (form2Data.description === undefined || form2Data.description === "") {
    //   errors.description = "Bitte füllen Sie dieses Pflichtfeld aus.";
    // }
    if (form2Data.siteurl !== undefined && form2Data.siteurl !== '' && !validator.isURL(form2Data.siteurl)) {
      errors.siteurl = `Bitte geben Sie eine valide URL in dem Format ‘https://beispiel.com/`;
    }
    if (form2Data.fburl !== undefined && form2Data.fburl !== '' && !validator.isURL(form2Data.fburl)) {
      errors.fburl = `Bitte geben Sie eine valide URL in dem Format ‘https://beispiel.com/`;
    }
    if (form2Data.instaurl !== undefined && form2Data.instaurl !== '' && !validator.isURL(form2Data.instaurl)) {
      errors.instaurl = `Bitte geben Sie eine valide URL in dem Format ‘https://beispiel.com/`;
    }
    if (form2Data.youtubeurl !== undefined && form2Data.youtubeurl !== "" && !validator.isURL(form2Data.youtubeurl)) {
      errors.youtubeurl = `Bitte geben Sie eine valide URL in dem Format ‘https://beispiel.com/`;
    }
    return errors;
  };


  const handleHeadingChange = (e) => {
    setHeading(e.target.value);
  };

  const handleOptionSelect = (option) => {
    console.log("selectedOptions", selectedOptions);
    const selectedIndex = selectedOptions?.indexOf(option);
    console.log("selectedIndex", selectedIndex);
    if (selectedIndex === -1 || selectedIndex === undefined) {
      // Option not selected, add it to the list
      setSelectedOptions([...selectedOptions, option]);
    } else {
      // Option already selected, remove it from the list
      const updatedOptions = [...selectedOptions];
      updatedOptions.splice(selectedIndex, 1);
      setSelectedOptions(updatedOptions);
    }
  };

  const handleHorizantalPositionSelect = (option) => {
    setForm1Data((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        logoHorizontalposition: option,
      };
      console.log("Updated form data:", updatedFormData);
      return updatedFormData;
    });
  };

  const handleVerticlePositionSelect = (option) => {
    setForm1Data((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        logoVerticlePosition: option,
      };
      console.log("Updated form data:", updatedFormData);
      return updatedFormData;
    });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleFile(file);
  };

  const handleImageChange = (e, index) => {
    const file = e.target.files[0];
    let errors = {};
    if (file) {
      if (file.size >= 4.5 * 1024 * 1024) { // Check if file size is greater than 5MB
        errors.images = Strings.image_size_error
      } else {
        errors.images = ''
        if (file.name.length > 100) {
          errors.images = Strings.image_filename_error
        } else {
          const newImageFiles = [...images]; // Copy the current state array

          newImageFiles[index] = {
            ...newImageFiles[index],
            original_image: file,
            updated_image: file,
            image_description: "",
          };
          console.log("newImageFiles", newImageFiles);
          setImages(newImageFiles);
        }
      }
      setForm2Errors(errors)
    }


  };

  const handleMouseEnter = () => {
    console.log('showing----')
    setShowDeletePop(true);
  };

  const handleMouseLeave = () => {
    setShowDeletePop(false);
  };

  const handleDeleteClick = (imageType) => {
    setShowDeletePop(false);
    if (imageType == 'heroImage') {
      setHeroImageFile(null)
    } else {
      setSelectedFile(null)
    }
  };


  const handleDelete = (index) => {
    console.log('updated_image', index);
    const newImages = [...images];
    const imageToUpdate = newImages[index];
    imageToUpdate.original_image = null;
    imageToUpdate.updated_image = null;
    imageToUpdate.image_description = "";
    console.log('newImages', newImages);
    setImages(newImages);
  };

  const handleEditText = (index) => {
    console.log("clicked", index);
    setEditedImageIndex(index);
    setShowImageTextEditor(true);
  };

  function isBlobURL(url) {
    const blobUrlRegex = /^blob:/i;
    return blobUrlRegex.test(url);
  }

  function generateRandomFileName() {
    const randomNumber = Math.floor(10 + Math.random() * 90); // Generates a random number between 10 and 99
    return `image_${randomNumber}.png`;
  }

  function createFileFromBlob(blob) {
    console.log('blob', blob)
    const fileName = generateRandomFileName();
    return new File([blob], fileName, { type: blob.type });
  }

  const saveImageData = (data) => {
    let arrayimages = [...images];
    arrayimages[editImageIndex] = {
      ...arrayimages[editImageIndex],
      image_description: data,
    };
    console.log("arrayimages", arrayimages);
    setImages(arrayimages);
    setShowImageTextEditor(false);
  };

  const convertBase64ToFile = (base64String) => {
    console.log(typeof base64String, base64String);
    const splitDataURI = `${base64String}`.split(",");
    const base64Data = splitDataURI[1];
    // Decode base64 data
    const binaryString = window.atob(base64Data);
    const arrayBuffer = new ArrayBuffer(binaryString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryString.length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
    }
    // Create Blob
    const blob = new Blob([uint8Array], { type: "image/png" });
    // Create a File from the Blob
    const convertedFile = createFileFromBlob(blob);
    return convertedFile;
  };

  const handlePublishClick = () => {
    handleNext2Click('publish')
  }

  const handleNext2Click = (btnClick) => {
    let errors = validateForm2Data()
    console.log("errors", errors);
    setForm2Errors(errors);
    if (Object.keys(errors).length === 0) {
      setDisable(true)
      console.log("images", images);
      console.log("heroimage", heroImageFile);
      const finalImagesList = images.length > 0 ? images.map((imageObj) => imageObj.updated_image) : []
      console.log("finalImagesList", finalImagesList);
      const imagesdescriptions = images.length > 0 ? images.map(
        (imageObj) => imageObj.image_description === 'null' ? ' ' : imageObj.image_description
      ) : []

      let heroImage = null;
      let businessImagesData = [];

      if (heroImageFile != null) {
        if (isBlobURL(heroImageFile)) {
          convertBlobUrlToFile(heroImageFile).then((file) => {
            if (file) {
              heroImage = file;
              console.log("heroImageblob", heroImage);
            } else {
              console.log("Failed to convert Blob URL to File.");
            }
          });
        } else if (heroImageFile instanceof File) {
          console.log("heroImageFile>>>>", heroImageFile);
          let url = URL.createObjectURL(heroImageFile);
          console.log("CONVERTTED URL HEROIMAGE", url);
          heroImage = heroImageFile;
        } else if (heroImageFile instanceof Blob) {
          console.log("heroImageFile>>>>", heroImageFile);
          let blobfile = createFileFromBlob(heroImageFile)
          let url = URL.createObjectURL(blobfile);
          console.log("CONVERTTED URL HEROIMAGE", url);
          heroImage = blobfile;
        } else {
          console.log("IINSTANCE OF URL");
          heroImage = convertBase64ToFile(heroImageFile);
        }
      }
      if (finalImagesList && finalImagesList.length > 0) {
        finalImagesList.forEach((imageFile) => {
          console.log(" BUSINESPAGEIMAGE imageFile", imageFile);
          if (imageFile != null) {
            if (isBlobURL(imageFile)) {
              convertBlobUrlToFile(imageFile).then((file) => {
                if (file) {
                  businessImagesData.push(file);
                  console.log("businesImageblob", imageFile);
                } else {
                  console.log("Failed to convert Blob URL to File.");
                }
              });
            } else if (imageFile instanceof File) {
              let url = URL.createObjectURL(imageFile);
              console.log("CONVERTTED BUSINESPAGEIMAGE", url);
              businessImagesData.push(imageFile);
            } else if (imageFile instanceof Blob) {
              let blobfile = createFileFromBlob(imageFile)
              let url = URL.createObjectURL(blobfile);
              console.log("CONVERTTED BUSINESPAGEIMAGE", url);
              businessImagesData.push(blobfile);
            } else {
              const file = convertBase64ToFile(imageFile);
              businessImagesData.push(file);
            }
          } else {
            businessImagesData.push(null);
          }
        });
      }
      setTimeout(() => {
        setLoading(true);
        callAddBusinessPageApi(
          businessImagesData,
          imagesdescriptions,
          heroImage,
          btnClick
        );
      }, 500);
    }
  };

  const openNewTab = () => {
    const baseUrl = newtaburl
    const formattedCompanyName = formatCompanyName(form1Data.companyName);
    const componentPage = `businesspage/${formattedCompanyName}`;
    const url = baseUrl + componentPage;
    window.open(url, "_blank");
  };

  const handleNext1Click = () => {
    const errors = validateForm1Data();

    setForm1Errors(errors);
    if (Object.keys(errors).length === 0) {
      // callUpdateApi()
      console.log("selectedFileblob", selectedFile);
      const logo = isBlobURL(selectedFile)
        ? selectedFile
        : selectedFile instanceof File || selectedFile instanceof Blob
          ? URL.createObjectURL(selectedFile)
          : selectedFile;
      console.log("selectedFileblob2", logo);
      // setCurrentPage(2);
      updateCompanyInfo()
    }
  };

  const validateForm1Data = () => {

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const errors = {};
    if (form1Data.email === undefined || form1Data.email === "") {
      errors.email = Strings.email_err
    }
    if (form1Data.email !== '') {
      if (!emailRegex.test(form1Data.email)) {
        errors.email = Strings.invalid_email
      }
    }
    return errors;
  };



  function getFileExtensionFromMimeType(mimeType) {
    const mimeTypeMap = {
      "image/jpeg": "jpg",
      "image/png": "png",
      "image/gif": "gif",
      "image/webp": "webp",
      // Add more MIME types and their corresponding extensions as needed
    };

    // Get the corresponding file extension for the given MIME type
    return mimeTypeMap[mimeType] || "";
  }

  async function convertBlobUrlToFile(blobUrl) {
    try {
      // Fetch the blob data from the URL
      const response = await fetch(blobUrl);
      const blobData = await response.blob();
      const fileExtension = getFileExtensionFromMimeType(blobData.type);
      // Generate a unique file name with the determined extension
      const randomNumbers = Math.floor(Math.random() * 1000);
      const fileName = `file_from_blob_${randomNumbers}.${fileExtension}`;
      const file = new File([blobData], fileName, { type: blobData.type });
      return file;
    } catch (error) {
      console.error("Error converting Blob URL to File:", error);
      return null;
    }
  }

  const callPublishPageApi = async () => {
    const url = `${URLS.BASE_URL}${URLS.BUSINESSPAGE_APPROVAL}`;
    axios.defaults.headers.common["Accept"] = "application/json";
    axios.defaults.headers.common["Content-Type"] = "application/json";
    await axios
      .get(url, { headers: { Authorization: token } })
      .then((response) => {
        console.log("response", response);
        setLoading(false);
        if (response?.data.status === 1) {
          getCompanyDetails(companyInfo.id)
          setTimeout(() => {
            openNewTab();
          }, 200);
        } else {
          console.log("response", response);
          setShowAlert(true)
          setError(response.data.message)
        }
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response) {
          setShowAlert(true)
          setError(error.response.data.message)
        }
      });
  }

  const callAddBusinessPageApi = async (
    finalImagesList,
    imagesdescriptions,
    heroImagefile,
    btnClick
  ) => {
    const url = `${URLS.BASE_URL}${URLS.CREATE_BUSINESS_PAGE}`;
    console.log("finalImagesList", finalImagesList);
    console.log("heroImagefile", heroImagefile);
    const form = new FormData();
    form.append("company_information", companyInfo ? companyInfo.id : "");
    form.append("hero_image", heroImagefile);
    form.append("headline", heading);
    form.append("highlights_1", form2Data.hilight1 ? form2Data.hilight1 : "");
    form.append("highlights_2", form2Data.hilight2 ? form2Data.hilight2 : "");
    form.append("highlights_3", form2Data.hilight3 ? form2Data.hilight3 : "");
    form.append("highlights_4", form2Data.hilight4 ? form2Data.hilight4 : "");
    form.append("highlights_5", form2Data.hilight5 ? form2Data.hilight5 : "");
    form.append("highlights_6", form2Data.hilight6 ? form2Data.hilight6 : "");
    form.append("website_url", form2Data.siteurl ? form2Data.siteurl : "");
    form.append("instagram_url", form2Data.instaurl ? form2Data.instaurl : "");
    form.append("youtube_url", form2Data.youtubeurl ? form2Data.youtubeurl : "");
    form.append("facebook_url", form2Data.fburl ? form2Data.fburl : "");
    form.append("description", form2Data.description ? form2Data.description : "");

    if (finalImagesList.length > 0) {
      finalImagesList.forEach((imageFile, index) => {
        form.append(`business_page_image_${index + 1}`, imageFile);
      });
    }

    if (imagesdescriptions.length > 0) {
      imagesdescriptions.forEach((description, index) => {
        form.append(`image_description_${index + 1}`, description);
      });
    }

    const formDataObject = {};
    form.forEach((value, key) => {
      formDataObject[key] = value;
    });

    console.log("formDataObject", formDataObject);

    axios.defaults.headers.common["Accept"] = "application/json";
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
    await axios
      .post(url, form, { headers: { Authorization: token } })
      .then((response) => {
        console.log("response", response);
        setLoading(false);
        setDisable(false)
        if (response?.data.status === 1) {
          if (btnClick === 'publish') {
            callPublishPageApi()
          } else if (btnClick === "save") {
            // navigate("/businessPage");
            const formattedCompanyName = formatCompanyName(form1Data.companyName);
            navigate(`/businesspage/${formattedCompanyName}`);
          } else if (btnClick === "profile2") {
            setCurrentPage(1);
          } else {
            setBusinessForm1data({});
            setBusinessForm2data({});
            openNewTab();
          }
        } else {
          console.log("response", response);
          setShowAlert(true)
          setError(response.data.message)
          setDisable(false)
        }
      })
      .catch(function (error) {
        setLoading(false);
        setDisable(false)
        console.log("error", error);
        if (error.response) {
          setShowAlert(true)
          setError(error.response.data.message)
        }
      });
  };

  const updateCompanyInfo = async () => {
    const url = `${URLS.BASE_URL}${URLS.UPDATE_COMPANY_INFO}${companyInfo.id}/`;
    const form = new FormData();
    console.log("selectedFile", selectedFile);
    let logofile = null
    if (selectedFile != null) {
      if (isBlobURL(selectedFile)) {
        convertBlobUrlToFile(selectedFile).then((file) => {
          if (file) {
            logofile = file;
            console.log("logofile", logofile);
          } else {
            console.log("Failed to convert Blob URL to File.");
          }
        });
      } else if (selectedFile instanceof File) {
        let url = URL.createObjectURL(selectedFile);
        console.log("CONVERTTED URL LOGO", url);
        logofile = selectedFile;
      } else if (selectedFile instanceof Blob) {
        let blobfile = createFileFromBlob(selectedFile)
        let url = URL.createObjectURL(blobfile);
        console.log("CONVERTTED URL LOGO", url);
        logofile = blobfile;
      }
    }
    form.append("company_logo", logofile);
    form.append("company_name", form1Data.companyName ? form1Data.companyName : "");
    form.append("domain_name", companyInfo.domain_name ? companyInfo.domain_name : "");
    form.append("street", form1Data.street ? form1Data.street : "");
    form.append("street_number", form1Data.streetNumber ? form1Data.streetNumber : "");
    form.append("zip_code", form1Data.zipCode ? form1Data.zipCode : "");
    form.append("city", form1Data.city ? form1Data.city : "");
    form.append("country", form1Data.country ? form1Data.country : "");
    form.append("company_email", form1Data.email ? form1Data.email : "");
    form.append("country_code", countryCode ? countryCode : "");
    form.append("company_phonenumber", form1Data.telephone ? form1Data.telephone : "");
    form.append("company_logo_horizontal_position", form1Data.logoHorizontalposition ? form1Data.logoHorizontalposition : "");
    form.append("company_logo_vertical_position", form1Data.logoVerticlePosition ? form1Data.logoVerticlePosition : "");
    form.append("type_of_business", selectedOptions.length > 0 ? JSON.stringify(selectedOptions) : '');

    const formDataObject = {};
    form.forEach((value, key) => {
      formDataObject[key] = value;
    });

    console.log("formDataObject", formDataObject);
    setLoading(true)
    axios.defaults.headers.common["Accept"] = "application/json";
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
    await axios
      .put(url, form, { headers: { Authorization: token } })
      .then((response) => {
        console.log("response", response);
        setLoading(false);
        setDisable(false)
        setLoading(false)
        if (response?.data.status === 1) {
          setUserData(response?.data.data.user)
          setCompanyInfo(response?.data.data)
          setCurrentPage(2);
          setToken(response?.data.token)
        } else {
          console.log("response", response);
          setShowAlert(true)
          setError(response.data.message)
          setDisable(false)
        }
      })
      .catch(function (error) {
        setLoading(false);
        setDisable(false)
        console.log("error", error);
        if (error.response) {
          setShowAlert(true)
          setError(error.response.data.message)
        }
      });
  }

  const handleSaveCroppedImage = (croppedImage, index, imgpath) => {
    // Update the array of images with the cropped image
    console.log("croppedImage", croppedImage);
    console.log("imgFile", imgpath);
    console.log("index", index);
    const updatedImages = [...images];
    // Update only the 'updated_image' key for the specified index
    updatedImages[index] = {
      ...updatedImages[index],
      updated_image:
        imgpath === null ? updatedImages[index].original_image : imgpath,
    };

    console.log("updatedImages", updatedImages);

    // Set the new state array
    setImages(updatedImages);
  };

  return (
    <>
      <Header />
      <div>
        <Row style={{ marginRight: 0 }}>
          <Col lg={9} xl={8} md={12}>
            <div className="pd15">
              {(data && data == 'registration') || (message != '') ?
                <div style={{ backgroundColor: color == 'green' ? 'lightgreen' : 'orange', borderRadius: 5, padding: 5, alignItems: 'center', justifyContent: 'flex-start', display: 'flex', marginBottom: 20 }}>
                  <FaCircleCheck size={20} style={{ color: 'green', marginRight: 10, marginLeft: 10 }} />
                  <span style={{ color: color == 'green' ? 'green' : 'red' }}>{message}</span>
                </div> : null
              }
              <div>
                <h1 className="headertext1">
                  Willkommen bei campervanspot.com
                </h1>
                <h1 className="headertext2">
                  In 3 Schritten zu eurem Unternehmensprofil
                </h1>
              </div>
              <div className="circle-container">
                <div
                  className={`circle ${currentPage === 1 ? "active" : ""}`}
                  onClick={() => goToPage(1)}
                ></div>
                <div
                  className={`circle ${currentPage === 2 ? "active" : ""}`}
                  onClick={() => goToPage(2)}
                ></div>
              </div>
              {currentPage === 1 ? (
                <div>
                  <h1 className="headertext2 mt3 mb4">
                    Profil vervollständigen
                  </h1>
                  <div className="mt3">
                    <Row>
                      <Col xl={5} xs={8} md={6} sm={8}>
                        <Row>
                          <Col className="col-input">
                            <TopLabelInput
                              type={"text"}
                              label="Unternehmensname"
                              value={form1Data.companyName}
                              onChange={(e) =>
                                handleInputChange("companyName", e.target.value)
                              }
                            />
                          </Col>
                        </Row>
                        <h6 className="mt-2 mb3">Kontaktdaten</h6>
                        <Row>
                          <Col className="col-input-businessform" md={8}>
                            <TopLabelInput
                              type={"text"}
                              label="Straße"
                              value={form1Data.street}
                              onChange={(e) =>
                                handleInputChange("street", e.target.value)
                              }
                            />
                          </Col>
                          <Col className="col-input-businessform" md={4}>
                            <TopLabelInput
                              type={"text"}
                              label="Nr."
                              value={form1Data.streetNumber}
                              onChange={(e) =>
                                handleInputChange(
                                  "streetNumber",
                                  e.target.value
                                )
                              }
                            />
                          </Col>
                        </Row>
                        {form1Errors.street || form1Errors.streetNumber ? (
                          <div
                            style={{
                              display: "flex",
                              marginBottom: 20,
                              marginTop: -10,
                            }}
                          >
                            <FiAlertTriangle color="red" />
                            <div className="error-message">
                              {form1Errors.zipCode}
                            </div>
                          </div>
                        ) : null}
                        <Col className="error-row"></Col>
                        <Row>
                          <Col className="col-input-businessform" md={8}>
                            <TopLabelInput
                              type={"text"}
                              label="Postleitzahl"
                              value={form1Data.zipCode}
                              onChange={(e) =>
                                handleInputChange("zipCode", e.target.value)
                              }
                            />
                          </Col>
                          <Col className="col-input-businessform" md={4}>
                            <TopLabelInput
                              type={"text"}
                              label="Stadt"
                              value={form1Data.city}
                              onChange={(e) =>
                                handleInputChange("city", e.target.value)
                              }
                            />
                          </Col>
                        </Row>
                        {form1Errors.zipCode && (
                          <div
                            style={{
                              display: "flex",
                              marginBottom: 10,
                              marginTop: -10,
                            }}
                          >
                            <FiAlertTriangle color="red" />
                            <div className="error-message">
                              {form1Errors.zipCode}
                            </div>
                          </div>
                        )}
                        <Row>
                          <Col className="col-input" md={12}>
                            <TopLabelInput
                              type={"text"}
                              label="Land"
                              value={form1Data.country}
                              onChange={(e) =>
                                handleInputChange("country", e.target.value)
                              }
                            />
                          </Col>
                        </Row>
                        {form1Errors.country && (
                          <div
                            style={{
                              display: "flex",
                              marginBottom: 10,
                              marginTop: -10,
                            }}
                          >
                            <FiAlertTriangle color="red" />
                            <div className="error-message">
                              {form1Errors.country}
                            </div>
                          </div>
                        )}
                        <Row>
                          <Col className="col-input" md={12}>
                            <TopLabelInput
                              type={"tel"}
                              label="Telefonnummer"
                              short={countryCode}
                              value={form1Data.telephone}
                              onChange={(value) => {
                                handleInputChange("telephone", {
                                  code: value.code,
                                  phone: value.phone,
                                  short: value.short,
                                });
                              }}
                            />
                          </Col>
                        </Row>
                        {form1Errors.telephone && (
                          <div
                            style={{
                              display: "flex",
                              marginBottom: 10,
                              marginTop: -10,
                            }}
                          >
                            <FiAlertTriangle color="red" />
                            <div className="error-message">
                              {form1Errors.telephone}
                            </div>
                          </div>
                        )}
                        <Row>
                          <Col className="col-input" md={12}>
                            <TopLabelInput
                              type={"email"}
                              mobile={true}
                              label="Email"
                              value={form1Data.email}
                              onChange={(e) =>
                                handleInputChange("email", e.target.value)
                              }
                            />
                          </Col>
                        </Row>
                        {form1Errors.email && (
                          <div
                            style={{
                              display: "flex",
                              marginBottom: 10,
                              marginTop: -10,
                            }}
                          >
                            <FiAlertTriangle color="red" />
                            <div className="error-message">
                              {form1Errors.email}
                            </div>
                          </div>
                        )}
                      </Col>
                      <Col xl={1} md={1} />
                      <Col xl={6} md={5}>
                        <Row>
                          <Col md={4}>
                            <h6 className="mb2">Logo</h6>
                            <div
                              className="dashed-view"
                              onDrop={handleDrop}
                              onDragOver={handleDragOver}
                            >
                              {(selectedFile !== null) ? (
                                <div htmlFor="fileInput" className="upload-label" onMouseEnter={() => handleMouseEnter()} onMouseLeave={() => handleMouseLeave()}>
                                  <OverlayTrigger
                                    show={showDeletePop}
                                    trigger={['hover', 'focus']}
                                    placement="top"
                                    overlay=
                                    {
                                      <Popover placement="top">
                                        <div className='popover-content'>
                                          <Button className="m-1" onClick={() => handleDeleteClick('')}>
                                            <MdDelete size={15} color='white' />
                                          </Button>
                                        </div>
                                      </Popover>
                                    }
                                  >
                                    <div style={{ display: 'flex', flex: 1, height: '100%' }}>
                                      <img
                                        src={(selectedFile instanceof File ||
                                          selectedFile instanceof Blob) ? URL.createObjectURL(selectedFile) : selectedFile}
                                        alt="Upload"
                                        onLoad={handleLogoImageLoaded}
                                        className="logo-image"
                                      />
                                      <input
                                        style={{
                                          backgroundColor: "red",
                                          position: "absolute",
                                          top: 0,
                                          left: 0,
                                          right: 0,
                                          bottom: 0,
                                          cursor: "pointer",
                                        }}
                                        ref={fileInputRef}
                                        type="file"
                                        accept="uploadedimage/*"
                                        onChange={handleFileChange}
                                      />
                                    </div>

                                  </OverlayTrigger>

                                </div>
                              ) :
                                <div htmlFor="fileInput" className="upload-label" >
                                  {!selectedFile ? (
                                    <div className="upload-placeholder">
                                      <img
                                        alt="selectedFile"
                                        style={{ width: 15, height: 15 }}
                                        src={require("../../assets/placeholder.png")}
                                      ></img>
                                    </div>
                                  ) : null}

                                  <input
                                    style={{
                                      backgroundColor: "red",
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      right: 0,
                                      bottom: 0,
                                      cursor: "pointer",
                                    }}
                                    ref={fileInputRef}
                                    type="file"
                                    accept="uploadedimage/*"
                                    onChange={handleFileChange}
                                  />
                                </div>
                              }

                            </div>
                          </Col>

                        </Row>

                        {form1Errors.logo && (
                          <div style={{ display: "flex", marginTop: 5 }}>
                            <FiAlertTriangle color="red" />
                            <div className="error-message">
                              {form1Errors.logo}
                            </div>
                          </div>
                        )}

                        <h6 className="mb2 mt3">Logo Stellung</h6>

                        <Row>
                          <Col xxl={5} xl={6} lg={10} md={10} style={{ marginBottom: 15 }}><span>Horizontale Position</span></Col>
                          <Col>
                            <div className="options-container">
                              <div
                                className={`option ${form1Data.logoHorizontalposition == 'left' ? "selected" : ""}`}
                                onClick={() => handleHorizantalPositionSelect("left")}
                              >
                                Links
                              </div>
                              <div
                                className={`option ${form1Data.logoHorizontalposition == 'center' ? "selected" : ""}`}
                                onClick={() => handleHorizantalPositionSelect("center")}
                              >
                                Mitte
                              </div>
                              <div
                                className={`option ${form1Data.logoHorizontalposition == 'right' ? "selected" : ""}`}
                                onClick={() => handleHorizantalPositionSelect("right")}
                              >
                                Rechts
                              </div>
                            </div>
                          </Col>

                        </Row>
                        <Row >
                          <Col xxl={5} xl={6} lg={10} md={10} style={{ marginTop: 10 }}><span>Vertikale Position</span> </Col>
                          <Col>
                            <div className="options-container" style={{ marginTop: 10 }}>
                              <div
                                className={`option ${form1Data.logoVerticlePosition == 'top' ? "selected" : ""}`}
                                onClick={() => handleVerticlePositionSelect("top")}
                              >
                                Oben
                              </div>
                              <div
                                className={`option ${form1Data.logoVerticlePosition == 'center' ? "selected" : ""}`}
                                onClick={() => handleVerticlePositionSelect("center")}
                              >
                                Mitte
                              </div>
                              <div
                                className={`option ${form1Data.logoVerticlePosition == 'bottom' ? "selected" : ""}`}
                                onClick={() => handleVerticlePositionSelect("bottom")}
                              >
                                Unten
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <h6 className="mt3">Unternehmensart</h6>
                        <div className="options-container">
                          <div
                            className={`option ${selectedOptions &&
                              selectedOptions.length > 0 &&
                              selectedOptions.includes("retailer")
                              ? "selected"
                              : ""
                              }`}
                            onClick={() => handleOptionSelect("retailer")}
                          >
                            Händler
                          </div>
                          <div
                            className={`option ${selectedOptions &&
                              selectedOptions.length > 0 &&
                              selectedOptions.includes("manufacturer")
                              ? "selected"
                              : ""
                              }`}
                            onClick={() => handleOptionSelect("manufacturer")}
                          >
                            Hersteller
                          </div>
                          <div
                            className={`option ${selectedOptions &&
                              selectedOptions.length > 0 &&
                              selectedOptions.includes("upgrader")
                              ? "selected"
                              : ""
                              }`}
                            onClick={() => handleOptionSelect("upgrader")}
                          >
                            Ausbauer
                          </div>
                          <div
                            className={`option ${selectedOptions &&
                              selectedOptions.length > 0 &&
                              selectedOptions.includes("rental company")
                              ? "selected"
                              : ""
                              }`}
                            onClick={() => handleOptionSelect("rental company")}
                          >
                            Vermieter
                          </div>
                        </div>
                        {form1Errors.businessType && (
                          <div style={{ display: "flex", marginTop: 5 }}>
                            <FiAlertTriangle color="red" />
                            <div className="error-message">
                              {form1Errors.businessType}
                            </div>
                          </div>
                        )}
                        <div style={{ display: "flex", marginTop: "4vmin" }}>
                          <button
                            className="next-btn1"
                            onClick={handleNext1Click}
                          >
                            Weiter
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              ) : (
                <div>
                  <h1 className="headertext2 mt4 mb4">
                    Unternehmensseite erstellen
                  </h1>
                  <div className="mt5">
                    <Row>
                      <Col xl={6} lg={6} sm={8} md={7}>

                        <div className="heilightdiv">
                          <span className="labelfloat">Highlights</span>
                          <div>
                            <input
                              className="inputheilight"
                              placeholder="Highlight 1"
                              value={form2Data.hilight1}
                              onInput={(e) =>
                                handleForm2InputChange(
                                  "hilight1",
                                  e.target.value
                                )
                              }
                            ></input>
                            <div className="inputborder" />
                          </div>
                          {form2Errors.hilight1 && (
                            <div style={{ display: "flex", marginBottom: 10 }}>
                              <FiAlertTriangle color="red" />
                              <div className="error-message">
                                {form2Errors.hilight1}
                              </div>
                            </div>
                          )}
                          <div>
                            <input
                              className="inputheilight"
                              placeholder="Highlight 2"
                              value={form2Data.hilight2}
                              onInput={(e) =>
                                handleForm2InputChange(
                                  "hilight2",
                                  e.target.value
                                )
                              }
                            ></input>
                            <div className="inputborder" />
                          </div>
                          {form2Errors.hilight2 && (
                            <div style={{ display: "flex", marginBottom: 10 }}>
                              <FiAlertTriangle color="red" />
                              <div className="error-message">
                                {form2Errors.hilight2}
                              </div>
                            </div>
                          )}
                          <div>
                            <input
                              className="inputheilight"
                              placeholder="Highlight 3"
                              value={form2Data.hilight3}
                              onInput={(e) =>
                                handleForm2InputChange(
                                  "hilight3",
                                  e.target.value
                                )
                              }
                            ></input>
                            <div
                              className="inputborder"
                            />
                          </div>
                          {form2Errors.hilight3 && (
                            <div style={{ display: "flex" }}>
                              <FiAlertTriangle color="red" />
                              <div className="error-message">
                                {form2Errors.hilight3}
                              </div>
                            </div>
                          )}
                          <div>
                            <input
                              className="inputheilight"
                              placeholder="Highlight 4"
                              value={form2Data.hilight4}
                              onInput={(e) =>
                                handleForm2InputChange(
                                  "hilight4",
                                  e.target.value
                                )
                              }
                            ></input>
                            <div className="inputborder" />
                          </div>
                          {form2Errors.hilight4 && (
                            <div style={{ display: "flex", marginBottom: 10 }}>
                              <FiAlertTriangle color="red" />
                              <div className="error-message">
                                {form2Errors.hilight4}
                              </div>
                            </div>
                          )}
                          <div>
                            <input
                              className="inputheilight"
                              placeholder="Highlight 5"
                              value={form2Data.hilight5}
                              onInput={(e) =>
                                handleForm2InputChange(
                                  "hilight5",
                                  e.target.value
                                )
                              }
                            ></input>
                            <div className="inputborder" />
                          </div>
                          {form2Errors.hilight5 && (
                            <div style={{ display: "flex", marginBottom: 10 }}>
                              <FiAlertTriangle color="red" />
                              <div className="error-message">
                                {form2Errors.hilight5}
                              </div>
                            </div>
                          )}
                          <div>
                            <input
                              className="inputheilight"
                              placeholder="Highlight 6"
                              value={form2Data.hilight6}
                              onInput={(e) =>
                                handleForm2InputChange(
                                  "hilight6",
                                  e.target.value
                                )
                              }
                            ></input>
                            <div className="inputborder" style={{ marginBottom: 10 }} />
                          </div>
                          {form2Errors.hilight6 && (
                            <div style={{ display: "flex", marginBottom: 10 }}>
                              <FiAlertTriangle color="red" />
                              <div className="error-message">
                                {form2Errors.hilight6}
                              </div>
                            </div>
                          )}
                        </div>
                        <TopLabelInput
                          type={"text"}
                          label="Uberschrift"
                          value={heading}
                          onChange={(e) => handleHeadingChange(e)}
                        />
                        {form2Errors.heading && (
                          <div style={{ display: "flex", marginBottom: 10 }}>
                            <FiAlertTriangle color="red" />
                            <div className="error-message">
                              {form2Errors.heading}
                            </div>
                          </div>
                        )}

                        {/* <div className='descriptiondiv'>
                          <span className='labelfloat'>Beschreibung</span>
                          <textarea className="descriptionlabel" value={form2Data.description} onInput={handleDescriptionChange} />
                        </div> */}

                        <ReactQuill
                          theme="snow"
                          modules={modules}
                          className="quill_businessForm"
                          formats={formats}
                          value={form2Data.description}
                          placeholder="Beschreibung ...."
                          onChange={handleProcedureContentChange}
                        ></ReactQuill>

                        {form2Errors.description && (
                          <div style={{ display: "flex", marginBottom: 10 }}>
                            <FiAlertTriangle color="red" />
                            <div className="error-message">
                              {form2Errors.description}
                            </div>
                          </div>
                        )}
                        <TopLabelInput
                          type={"url"}
                          label="Website-URL"
                          value={form2Data.siteurl}
                          onChange={(e) =>
                            handleForm2InputChange("siteurl", e.target.value)
                          }
                        />
                        {form2Errors.siteurl && (
                          <div style={{ display: "flex", marginBottom: 10 }}>
                            <FiAlertTriangle color="red" />
                            <div className="error-message">
                              {form2Errors.siteurl}
                            </div>
                          </div>
                        )}
                      </Col>

                      <Col xl={6} lg={6} sm={8} md={7}>
                        <TopLabelInput
                          type={"url"}
                          label="Fb-URL"
                          value={form2Data.fburl}
                          onChange={(e) =>
                            handleForm2InputChange("fburl", e.target.value)
                          }
                        />
                        {form2Errors.fburl && (
                          <div style={{ display: "flex", marginBottom: 10 }}>
                            <FiAlertTriangle color="red" />
                            <div className="error-message">
                              {form2Errors.fburl}
                            </div>
                          </div>
                        )}
                        <TopLabelInput
                          type={"url"}
                          label="Insta-URL"
                          value={form2Data.instaurl}
                          onChange={(e) =>
                            handleForm2InputChange("instaurl", e.target.value)
                          }
                        />
                        {form2Errors.instaurl && (
                          <div style={{ display: "flex", marginBottom: 10 }}>
                            <FiAlertTriangle color="red" />
                            <div className="error-message">
                              {form2Errors.instaurl}
                            </div>
                          </div>
                        )}
                        <TopLabelInput
                          type={"url"}
                          label="Youtube-URL"
                          value={form2Data.youtubeurl}
                          onChange={(e) =>
                            handleForm2InputChange("youtubeurl", e.target.value)
                          }
                        />
                        {form2Errors.youtubeurl && (
                          <div style={{ display: "flex", marginBottom: 10 }}>
                            <FiAlertTriangle color="red" />
                            <div className="error-message">
                              {form2Errors.youtubeurl}
                            </div>
                          </div>
                        )}
                        <h6 className="mt3">Hero Image</h6>
                        <div className="dashed-view">
                          {heroImageFile !== null ?
                            <div htmlFor="fileInput" className="upload-label" onMouseEnter={() => handleMouseEnter()} onMouseLeave={() => handleMouseLeave()}>
                              <OverlayTrigger
                                show={showDeletePop}
                                trigger={['hover', 'focus']}
                                placement="top"
                                overlay=
                                {
                                  <Popover placement="top">
                                    <div className='popover-content'>
                                      <Button className="m-1" onClick={() => handleDeleteClick('heroImage')}>
                                        <MdDelete size={15} color='white' />
                                      </Button>
                                    </div>
                                  </Popover>
                                }
                              >
                                <div style={{ height: '100%' }}>
                                  {/* {heroImageFile != null ? (
                              <img src={URL.createObjectURL(heroImageFile)} alt="Uploaded" className="uploaded-image" />
                            ) : null} */}
                                  {heroImageFile !== null &&
                                    (heroImageFile instanceof File ||
                                      heroImageFile instanceof Blob) ? (
                                    <img
                                      src={URL.createObjectURL(heroImageFile)}
                                      alt=""
                                      className="hero-image"
                                      ref={heroRef}
                                    />
                                  ) : heroImageFile !== null &&
                                    isBlobURL(heroImageFile) ? (
                                    <img
                                      src={heroImageFile}
                                      alt=""
                                      className="hero-image"
                                      ref={heroRef}
                                    />
                                  ) : heroImageFile !== null ? (
                                    <img
                                      src={heroImageFile}
                                      onLoad={handleHeroImageLoaded}
                                      alt="hero-image"
                                      ref={heroRef}
                                      className="hero-image"
                                    />
                                  ) : null}
                                  <input
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      right: 0,
                                      bottom: 0,
                                      cursor: "pointer",
                                    }}
                                    ref={fileInputRef}
                                    type="file"
                                    accept="image/*"
                                    onChange={handleHeroImageChange}
                                  />
                                </div>
                              </OverlayTrigger>
                            </div> :
                            <div htmlFor="fileInput" className="upload-label">
                              {!heroImageFile ? (
                                <div className="upload-placeholder">
                                  <img
                                    alt=""
                                    style={{ width: 15, height: 15 }}
                                    ref={heroRef}
                                    src={require("../../assets/placeholder.png")}
                                  ></img>
                                </div>
                              ) : null}
                              <input
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  right: 0,
                                  bottom: 0,
                                  cursor: "pointer",
                                }}
                                ref={fileInputRef}
                                type="file"
                                accept="image/*"
                                onChange={handleHeroImageChange}
                              />
                            </div>
                          }

                        </div>
                        {form2Errors.heroImageFile && (
                          <div style={{ display: "flex", marginBottom: 10 }}>
                            <FiAlertTriangle color="red" />
                            <div className="error-message">
                              {form2Errors.heroImageFile}
                            </div>
                          </div>
                        )}
                        <h6 className="mt3">Galerie</h6>
                        <Row>
                          {[...Array(5)].map((_, index) => (
                            <Col xs={4} sm={5} md={4} lg={5} xl={4} key={index}>
                              <div
                                className="dashed-view"
                              // onDrop={handleDrop}
                              // onDragOver={handleDragOver}
                              >
                                <div
                                  htmlFor={`fileInput-${index}`}
                                  className="upload-label"
                                >
                                  {images.length > 0 && images[index] ? images[index].updated_image === null ? (
                                    <div>
                                      <div className="upload-placeholder">
                                        <img
                                          style={{ width: 15, height: 15 }}
                                          src={require("../../assets/placeholder.png")}
                                          alt="Placeholder"
                                        ></img>
                                      </div>
                                      <input
                                        style={{
                                          backgroundColor: "red",
                                          position: "absolute",
                                          top: 0,
                                          left: 0,
                                          right: 0,
                                          bottom: 0,
                                          cursor: "pointer",
                                        }}
                                        ref={fileInputRefs[index]}
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) =>
                                          handleImageChange(e, index)
                                        }
                                        id={`fileInput-${index}`}
                                      />
                                    </div>
                                  ) : (
                                    <ImageCard
                                      updatedImage={
                                        isBlobURL(
                                          images[index]["updated_image"]
                                        )
                                          ? images[index]["updated_image"]
                                          : images[index][
                                            "updated_image"
                                          ] instanceof File ||
                                            images[index][
                                            "updated_image"
                                            ] instanceof Blob
                                            ? URL.createObjectURL(
                                              images[index]["updated_image"]
                                            )
                                            : images[index]["updated_image"]
                                      }
                                      imageUrl={
                                        isBlobURL(
                                          images[index]["original_image"]
                                        )
                                          ? images[index]["original_image"]
                                          : images[index][
                                            "original_image"
                                          ] instanceof File ||
                                            images[index][
                                            "original_image"
                                            ] instanceof Blob
                                            ? URL.createObjectURL(
                                              images[index]["original_image"]
                                            )
                                            : images[index]["original_image"]
                                      }
                                      handleImageChange={(e) =>
                                        handleImageChange(e, index)
                                      }
                                      fileInputRef={fileInputRefs[index]}
                                      onDelete={() => handleDelete(index)}
                                      onEditText={() => handleEditText(index)}
                                      onLoadImage={(e) =>
                                        handleBusinessImageLoaded(e.target.src, index)
                                      }
                                      onSaveCroppedImage={(
                                        croppedImage,
                                        imgpath
                                      ) =>
                                        handleSaveCroppedImage(
                                          croppedImage,
                                          index,
                                          imgpath
                                        )
                                      }
                                    />
                                  ) : <div>
                                    <div className="upload-placeholder">
                                      <img
                                        style={{ width: 15, height: 15 }}
                                        src={require("../../assets/placeholder.png")}
                                        alt="Placeholder"
                                      ></img>
                                    </div>
                                    <input
                                      style={{
                                        backgroundColor: "red",
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        cursor: "pointer",
                                      }}
                                      ref={fileInputRefs[index]}
                                      type="file"
                                      accept="image/*"
                                      onChange={(e) =>
                                        handleImageChange(e, index)
                                      }
                                      id={`fileInput-${index}`}
                                    />
                                  </div>}

                                </div>
                              </div>
                            </Col>
                          ))}
                        </Row>
                        {form2Errors.images && (
                          <div style={{ display: "flex", marginBottom: 10 }}>
                            <FiAlertTriangle color="red" />
                            <div className="error-message">
                              {form2Errors.images}
                            </div>
                          </div>
                        )}
                        {(pageStaus === 'inprogress') ?
                          <div style={{ marginTop: 20, marginBottom: 20, display: 'flex', flexDirection: 'row', justifyContent: 'center', marginLeft: '7vw' }}>
                            <button
                              className="next-btn"
                              onClick={() => handleNext2Click("save")}
                            >
                              Speichern
                            </button>
                            <button
                              className="preview-btn"
                              onClick={() => handleNext2Click("preview")}
                            >
                              Preview
                            </button>
                            <button
                              className="publishbtn"
                              onClick={() => handlePublishClick()}
                            >
                              <span>Veröffentlichen</span>
                            </button>
                          </div>
                          :
                          <div style={{ marginTop: 20, marginBottom: 20, display: 'flex', flexDirection: 'row', justifyContent: 'center', marginLeft: '5vw' }}>
                            <button
                              className="next-btn"
                              onClick={() => handleNext2Click("save")}
                            >
                              Speichern & Veröffentlichen
                            </button>
                          </div>}
                      </Col>
                    </Row>
                  </div>
                </div>
              )}
            </div>
          </Col >
          <Col lg={0} xl={4} md={0}>
            {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}> */}
            {currentPage === 1 ? (
              <div
                style={{
                  backgroundImage:
                    currentPage === 1
                      ? `url(${process.env.PUBLIC_URL + "/businessform1.png"})`
                      : `url(${process.env.PUBLIC_URL + "/businessform2.png"})`,
                  backgroundPosition: "cover",
                  backgroundSize: "cover",
                  height: "100%",
                }}
              />
            ) : (
              <div
                style={{
                  backgroundImage:
                    currentPage === 1
                      ? `url(${process.env.PUBLIC_URL + "/businessform1.png"})`
                      : `url(${process.env.PUBLIC_URL + "/businessform2.png"})`,
                  backgroundPosition: "cover",
                  backgroundSize: "cover",
                  height: "100%",
                }}
              />
            )}
          </Col>
        </Row >
        {showalert &&
          <AlertComponent message={error} onClose={() => { setShowAlert(false) }}></AlertComponent>
        }
        {
          loading && (
            <div className="loader">
              <ClipLoader
                color={"#123abc"}
                loading={loading}
                css={override}
                size={50}
              />
            </div>
          )
        }
        {
          showImageTextEditor && (
            <ImageTextEditor
              singleimg={true}
              imageData={images[editImageIndex]}
              images={images}
              showModal={showImageTextEditor}
              onSaveImageData={(text) => saveImageData(text)}
              closeModal={() => setShowImageTextEditor(false)}
            />
          )
        }

      </div >
    </>
  );
};

export default BusinessFormPage;
