import React, { useEffect, useState } from 'react';
import axios from 'axios';

const CountryFlag = ({ countryCode }) => {
    const [flagUrl, setFlagUrl] = useState('');

    useEffect(() => {
        const fetchFlag = async () => {
            try {
                const response = await axios.get(`https://restcountries.com/v3.1/alpha/${countryCode}`);
                const url = response.data[0].flags.svg;
                console.log(`Fetched flag URL: ${url}`);
                setFlagUrl(url);
            } catch (error) {
                console.error('Error fetching the country flag:', error);
            }
        };

        fetchFlag();
    }, [countryCode]);

    return (
        <div style={{ alignContent: 'center', display: 'flex' }}>
            {flagUrl && (
                <img src={flagUrl} alt={`${countryCode} flag`} width="25" style={{ marginRight: 10, borderRadius: 2 }} />
            )}
        </div>
    );
};

export default CountryFlag;
