import React from "react";
import '../ErrorPage/ErrorPage.css'


export default function NotFound() {
    return (
        <div className="errordiv" >
            < img
                src={require("../../assets/logo.webp")}
                width="200vh"
                height="200vw"
                alt='logo'
                style={{ objectFit: 'contain' }}
                className="d-inline-block align-top"
            />
            <p style={{ marginBottom: 20, marginTop: 20 }} className='error_string'>
                404: Es tut uns sehr leid, aber wir konnten die von Dir gesuchte Seite nicht finden. Bitte starte nochmal von hier:
                <a href="https://campervanspot.com" style={{ textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer"> https://campervanspot.com</a>
            </p>
        </div>

    )
}