import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Strings, impressumLink, termsandconditions } from '../../utilities/constants';
import './Footer.css'
const Footer = () => {
    return (
        <footer className='footer'>
            <div className='footer__container'>
                <Row className='rowfinisher'>
                    <Col md={1}></Col>
                    <Col className='footer_left_column' >
                        <h1 className='addressText'>{Strings.address}</h1>
                        <p className='a para'>campervanspot.com GmbH
                            <br></br>
                            Marienstr. 19
                            <br></br>
                            50259 Pulheim
                            <br></br>
                            Deutschland
                        </p>
                    </Col>
                    <Col className='footer_right_column'  >
                        <h5 className='addressText'>Rechtliches</h5>
                        <ul className='ul'>
                            <li>
                                <a className='a' href={impressumLink} rel="noopener">Impressum</a>
                            </li>
                            <li>
                                <a className='a' href={termsandconditions} rel="noopener" herf="#">Datenschutzvereinbarung</a>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </div>
        </footer>
    )
}
export default Footer;